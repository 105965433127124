import { bind } from '@donkeyjs/proxy';
import { Errors } from '../layout';
import type { LoginFormType } from './getLoginForm';

interface LoginFormProps {
  form: LoginFormType;
  isRegistering?: boolean;
  isConfirm?: boolean;
}

export function LoginForm(props: LoginFormProps) {
  return (
    <>
      {() => props.isConfirm && <h1>Confirm it's you</h1>}
      <Errors node={props.form} />
      {() => !props.isConfirm && <props.form.$.email autofocus />}
      <props.form.$.password
        password
        autocomplete={bind(() =>
          props.isRegistering ? 'new-password' : 'current-password',
        )}
      />
    </>
  );
}
