import type { ClientSchemaMeta } from '@donkeyjs/client';
import { formatFullName } from './shared/format/formatFullName';

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Order: {
    asString: (node) => {
      const name = formatFullName({
        firstName: node.shippingFirstName,
        lastName: node.shippingLastName,
        middleName: node.shippingMiddleName,
        title: node.shippingTitle,
      });
      return `${node.orderNumber ? `#${node.orderNumber}` : '-'}${
        name ? ` (${name})` : ''
      }`;
    },
  },
};
