import { type DataNode, batch, isDataNode, meta, store } from '@donkeyjs/proxy';
import { admin } from '../admin';
import { dropZone } from '../dragdrop/useDropZone';
import { getAssetUrl } from '../helpers/getAssetUrl';
import { getMailContext } from '../mail/mailContext';
import { session } from '../session';
import type { ImageProps } from './Image';

export interface UseImageProps {
  readonly file:
    | DataNode<DataSchema, 'File'>
    | DataNode<DataSchema, 'FileRef'>
    | null
    | undefined;
  readonly ratio?: number | 'adaptive' | undefined;
  readonly mailWidth?: number;
  readonly maxWidth?: number;
  readonly onChange?: (file: DataNode<DataSchema, 'File'>) => void;
  readonly onClear?: () => void;
  readonly onTest?: (
    file: DataNode<DataSchema, 'File'> | null | undefined,
  ) => (() => void) | void;
}

export const useImage = (props: ImageProps) => {
  const mail = getMailContext();

  const outline =
    props.file &&
    (props.onChange || props.onClear) &&
    admin.useOutline?.(props.file as DataNode<DataSchema, 'File'>, {
      onClear: props.onClear,
    });

  function handleChange(ev?: MouseEvent) {
    ev?.preventDefault();
    admin.selectFile?.().then((file) => {
      if (file) {
        props.onChange?.(file);
      }
    });
  }

  const state = store({
    element: undefined as HTMLElement | undefined,
    imageLoaded: false,

    get file() {
      const file =
        props.file?.__typename === 'FileRef' ? props.file.file : props.file;
      meta(file)?.request({
        fileExtension: true,
        fileType: true,
        height: true,
        name: true,
        uploadStatus: true,
        dominant: true,
        width: true,
      });
      return file;
    },

    get url(): string {
      const width =
        mail?.target === 'mail'
          ? props.maxWidth || props.mailWidth || 800
          : this.currentWidth;
      return !this.file ||
        width === 0 ||
        (props.ratio === 'adaptive' && this.currentRealHeight === 0)
        ? ''
        : getAssetUrl(this.file, {
            mailContext: mail || null,
            width,
            height: props.ratio
              ? Math.floor(
                  width /
                    (props.ratio === 'adaptive'
                      ? Math.floor(
                          (this.currentRealWidth * 10) / this.currentRealHeight,
                        ) / 10
                      : props.ratio),
                )
              : undefined,
          });
    },

    get visible() {
      if (!this.file) return false;
      return (
        !!this.file &&
        !meta(this.file).isLoading &&
        this.file.uploadStatus === 'available' &&
        this.file.fileType.startsWith('image/')
      );
    },

    get ratio() {
      if (props.ratio === 'adaptive') return undefined;
      if (props.ratio) return props.ratio;
      if (!this.file) return 1;

      const width = this.file?.width;
      const height = this.file?.height;
      return width && height ? width / height : undefined;
    },

    get dominantColor() {
      return `#${this.file?.dominant || 'eeeeee'}`;
    },

    clientWidth: undefined as number | undefined,
    currentWidth: props.mailWidth ?? (session.dom.crawler ? 800 : 0),
    currentRealWidth: 0,
    currentRealHeight: 0,

    handleSize(size: ResizeObserverEntry) {
      batch(() => {
        const newWidth = Math.ceil(size.contentRect.width / 200) * 200;
        if (newWidth > state.currentWidth) state.currentWidth = newWidth;
        state.currentRealWidth = size.contentRect.width;
        state.currentRealHeight = size.contentRect.height;
      });
    },

    get attach(): JSX.OnMount<HTMLElement> | undefined {
      return [
        (el) => {
          this.element = el;
          return () => {
            this.element = undefined;
          };
        },
        props.onChange
          ? dropZone({
              key: props.file,
              onDrag: (item) => {
                if (item.type !== 'node') return;
                const node = item.node;
                if (isDataNode<DataSchema, 'File'>(node, 'File')) {
                  return {
                    positions: ['inside'],
                    hover: () => this.handleHover(node),
                    drop: () => this.handleDrop(node),
                  };
                }
              },
            })
          : () => {},
        (el) => outline?.onAttach?.(el),
      ];
    },

    style(additionalStyles?: JSX.CSSProperties) {
      const result: JSX.CSSProperties = this.ratio
        ? {
            aspectRatio: this.ratio.toString(),
            ...additionalStyles,
          }
        : props.ratio === 'adaptive'
          ? {
              objectFit: 'cover',
              ...additionalStyles,
            }
          : { ...additionalStyles };
      if (!this.imageLoaded) {
        result.backgroundColor = state.dominantColor;
      }
      return result;
    },

    get handleButtonClick() {
      return props.onChange ? handleChange : undefined;
    },

    handleLoad() {
      state.imageLoaded = true;
    },

    handleHover(node: DataNode<DataSchema, 'File'>) {
      if (props.onTest) {
        return props.onTest(node);
      }
      if (props.file?.__typename === 'FileRef') {
        return meta(props.file).testValues({ file: node });
      }
    },

    handleDrop(node: DataNode<DataSchema, 'File'>) {
      if (props.onChange) {
        props.onChange(node);
      }
    },
  });

  return state;
};
