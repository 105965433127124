export const formatFullName = (
  node: {
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    middleName?: string | null;
  },
  lastNameFirst?: boolean,
) =>
  lastNameFirst
    ? [
        node.lastName,
        [node.firstName, node.title, node.middleName].filter(Boolean).join(' '),
      ]
        .filter(Boolean)
        .join(', ')
    : [node.title, node.firstName, node.middleName, node.lastName]
        .filter(Boolean)
        .join(' ');
