import type { AppClientModule } from '@donkeyjs/client';
import { lazyComponent } from '@donkeyjs/client';
import { permissions, productPermissions } from '../permissions';
import type { ProductsSchema } from '../schema';
import { clientSchemaMeta } from '../schemaMeta';
import { blocks } from './blocks';
import { ViewAddress } from './views/ViewAddress';

export * from './backoffice/shoppingPlugin';
export * from './blocks/cart/views/ViewOrderItem';
export * from './helpers/useOrderableInfo';
export * from './products/ProductOrderItemParser';
export * from './shoppingSettings';
export * from './ui';
export * from './useCart';

const FinancialRoute = lazyComponent('financial-route', () =>
  import('./backoffice/components/Financial/Route').then(
    (m) => m.FinancialRoute,
  ),
);

const OrderRoute = lazyComponent('order-route', () =>
  import('./backoffice/components/OrderRoute').then((m) => m.OrderRoute),
);

export const clientModuleShopping: AppClientModule = {
  blocks,
  permissions,
  views: {
    Address: { default: ViewAddress },
  },
  localRoutes: [
    {
      name: 'Financial',
      id: 'shopping.financial',
      pathname: '/.dashboard/financial',
      isSystemRoute: true,
      render: () => <FinancialRoute />,
    },
    {
      name: 'Order',
      id: 'shopping.order',
      pathname: '/.order',
      isSystemRoute: true,
      render: () => <OrderRoute />,
    },
  ],
  clientSchemaMeta,
};

export const clientModuleProducts: AppClientModule<ProductsSchema> = {
  // blockPresets: [
  //   {
  //     type: 'data',
  //     name: () => 'Products',
  //     hue: 25,
  //     icon: phPackage({ weight: 'duotone' }),
  //     values: { settings: { resolver: 'products', view: 'default' } },
  //   },
  // ],
  permissions: productPermissions,
};
