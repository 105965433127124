import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import { admin } from '../../../admin';
import { Image } from '../../../components/Image';

interface FileProps {
  value: DataNode<DataSchema, 'File'> | null | undefined;
  onTest?: (
    file: DataNode<DataSchema, 'File'> | null | undefined,
  ) => (() => void) | void;
  changeOnClick?: boolean;
  ratio?: number | 'adaptive';
  readonly?: boolean;
  testing?: boolean;
  class?: JSX.ClassNames;
  style?: Record<string, any>;
  onmount?: JSX.OnMount<HTMLSpanElement>;
}

export function File(props: FileProps) {
  const handleSelect = async (file?: DataNode<DataSchema, 'File'>) => {
    const result = file || (await admin.selectFile?.());
    props.value = result;
  };

  const result = (
    <Image
      file={bind(() => props.value)}
      class={bind(() => props.class)}
      style={bind(() => props.style)}
      onTest={props.onTest}
      onChange={bind(() =>
        !props.readonly && !props.changeOnClick ? handleSelect : undefined,
      )}
      onClick={bind(() =>
        !props.readonly && props.changeOnClick
          ? () => handleSelect()
          : undefined,
      )}
      testing={bind(() => props.testing)}
      ratio={bind(() => props.ratio)}
    />
  );

  return props.onmount ? <span onmount={props.onmount}>{result}</span> : result;
}
