import { bind, getGlobal, live } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataList,
  type DataNode,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { session } from '../session';
import styles from './SlideShow.module.css';

export interface UseSlideShowSettings {
  autoPlay?: boolean;
  interval?: number;
  transition?: 'fade' | 'slide';
  transitionSpeed?: number;
}

export interface UseSlideShowProps<T extends NodeTypename<DataSchema>>
  extends UseSlideShowSettings {
  data: DataList<DataSchema, T>;
  autoHeight?: boolean;
  render(item: DataNode<DataSchema, T>): JSX.Children;
}

const key = Symbol('slideShow');

export function useSlideShowLock() {
  return getGlobal(key, () => store<{ locked?: DataNode<DataSchema> }>({}));
}

export const useSlideShow = <T extends NodeTypename<DataSchema>>(
  props: UseSlideShowProps<T>,
) => {
  let timeout: number | undefined;

  const context = useSlideShowLock();

  const startTimeout = () => {
    if (timeout) clearTimeout(timeout);
    timeout = window.setTimeout(
      state.next,
      state.interval * 1000 + (timeout ? state.transitionSpeed * 1000 : 0),
    );
  };

  const slideChanged = () => {
    if (timeout) startTimeout();
  };

  const state = store({
    el: null as HTMLElement | null,
    current: 0,

    get interval() {
      return props.interval ?? 5;
    },
    get transition() {
      return props.transition ?? 'fade';
    },
    get transitionSpeed() {
      return props.transitionSpeed ?? 0.5;
    },
    get slideCount() {
      return props.data.length;
    },

    get height(): number {
      const element = state.el?.children[state.current];
      return element?.clientHeight || 0;
    },

    get style() {
      if (props.autoHeight) {
        return undefined;
      }

      const style: JSX.CSSProperties = {
        height: `${state.height}px`,
      };

      if (state.transition === 'slide') {
        style.transform = `translateX(-${state.current * 100}%)`;
        style.transition = `transform ${state.transitionSpeed * 1000}ms`;
      }

      return style;
    },

    onmount(el: HTMLElement) {
      state.el = el;
      return () => {
        state.el = null;
      };
    },

    next() {
      if (context.locked) return;
      const next = state.current + 1;
      state.current = next === state.slideCount ? 0 : next;
      slideChanged();
    },

    previous() {
      if (context.locked) return;
      const prev = state.current - 1;
      state.current = prev < 0 ? state.slideCount - 1 : prev;
      slideChanged();
    },

    $item(node: DataNode<DataSchema, T>, index: () => number) {
      return (
        <div
          class={bind(() => [
            styles.slide,
            {
              [styles.fade]: state.transition === 'fade',
              active: index() === state.current,
            },
          ])}
          style={bind(() => {
            const style: JSX.CSSProperties = {};
            if (state.transition === 'fade') {
              style.opacity = index() === state.current ? '1' : '0';
              style.transition = `opacity ${state.transitionSpeed * 1000}ms`;
            }
            return style;
          })}
        >
          {() => props.render(node)}
        </div>
      );
    },
  });

  live(() => {
    if (session.dom.ssr) return;
    if (props.autoPlay) {
      startTimeout();
    } else if (timeout) {
      clearTimeout(timeout);
    }
  });

  live(() => {
    if (context.locked && props.data.includes(context.locked as any)) {
      state.current = props.data.indexOf(context.locked as any);
      slideChanged();
    }
  });

  return state;
};
