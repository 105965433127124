import { jsxx } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { getI18n } from '../../../i18n/getI18n';
import { LoadingSingleLine, lazyComponent } from '../../../loaders';
import { format } from './helpers/format';
import { useDateComponent } from './useDateComponent';

const InlineDateEditor = lazyComponent(
  'donkey.fields.inlinedate',
  () => import('./editors/InlineDateEditor').then((m) => m.InlineDateEditor),
  'none',
);

export function InlineDate(props: FieldRenderProps<'date'>) {
  const i18n = getI18n();

  const state = useDateComponent(props);

  return () => {
    if (props.field.loading) return <LoadingSingleLine size={'small'} />;

    if (!props.readonly) return jsxx(InlineDateEditor, props);
    if (!props.field.value) return null;

    return format(state, props, i18n);
  };
}
