import { bind, jsxx, setState } from '@donkeyjs/jsx-runtime';
import type { DataNode, Node, NodeTypename, Schema } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { lazyComponent } from '../../../loaders';
import { getViewContext } from '../../../views';
import { View } from '../../../views/View';
import inlineStyles from '../../inlineLayout.module.css';
import { getView } from './getView';

const InlineNodeSelect = lazyComponent(
  'donkey.fields.inlinenode',
  () => import('./editors/InlineNodeSelect').then((m) => m.InlineNodeSelect),
  'none',
);

export function InlineNodeField(props: FieldRenderProps<'node'>) {
  return (
    <InlineNode
      typename={bind(() => props.field.schema?.type)}
      value={bind(props.field, 'value')}
      mode={bind(() => props.mode)}
      view={bind(() => props.view)}
      optional={bind(() => props.field.schema?.optional)}
      inactive={bind(() => !props.parentOutline?.active)}
      readonly={bind(() => props.readonly)}
      class={bind(() => props.class)}
      onmount={bind(() => props.onmount)}
    />
  );
}

export interface InlineNodeProps<S extends Schema = Schema> {
  typename: NodeTypename<S> | null | undefined;
  value: Node | null | undefined;
  mode?: 'view' | 'select';
  view?: string;
  optional?: boolean;
  inactive?: boolean;
  autoSelectFirst?: boolean;
  readonly?: boolean;
  class?: JSX.ClassNames;
  onmount?: JSX.OnMount<HTMLElement>;
}

export function InlineNode<S extends Schema = Schema>(
  props: InlineNodeProps<S>,
) {
  const viewContext = getViewContext();

  const state = setState({
    get view() {
      return props.typename
        ? getView(props.typename, props.view, viewContext)
        : undefined;
    },
  });

  return () => {
    if (!props.typename) return null;

    if (props.mode === 'select') {
      if (!props.readonly) {
        return jsxx(InlineNodeSelect, props);
      }
      return (
        <span
          class={bind(() => [
            props.class,
            { [inlineStyles.active]: !props.inactive },
          ])}
        >
          {props.value?.toString() || null}
        </span>
      );
    }

    if (!props.value) return null;

    if (state.view)
      return (
        <View<S, any>
          node={props.value! as DataNode<S, any>}
          readonly={true}
          view={state.view!}
          onmount={props.onmount}
        />
      );

    return (
      <div class="view" onmount={props.onmount}>
        {() =>
          `No view available for ${props.typename} with key ${
            viewContext?.preferredListView
              ? `'${viewContext?.preferredListView}' or 'default'`
              : "'default'"
          }`
        }
      </div>
    );
  };
}
