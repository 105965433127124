import {
  getNodeContextProviders,
  getUserContext,
  session,
  type UserContext,
} from '@donkeyjs/client';
import { getGlobal } from '@donkeyjs/jsx-runtime';
import {
  meta,
  store,
  type DataNode,
  type List,
  type Schema,
} from '@donkeyjs/proxy';
import type { Orderable } from '../schema';
import { parseOrderItem, type CartOrderItem } from '../shared/parseOrderItem';
import { addToOrder } from './helpers/addToOrder';
import { removeFromOrder } from './helpers/removeFromOrder';

const globalKey = Symbol('shoppingCart');

export type Cart = ReturnType<typeof getCart>;

export type OrderableKey = Exclude<
  {
    [Key in keyof DataNode<DataSchema, 'OrderItem'>]: NonNullable<
      DataNode<DataSchema, 'OrderItem'>[Key]
    > extends Orderable
      ? Key
      : never;
  }[keyof DataNode<DataSchema, 'OrderItem'>] &
    keyof DataNode<DataSchema, 'OrderItem'>,
  '$'
>;

export type OrderableItem<Key extends OrderableKey> = NonNullable<
  DataNode<DataSchema, 'OrderItem'>[Key]
>;

export const useCart = (): Cart => {
  const user = getUserContext();
  return getGlobal(globalKey, () => getCart(user));
};

const getCart = (user?: UserContext) => {
  const loadOrder = session.dom.ssr
    ? undefined
    : new URLSearchParams(window.location.search).get('load-order');
  const [loadId, loadSecret] = (loadOrder || '').split('.');

  const order = session.dom.ssr
    ? undefined
    : session.data.getOrder({
        source: 'current-order',
        permanent: true,
        ...((loadId && loadSecret
          ? {
              id: loadId,
              s: loadSecret,
            }
          : {}) as any),
        get u() {
          return user?.userRequest?.[0]?.id;
        },
      });

  meta(session.app.data[0]).request({
    defaultCurrency: true,
    shippingFromCountry: true,
    shippingBasePrice: true,
    enableCoupons: true,
  });

  const itemFields = Object.entries(session.app.schema.nodes.OrderItem.fields);
  const items: any = {};
  for (const [field, value] of itemFields) {
    items[field] = value.scalar || value.enum ? true : { id: true };
  }

  meta(order?.[0])?.request({
    email: true,
    orderNumber: true,
    orderedAt: true,
    stage: true,
    orderCulture: true,
    exception: true,

    currency: true,

    shippingCompanyName: true,
    shippingTitle: true,
    shippingFirstName: true,
    shippingLastName: true,
    shippingMiddleName: true,
    shippingCountryCode: true,
    shippingCity: true,
    shippingAddressLine1: true,
    shippingAddressLine2: true,
    shippingPostalCode: true,

    billingCompanyName: true,
    billingTitle: true,
    billingFirstName: true,
    billingLastName: true,
    billingMiddleName: true,
    billingCountryCode: true,
    billingCity: true,
    billingAddressLine1: true,
    billingAddressLine2: true,
    billingPostalCode: true,

    items,

    user: {
      id: true,
    },

    transactions: {
      amount: true,
      at: true,
      isPercentage: true,
      status: true,
      type: true,
      message: true,
      orderItem: { id: true },
    },
  });

  const ensureOrder = () => {
    return session.dom.ssr || result.isLoading
      ? undefined
      : (result.order ??= session.data.createNode({
          __typename: 'Order',
          stage: 'SHOPPING',
          shipToBillingAddress: true,
          currency: session.app.data[0].defaultCurrency || 'EUR',
          shippingCountryCode: session.app.data[0].shippingFromCountry || 'NL',
          billingCountryCode: session.app.data[0].shippingFromCountry || 'NL',
          user: user?.user || undefined,
        }));
  };

  const ensureEditableOrder = () => {
    const order = ensureOrder();
    if (order?.stage !== 'SHOPPING') return null;
    return order;
  };

  const localOrder = store<{ value?: DataNode<DataSchema, 'Order'> | null }>(
    {},
  );

  const result = store({
    get isLoading() {
      return !!order?.[0] && meta(order[0]).isLoading;
    },

    get order(): DataNode<DataSchema, 'Order'> | undefined {
      if (localOrder.value === null) return undefined;
      const result = localOrder.value || order?.[0];
      if (result?.stage !== 'SHOPPING') return undefined;
      return result;
    },

    set order(value: DataNode<DataSchema, 'Order'> | undefined) {
      localOrder.value = value || null;
    },

    get count(): number {
      const order = result.order;
      if (!order) return 0;

      return order.items.reduce(
        (count, orderItem) => count + orderItem.quantity,
        0,
      );
    },

    get parsedItems(): CartOrderItem[] {
      return (
        result.order?.items
          .map((item) => parseOrderItem(item, result.order?.transactions))
          .filter((n) => !!n) || []
      );
    },

    add<Kind extends OrderableKey>(
      kind: Kind,
      item: OrderableItem<Kind>,
      quantity = 1,
    ) {
      const order = ensureEditableOrder();
      if (!order) return;

      addToOrder(order, kind, item, quantity);
    },

    replace<Kind extends OrderableKey>(
      kind: Kind,
      orderItem: DataNode<DataSchema, 'OrderItem'>,
      withItem: OrderableItem<Kind>,
    ) {
      const order = ensureEditableOrder();
      if (!order) return;

      const index = order.items.indexOf(orderItem);
      if (index === -1) return;

      orderItem[kind] = withItem;
      orderItem.price = withItem.price;
    },

    remove<Kind extends OrderableKey>(
      kind: Kind,
      item: OrderableItem<Kind>,
      quantity?: number,
    ) {
      const order = ensureEditableOrder();
      if (!order) return;

      removeFromOrder(order, kind, item, quantity);
    },

    countItems<Kind extends OrderableKey>(
      kind: Kind,
      item: OrderableItem<Kind>,
    ): number {
      const order = result.order;
      if (!order) return 0;

      const orderItem = order.items.find(
        (orderItem) => orderItem[kind]?.id === item.id,
      );

      return orderItem?.quantity ?? 0;
    },

    isInCart<Kind extends OrderableKey>(
      kind: Kind,
      items: OrderableItem<OrderableKey>[] | List<OrderableItem<OrderableKey>>,
    ): boolean {
      const order = result.order;
      if (!order) return false;

      return items.some((item) =>
        order.items.some((orderItem) => orderItem[kind]?.id === item.id),
      );
    },

    applyCoupon(code: string) {
      const order = ensureEditableOrder();
      if (!order) return;

      return session.data.mutation.applyCoupon(
        { code: code },
        {
          id: true,
          amount: true,
          at: true,
          isPercentage: true,
          status: true,
          type: true,
          message: true,
          order: {
            id: true,
          },
          orderItem: {
            id: true,
          },
        },
      );
    },
  });

  getNodeContextProviders().register('order', {
    name: () => 'Order',
    get: () => {
      ensureOrder();
      return result.order as DataNode<Schema>;
    },
  });

  return result;
};
