import { jsxx } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { lazyComponent, LoadingSingleLine } from '../../../loaders';
import { Markup } from '../../markup';

const InlineStringEditor = lazyComponent(
  'donkey.fields.inlinestring',
  () =>
    import('./editors/InlineStringEditor').then((m) => m.InlineStringEditor),
  'none',
);

export function InlineString(props: FieldRenderProps<'string'>) {
  return () => {
    if (props.field.loading) return <LoadingSingleLine size="medium" />;

    if (props.readonly) {
      const value = props.field.schema?.markup
        ? jsxx(Markup, {
            get value() {
              return props.field.value || '\n';
            },
            readonly: true,
          })
        : props.field.value;
      return props.class && props.field.value ? (
        <span class={props.class}>{value}</span>
      ) : (
        value
      );
    }

    return jsxx(InlineStringEditor, props);
  };
}
