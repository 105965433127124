import {
  createMarkupString,
  normalizeMarkup,
  type Markup,
  type MarkupString,
} from './Markup';

export interface HtmlToMarkupBlock {
  type: 'UNSET' | 'P' | 'T1' | 'T2' | 'H1' | 'H2' | 'UL' | 'OL' | 'QUOTE';
  markup: MarkupString;
}

interface HtmlToMarkupIntermediateBlock {
  type: 'UNSET' | 'P' | 'T1' | 'T2' | 'H1' | 'H2' | 'UL' | 'OL' | 'QUOTE';
  text: string;
  markup: Markup;
}

interface ChildNodeEssentials {
  nodeType: number;
  childNodes?: NodeListOf<ChildNode> | ChildNodeEssentials[];
  textContent?: string | null;
  tagName?: string;
  href?: string;
  target?: string;
  dataset?: { fieldId?: string };
  style?: { fontWeight?: string; fontStyle?: string; textDecoration?: string };
}

type ChildNodes = NodeListOf<ChildNode> | ChildNodeEssentials[];

export function htmlToMarkupInBrowser(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return htmlToMarkup(doc.body.childNodes);
}

export function htmlToMarkup(fragment: ChildNodes) {
  const result: HtmlToMarkupIntermediateBlock[] = [];
  let current: HtmlToMarkupIntermediateBlock | null = null;

  function ensureCurrent(value?: HtmlToMarkupIntermediateBlock) {
    if (value || !current) {
      current = value || { type: 'UNSET', text: '', markup: [] };
      result.push(current);
    }
    return current;
  }

  function processChildren(
    fragment: NodeListOf<ChildNode> | ChildNodeEssentials[] = [],
    isInline = false,
  ) {
    for (const node of Array.from(fragment)) {
      if (node.nodeType === 3) {
        ensureCurrent().text += node.textContent?.replace(/\n/g, ' ') || '';
      } else if (node.nodeType === 1) {
        const tagName = (node as HTMLElement).tagName.toUpperCase();

        if (tagName === 'BR') {
          ensureCurrent().text += '\n';
          continue;
        }

        if (!isInline) {
          const blockType = getBlockType(tagName);
          if (blockType) {
            ensureCurrent({
              type: blockType,
              text: '',
              markup: [],
            });
            processChildren(node.childNodes);
            current = null;
            continue;
          }
        }

        const inline = getInlineInfo(node as HTMLElement);
        if (inline) {
          ensureCurrent();
          const index = current!.text.length;
          processChildren(node.childNodes, true);
          const length = current!.text.length - index;
          current!.markup.push({ ...inline, i: index, l: length });
          continue;
        }

        if (!shouldIgnoreType(tagName)) {
          processChildren(node.childNodes, isInline);
          if (tagName === 'DIV') {
            ensureCurrent().text += '\n';
          }
        }
      }
    }
  }

  processChildren(fragment);

  return result.map<HtmlToMarkupBlock>(({ type, text, markup }) => ({
    type,
    markup: createMarkupString(text, normalizeMarkup(markup)),
  }));
}

function getBlockType(tagName: string) {
  return (
    {
      H1: 'T1',
      H2: 'T2',
      H3: 'H1',
      H4: 'H2',
      H5: 'H2',
      H6: 'H2',
      P: 'P',
      LI: 'UL',
      BLOCKQUOTE: 'QUOTE',
    } as const
  )[tagName];
}

function getInlineInfo(
  element: ChildNodeEssentials,
):
  | { to: string; tg?: '_blank' }
  | { fld: string }
  | { f: 'b' | 'i' | 's' }
  | null {
  const tagName = element.tagName!.toUpperCase();

  switch (tagName) {
    case 'A':
      return {
        to: element.href || '',
        tg: element.target === '_blank' ? '_blank' : undefined,
      } as const;

    case 'SPAN': {
      if (element.dataset?.fieldId) return { fld: element.dataset.fieldId };
      if (element.style?.fontStyle === 'italic') return { f: 'i' };
      if (element.style?.textDecoration === 'line-through') return { f: 's' };

      if (element.style?.fontWeight === 'bold') return { f: 'b' };
      if (Number.parseInt(element.style?.fontWeight || '0', 10) >= 600)
        return { f: 'b' };

      return null;
    }

    case 'B':
    case 'STRONG':
      return { f: 'b' };

    case 'I':
    case 'EM':
      return { f: 'i' };

    case 'S':
      return { f: 's' };

    default:
      return null;
  }
}

function shouldIgnoreType(tagName: string) {
  return !!(
    {
      STYLE: true,
      SCRIPT: true,
    } as const
  )[tagName];
}
