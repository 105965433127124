import { Loader, getTheme, text } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCheck, PhShoppingCartSimple } from '@donkeyjs/phosphor-icons';
import { meta, store } from '@donkeyjs/proxy';
import { I18nShopping } from '../../i18n';
import { type OrderableItem, type OrderableKey, useCart } from '../useCart';

interface UseAddToCartOptions<Key extends OrderableKey> {
  kind: Key;
  item: OrderableItem<Key>;
  amount?: number;
}

interface AddToCartButtonProps<Key extends OrderableKey>
  extends UseAddToCartOptions<Key> {
  class?: JSX.ClassNames;
  style?: 'default' | 'icon-only';
}

export function useAddToCart<Key extends OrderableKey>(
  options: UseAddToCartOptions<Key>,
) {
  const cart = useCart();

  meta(options.item).request({
    currency: true,
    name: true,
    price: true,
    weight: true,
  } as any);

  const onClick = () => {
    cart.add(options.kind, options.item, options.amount);
  };

  const state = store({
    get inCart() {
      return !!cart.countItems(options.kind, options.item);
    },
    get icon() {
      return state.inCart ? (
        <PhCheck weight="bold" />
      ) : (
        <PhShoppingCartSimple weight="fill" />
      );
    },
    get label() {
      return state.inCart
        ? text(I18nShopping, 'ShopOption.IsInCart')
        : text(I18nShopping, 'ShopOption.AddToCart');
    },
    onClick,
  });

  return state;
}

export function AddToCartButton<Key extends OrderableKey>(
  props: AddToCartButtonProps<Key>,
) {
  const theme = getTheme();
  const state = useAddToCart(props);

  return () => (
    <Loader loading={bind(() => meta(props.item).isLoading)} type="none">
      <button
        type="button"
        class={bind(() => [
          theme.class.button,
          'add-to-cart-button',
          'default',
          props.class,
          { 'in-cart': state.inCart },
        ])}
        onclick={state.onClick}
      >
        {() => state.icon}
        {() => props.style !== 'icon-only' && <span>{() => state.label}</span>}
      </button>
    </Loader>
  );
}
