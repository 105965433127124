import {
  type DataNode,
  type MarkupFieldType,
  type Schema,
  meta,
} from '@donkeyjs/proxy';
import { useDataContext } from '../../data/DataContext';
import { getI18n } from '../../i18n/getI18n';
import styles from './MarkupField.module.css';
import { MarkupTemplate } from './MarkupTemplate';

interface MarkupFieldProps {
  entity: MarkupFieldType;
  readonly?: boolean;
  selected?: boolean;
}

export function MarkupField(props: MarkupFieldProps) {
  const context = useDataContext();
  const i18n = getI18n(true);

  return () => {
    const field = props.entity.fld.split(':')[1] ?? props.entity.fld;
    const [typename, ...fields] = field?.split('.') ?? [];
    if (!typename || !fields.length) return null;

    let node = context?.findTypename<Schema, string>(typename)?.node;
    while (fields.length > 1) {
      node = (node as any)?.[fields.shift()!] as
        | DataNode<Schema, string>
        | undefined;
    }
    const Field =
      props.readonly || !meta(node)?.isLoading
        ? (node?.$ as any)?.[fields[0]]
        : undefined;

    if (Field)
      return props.readonly ? (
        <Field readonly />
      ) : (
        <span contenteditable="false" class={styles.preview}>
          <Field readonly />
        </span>
      );

    if (props.readonly) return null;

    return (
      <MarkupTemplate contenteditable="false" inline>
        {i18n.getFieldName(typename as any, fields[0])}
      </MarkupTemplate>
    );
  };
}
