import { traceInput } from '@donkeyjs/core';
import { type FieldSchema, store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../../..';
import { useUserZone } from '../../../../i18n/UserZone';
import { getI18n } from '../../../../i18n/getI18n';
import { session } from '../../../../session';

export type StringEditor = ReturnType<typeof useStringEditor>;

export const useStringEditor = (props: FieldRenderProps<'string'>) => {
  const i18n = getI18n(useUserZone());

  const state = store({
    get enumType(): keyof typeof session.app.schema.enums | undefined {
      return props.field.schema?.enum
        ? (props.field.schema.type as keyof typeof session.app.schema.enums)
        : undefined;
    },

    get value() {
      return (
        (props.readonly && !props.field.value ? '' : props.field.value) ||
        props.defaultOption ||
        ''
      );
    },
    set value(value) {
      props.field.value = value;
    },

    get options(): { value: string; label: string }[] | undefined {
      if (props.options) return props.options;

      if ((props.field.schema as FieldSchema<'string'>)?.options) {
        return (props.field.schema as FieldSchema<'string'>).options!.map(
          (option) =>
            typeof option === 'string'
              ? {
                  value: option,
                  label: option,
                }
              : option,
        );
      }

      const type = state.enumType;
      if (!type) return undefined;

      return session.app.schema.enums[type]?.values.map((value) => ({
        value: value,
        label: i18n.getEnumValue(type, value),
      }));
    },

    handleInput() {
      if (props.field.parent) {
        traceInput(`${props.field.parent.typename}/${props.field.parent.key}`);
      }
    },
  });

  return state;
};
