export function formatSearchText(
  text: string,
  mode: 'tokens' | 'plain' = 'plain',
): string {
  // Early return for empty/invalid input
  if (!text?.trim()) {
    return '';
  }

  if (mode === 'plain') {
    return text.trim();
  }

  // Clean and normalize the search text
  const cleanedText = text
    .trim()
    // Convert special characters and punctuation to spaces
    .replace(/[!@#$%^&*()+=\[\]{};:'"\\|,.<>/?`~\-_]+/g, ' ')
    // Handle various quotation marks and apostrophes
    .replace(/[\u2018\u2019\u201C\u201D]/g, ' ')
    // Replace multiple spaces with a single space
    .replace(/\s+/g, ' ')
    .trim();

  // If the cleaned text is empty after processing, return the original trimmed text
  if (!cleanedText) {
    return text.trim();
  }

  // Format for tsquery with :* suffix
  const searchTerms = cleanedText
    .split(' ')
    .filter(Boolean)
    // Escape any remaining special characters that might interfere with tsquery
    .map((term) => term.replace(/[&|!:*()]/g, ''));

  return `${searchTerms.join(' & ')}:*`;
}
