import { lazyComponent, type BlocksType } from '@donkeyjs/client';
import { PaymentForm } from '../payments/PaymentForm';

export const blocks: BlocksType = {
  'shopping-cart': {
    component: lazyComponent('shopping/cart', () =>
      import('./cart/CartBlock').then((m) => m.CartBlock),
    ),
  },
  'shopping-cart-mail-merge': {
    component: lazyComponent('shopping/cart-mail-merge', () =>
      import('./cart/CartMailMergeBlock').then((m) => m.CartMailMergeBlock),
    ),
  },
  payment: { component: PaymentForm },
};
