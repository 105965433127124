import type { DatabaseRouter } from '@donkeyjs/core';
import {
  type Component,
  addGlobalEventListener,
  onMount,
  removeGlobalEventListener,
} from '@donkeyjs/jsx-runtime';
import { type Culture, type DataNode, meta } from '@donkeyjs/proxy';
import { SEO } from '../SEO';
import { Authentication } from '../authentication/Authentication';
import { Portal, createShortcutsHandler, useGlobalCommands } from '../helpers';
import { ErrorBoundary } from '../helpers/ErrorBoundary';
import { setI18n } from '../i18n/getI18n';
import { useInspect } from '../inspect/useInspect';
import { setLayout } from '../layout';
import { layouts } from '../layout/layouts';
import { setMailContextFromUrl } from '../mail';
import { session } from '../session';
import { setTheme } from '../styles';
import { fonts } from '../styles/fonts/fonts';

import { admin } from '../admin';
import { getUserContext } from '../authentication';
import { setDataContext } from '../data/DataContext';
import '../styles.css';

export function AppRoot(props: {
  useRouter: () => DatabaseRouter;
  install: () => void;
  root: Component;
}) {
  const appData = session.data.getApp({ hostname: session.app.hostname });
  session.app.data = appData;

  const router = props.useRouter();
  session.router = router;

  const inspect = useInspect();
  useGlobalCommands({
    'inspect.toggle': {
      name: 'Toggle Inspect Window',
      handler: () => inspect.toggle(),
    },
  });

  props.install();

  // dom.ensureStyle('main', (style) => {
  //   style.innerHTML = styles;
  //   dom.appendStyleToHead(style);
  // });

  setLayout('inline', layouts);

  setI18n({
    get culture() {
      return session.router.culture;
    },
    userCulture: session.app.schema.defaultCulture as Culture,
  });

  const mail = setMailContextFromUrl(session.app.theme);
  session.dom.bodyAttributes.class = [
    'app-root',
    setTheme(session.app.theme).themeClass!,
  ];

  const handleClick = (ev: MouseEvent) => {
    if (!ev.defaultPrevented && ev.target && 'closest' in ev.target) {
      const element = ev.target as Element;
      const link = element.closest('a');

      if (
        !link ||
        !link.getAttribute('href')?.startsWith('/') ||
        link.getAttribute('href')?.startsWith('/assets/')
      )
        return;

      ev.preventDefault();

      if ((ev as any).outlinePreventsLinks) return;

      const editable = element.closest(
        ':not(.main-contenteditable)[contenteditable="true"], input, textarea, button, label, select',
      );
      if (editable) return;

      session.router.navigate(link.getAttribute('href')!);
    }
  };

  onMount(() => {
    if (!session.dom.ssr) {
      const shortcutsHandler = createShortcutsHandler();
      addGlobalEventListener('click', handleClick as any);
      addGlobalEventListener('keydown', shortcutsHandler as any);

      return () => {
        removeGlobalEventListener('click', handleClick as any);
        removeGlobalEventListener('keydown', shortcutsHandler as any);
      };
    }
  });

  return (
    <Authentication>
      <ErrorBoundary>
        {() =>
          session.app.plugins.app.reduce<JSX.Children>(
            (acc, Plugin) => <Plugin>{acc}</Plugin>,
            () => {
              if (meta(session.app.data)?.errors.length)
                throw meta(session.app.data[0])?.errors[0];
              if (!session.app.data[0]) throw new Error('no-app');

              const Root = props.root;
              return (
                <WithDataContext route={session.router.route?.node}>
                  {session.router.route.render?.(() => <Root />) ?? <Root />}
                </WithDataContext>
              );
            },
          )
        }
      </ErrorBoundary>
      <SEO />
      <Portal parent={session.dom.head} bare>
        {() =>
          fonts.hasGoogleFonts &&
          !mail?.target && (
            <>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossorigin=""
              />
            </>
          )
        }
        {() =>
          Object.values(fonts.links).map((link) => (
            <link rel="stylesheet" href={link} />
          ))
        }
        {() =>
          fonts.css && (
            <style type="text/css">{Object.values(fonts.css).join('\n')}</style>
          )
        }
      </Portal>
    </Authentication>
  );
}

function WithDataContext(props: {
  route?: DataNode<DataSchema, 'Route'>;
  children?: JSX.Children;
}) {
  const user = getUserContext();

  if (props.route) {
    const outline = admin.useOutline?.(props.route, { withoutInterface: true });
    setDataContext(props.route, outline);
  }

  return user.user && props.route?.accessibleToRoles ? (
    <WithUserContext user={user.user}>{props.children}</WithUserContext>
  ) : (
    props.children
  );
}

function WithUserContext(props: {
  user: DataNode<DataSchema, 'User'>;
  children?: JSX.Children;
}) {
  const outline = admin.useOutline?.(props.user, { withoutInterface: true });
  setDataContext(props.user, outline);

  return props.children;
}
