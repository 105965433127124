import type { LocaleInput } from '@donkeyjs/core';
import { formatLocale } from 'd3-format';
import { format } from 'date-fns';
import dateLocale from 'date-fns/locale/en-GB';

export * from './System';

export const I18nSystemEnGB = () =>
  import('./System').then((m) => m.I18nSystemEnGB);

export const I18nBlocksEnGB = () =>
  import('./Blocks').then((m) => m.I18nBlocksEnGB);

export const I18nImportEnGB = () =>
  import('./Import').then((m) => m.I18nImportEnGB);

export const schemaEnGB = () =>
  import('./Schema').then((m) => m.BaseSchemaEnGB);

const d3Locale = {
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['£', ''],
};

const formatNumber = formatLocale(d3Locale as any).format;

export const enGB: Required<LocaleInput> = {
  formatDate: (date, specifier) =>
    format(date, specifier, { locale: dateLocale }),
  formatNumber: (number, specifier) => formatNumber(specifier)(number),
  dateLocale,
};

export type DefaultLocale = typeof enGB;
