import type { ScopedDocumentSelection } from '@donkeyjs/jsx-runtime';
import type {
  DataList,
  DataNode,
  DataNodeFields,
  IntrinsicNodeFields,
  NodeTypename,
  ResolverArgsSearch,
  Schema,
  Store,
} from '@donkeyjs/proxy';
import type { BlockPreset } from './blocks';
import type { MenuItemProps } from './donkey';
import type {
  DraggableOptions,
  DraggedItem,
  UseDropZoneOptions,
} from './dragdrop';
import type { MarkupTreeProps } from './layout/markup/MarkupTree';
import type { ViewType } from './views';

interface SelectFileOptions {
  readonly multiple?: boolean;
}

export interface CreateOrEditNodeProps<
  S extends Schema,
  T extends NodeTypename<S>,
> {
  typename: T;
  defaultValues?: Omit<Partial<IntrinsicNodeFields>, '__typename'> &
    Partial<DataNodeFields<S, T>>;
  node?: DataNode<S, T>;
  isolatedFields?: (keyof DataNode<S, T>)[];
  addIsolatedFields?: (keyof DataNode<S, T>)[];
  hideIsolatedFields?: (keyof DataNode<S, T>)[];
}

export interface GlobalSelection {
  selection: OutlineEntry<Schema>[];
  locked: OutlineEntry<Schema>[];
  hover: OutlineEntry<Schema> | undefined;
  hoverLocked: Map<OutlineEntry<Schema>, boolean>;
  defaultProperties?: OutlineEntry<Schema>;
  root?: SelectionRoot;

  select<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
    options?: { append?: boolean },
  ): void;
  deselect<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isSelected<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  lock<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  lockHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  unlock<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  unlockHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isLocked<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  setHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type> | undefined,
  ): void;
  unsetHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isHovering<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  useRoot(root: SelectionRoot): () => void;
  setDefaultProperties<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  unsetDefaultProperties<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
}

interface SelectionRoot {
  node: DataNode<Schema>;
  blocks?: DataList<DataSchema, 'Block'>;
  mode?: 'mail';
}

export interface UseOutlineOptions<
  S extends Schema,
  Type extends NodeTypename<S>,
> {
  readonly context?: DataList<S, Type> | DataNode<S, Type>[];
  readonly view?: ViewType<S, Type>;
  readonly parent?: DataNode<S> | OutlineEntry<S>;
  readonly draftsEnabled?: boolean;
  readonly preventSelection?: boolean;
  readonly withoutInterface?: boolean;
  readonly overrideLock?: boolean;
  readonly onReplace?: (node: DataNode<S, Type>) => void;
  readonly onDelete?: () => void;
  readonly onClear?: () => void;
}

export interface OutlineEntryInput<
  S extends Schema,
  Type extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly name?: JSX.Children | string | null;
  readonly descriptor?: JSX.Children | string | null;
  readonly text?: JSX.Children;
  readonly icon?: () => JSX.Element;
  readonly defaultClosed?: boolean;
  readonly onResize?: (width: number | null) => void;
  readonly onAttach?: (element: EventTarget) => (() => void) | void;
  readonly onEnter?: (ev: KeyboardEvent) => void;
  readonly preventFirstClick?: boolean;
  taskbar?: JSX.Children;
  readonly properties?: (outline: OutlineEntry) => JSX.Children;
  readonly suggestions?: Suggestion[];
  readonly emphasis?: 'low' | 'medium' | 'high' | 'text';
  readonly draggable?: S extends DataSchema
    ? Type extends NodeTypename<DataSchema>
      ? DraggableOptions<Type>
      : never
    : never;
  readonly accept?:
    | boolean
    | {
        accept?: boolean | ((item: DraggedItem) => boolean);
        interceptDrop?: (
          item: DraggedItem,
          target: DataNode<DataSchema, 'Block'>,
        ) => boolean;
        interceptHover?: (
          item: DraggedItem,
          target: DataNode<DataSchema, 'Block'>,
        ) => (() => void) | void;
      }
    | UseDropZoneOptions;
  readonly disableAccept?: () => boolean;
  readonly hideFromPanel?: boolean;
  readonly setAsDefaultProperties?: boolean;
  readonly contextMenu?: () => (MenuItemProps | '-' | undefined)[];
  readonly headerPosition?: 'outside' | 'inside';
  readonly visibleWhenChildSelected?: boolean;
  readonly children?: OutlineEntry<S>[];
  readonly onAspectRatio?: (aspectRatio: number | null) => void;
}

export interface OutlineEntry<
  S extends Schema = Schema,
  Type extends NodeTypename<S> = NodeTypename<S>,
> extends Omit<OutlineEntryInput<S, Type>, 'accept'> {
  node: DataNode<S, Type>;
  selected: boolean;
  selectionLocked: boolean;
  readonly childSelected: boolean;
  readonly instanceSelected: boolean;
  element?: HTMLElement;
  hovering: boolean;
  readonly active: boolean;
  readonly disabled: boolean;
  readonly locked: boolean;
  parent: OutlineEntry<S> | undefined;
  accept?: UseDropZoneOptions;
  lockHover(): void;
  unlockHover(): void;
  useSuggestions(options?: UseSuggestionsOptions): Store<{
    readonly groups: Record<string, Suggestion[]>;
    load(): void;
    title(suggestion: Suggestion, matchClass?: JSX.ClassNames): JSX.Children;
  }>;
}

interface UseSuggestionsOptions {
  searchText?: () => string;
  searchQuery?: () => ResolverArgsSearch | undefined;
  autoLoadNodes?: boolean;
  add?: Suggestion[];
}

export type Suggestion =
  | SuggestionField
  | SuggestionBlock
  | SuggestionNode
  | SuggestionCustom;

export interface SuggestionField {
  readonly type: 'field';
  readonly typename: NodeTypename<DataSchema>;
  readonly name: string;
  readonly parent?: {
    depth: number;
    prefix: string;
    typename: NodeTypename<DataSchema>;
    name: string;
  };
  readonly icon?: JSX.Children;
  readonly inline?: boolean;
  readonly value: string;
}

export interface SuggestionBlock {
  readonly type: 'block';
  readonly group: string;
  readonly name: string;
  readonly icon?: JSX.Children;
  readonly value: BlockPreset;
}

export interface SuggestionNode {
  readonly type: 'node';
  readonly typename: NodeTypename<DataSchema>;
  readonly name: string;
  readonly icon?: JSX.Children;
  readonly node: DataNode<DataSchema, NodeTypename<DataSchema>>;
}

export interface SuggestionCustom {
  readonly type: 'custom';
  readonly key: string;
  readonly group: string;
  readonly name: string;
  readonly icon?: JSX.Children;
}

export interface MarkupToolbarProps {
  locked: boolean;
  readonly editor: MarkupTreeProps;
  readonly selection: ScopedDocumentSelection | null;
}

export interface OnLinkMountOptions {
  routeId?: string;
  lid?: string;
}

export interface ClientAdmin {
  useSelection?: () => GlobalSelection;
  useOutline?: <
    S extends Schema,
    Type extends NodeTypename<S> = NodeTypename<S>,
  >(
    nodeInput: DataNode<S, Type>,
    options?: UseOutlineOptions<S, Type>,
  ) => OutlineEntry<S, Type>;
  useToolbar?: (children: JSX.Children) => void;
  selectFile?: (
    options?: SelectFileOptions,
  ) => Promise<DataNode<DataSchema, 'File'> | undefined>;
  showCreateOrEditNodeDialog?: <S extends Schema, T extends NodeTypename<S>>(
    props: CreateOrEditNodeProps<S, T>,
  ) => Promise<DataNode<S, T> | null>;
  MarkupToolbar?: (props: MarkupToolbarProps) => JSX.Element;
  onLinkMount?: (
    options: OnLinkMountOptions,
  ) => (element: HTMLAnchorElement) => void;
}

export const admin: ClientAdmin = {};
