import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { MailCSSProperties } from '.';
import { getTheme } from '../styles/applyTheme';
import styles from './Mail.module.css';

interface MailWrapperProps {
  class?: string;
  width: number;
  padding?: string;
  wrapperStyle?: Partial<MailCSSProperties>;
  style?: Partial<MailCSSProperties>;
  children?: JSX.Children;
}

export function MailWrapper(props: MailWrapperProps) {
  const theme = getTheme();

  const state = store({
    get wrapperStyle(): Partial<MailCSSProperties> {
      return {
        backgroundColor: (theme.email || theme).colors?.viewportBackground,
        borderCollapse: 'collapse',
        clear: 'both',
        ...(props.wrapperStyle || {}),
      };
    },
    get style(): Partial<MailCSSProperties> {
      return {
        clear: 'both',
        display: 'block',
        margin: '0 auto',
        maxWidth: `${props.width}px`,
        backgroundColor: (theme.email || theme).colors?.background,
        ...(props.style || {}),
      };
    },
  });

  return (
    <table
      class={bind(() => [styles.wrapper, props.class])}
      style={bind(() => state.wrapperStyle)}
      width="100%"
      cellpadding={bind(() => props.padding ?? '0')}
      cellspacing="0"
      bgcolor={bind(() => state.wrapperStyle.backgroundColor)}
    >
      <tbody>
        <tr>
          <td />
          <td
            style={bind(() => state.style)}
            align={bind(() => state.style.textAlign)}
            bgcolor={bind(() => state.style.backgroundColor)}
          >
            <div
              style={bind(() => ({
                maxWidth: `${props.width}px`,
              }))}
            >
              {props.children}
            </div>
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  );
}
