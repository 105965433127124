import type { RouteDefinition } from '@donkeyjs/core';
import { bind, componentContext, withContext } from '@donkeyjs/jsx-runtime';
import { map } from '@donkeyjs/proxy';
import { admin } from '../admin';
import { session } from '../session';
import styles from './Menu.module.css';
import type { GetMenuOptions, MenuRoute } from './getMenu';
import { useMenu } from './useMenu';

export interface MenuProps
  extends Omit<GetMenuOptions, 'activeRoute' | 'user' | 'app'> {
  readonly class?: JSX.ClassNames;
  readonly routes?: MenuRoute[];
  readonly activeRoute?: RouteDefinition;
  readonly onRouteClick?: (route: RouteDefinition) => void;
  readonly render?: (route: RouteDefinition) => JSX.Children;
}

export function Menu(props: MenuProps) {
  const state = useMenu(props);
  const context = componentContext.current!;

  const renderItem = (route: MenuRoute) => (
    <li>
      <a
        class={bind(() => [styles.route, { active: route.isActive }])}
        href={bind(() => session.router.getPath({ route: route.route }))}
        onclick={() => props.onRouteClick?.(route.route)}
        onmount={withContext(context, () =>
          admin.onLinkMount?.({
            routeId: route.route.node?.id,
          }),
        )}
      >
        {() => (props.render ? props.render(route.route) : route.route.name)}
      </a>
      {() =>
        route.submenu.length > 0 && <ul>{route.submenu.map(renderItem)}</ul>
      }
    </li>
  );

  return () =>
    state.level.length > 0 && (
      <ul class={bind(() => props.class)}>
        {map(() => state.level, renderItem)}
      </ul>
    );
}
