import type { FieldRenderProps, I18nContext } from '../../../..';
import type { useDateComponent } from '../useDateComponent';

export const format = (
  state: ReturnType<typeof useDateComponent>,
  p: FieldRenderProps<'date'>,
  i18n: I18nContext,
): string => {
  if (!p.field.value) return '';

  if (
    state.isRangeStart &&
    !state.rangeIsCollapsed &&
    p.formatRange !== null &&
    !p.noRange
  ) {
    return p.formatRange
      ? p.formatRange(p.field.value, state.rangeEnds)
      : [
          i18n.formatDate(
            p.field.value,
            p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
          ),
          !!state.rangeEnds &&
            i18n.formatDate(
              state.rangeEnds,
              p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
            ),
        ]
          .filter(Boolean)
          .join(' - ');
  }

  return i18n.formatDate(
    p.field.value,
    p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
  );
};
