import type { DataNode } from '@donkeyjs/proxy';
import type { DataRequestSelection } from '@donkeyjs/server';
import type {
  Appearance,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import type { OrderableItem, OrderableKey } from '.';
import type { CouponFields } from '../shared/coupons/validateCoupon';
import type {
  OrderDiscountFields,
  OrderPaymentPlanFields,
} from '../shared/order/getOrderOverview';

export interface ParsedOrderItem {
  key: OrderableKey;
  orderable: OrderableItem<OrderableKey>;
  orderables: { item: OrderableItem<OrderableKey>; name: string }[];
  discounts: OrderDiscountFields[];
  validateCoupon?: <T extends CouponFields>(coupon: T) => boolean;
  paymentSchemes: OrderPaymentPlanFields[];
  earliestPaymentAt?: Date | null;
  name: string;
  option: string;
  image?: DataNode<DataSchema, 'FileRef'>;
  maxQuantity?: number;
}

export interface OrderItemParser<Fields extends {}> {
  selection: DataRequestSelection<DataSchema, 'OrderItem'>;
  couponSelection?: DataRequestSelection<DataSchema, 'Coupon'>;
  couponNeedsValidation?: <T extends CouponFields>(coupon: T) => boolean;
  parse(item: Fields): Omit<ParsedOrderItem, 'origin'> | null | undefined;
}

export interface ShoppingClientSettings {
  stripe?: {
    publicKey: string;
    testKey?: string;
    appearance?: Appearance;
    elementOptions?: StripePaymentElementOptions;
  };
  cart?: {
    itemView?: 'default' | 'option-selection';
    enableCompanyFields?: boolean;
  };
  auth?: {
    orderNeedsRole?: string;
  };
  parsers?: OrderItemParser<any>[];
  maxItemQuantity?: (item: OrderableItem<OrderableKey>) => number;
}

let settings: ShoppingClientSettings = {};

export const setShoppingSettings = (options: ShoppingClientSettings) => {
  settings = options;
};

export const getShoppingSettings = () => settings;
