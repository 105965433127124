import type { AccessType } from '@donkeyjs/core';
import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import {
  type Culture,
  type DataList,
  type DataNode,
  type FieldsFragment,
  type NodeTypename,
  type Schema,
  meta,
  store,
} from '@donkeyjs/proxy';
import { differenceInMinutes } from 'date-fns';
import { session } from '../session';
import { showAuthenticationDialog } from './AuthenticationDialog';
import type { RegistrationForm } from './getRegistrationForm';

export interface UserLogin {
  email: string;
  password: string;
}

export type UserContext = ReturnType<typeof setUserContext>;

const key = Symbol('user');

export let browserUser: DataNode<DataSchema, 'User'> | undefined;

export const setUserContext = (options: {
  user: DataList<DataSchema, 'User'> | null;
  confirm: () => Promise<boolean>;
}) => {
  if (typeof window !== 'undefined' && options.user?.[0]) {
    browserUser = options.user[0];
  }

  const changed = store({
    request: undefined as DataList<DataSchema, 'User'> | null | undefined,
  });

  function requestRole(roles: string[], role: string) {
    if (roles.includes(role)) return true;
    return showAuthenticationDialog({
      async onLogin(login) {
        return await context.login(login);
      },
      async onRegister(register) {
        return await context.register(register);
      },
    });
  }

  const context = store({
    get userRequest(): DataList<DataSchema, 'User'> | null {
      return changed.request === undefined ? options.user : changed.request;
    },

    set userRequest(user: DataList<DataSchema, 'User'> | null | undefined) {
      changed.request = user;
    },

    get user(): DataNode<DataSchema, 'User'> | null {
      return this.userRequest?.[0] || null;
    },

    get culture() {
      return (
        (this.user?.uiCulture as Culture) || session.app.schema.defaultCulture
      );
    },

    get roles() {
      return this.user?.roles?.split(',') || ['visitor'];
    },

    get theme(): 'DARK' | 'LIGHT' {
      return (this.user?.theme as 'DARK' | 'LIGHT') || 'DARK';
    },

    get hasLoggedInRecently(): boolean {
      if (!context.user?.lastLogin) return false;
      return differenceInMinutes(new Date(), context.user.lastLogin) <= 5;
    },

    get isLoggedIn() {
      return !!this.user && !meta(this.user).isLocal;
    },
    get isAdmin() {
      return this.roles.includes('admin') || this.roles.includes('sysadmin');
    },
    get isSysAdmin() {
      return this.roles.includes('sysadmin');
    },

    matchRoles(roles: string[]): boolean {
      for (const matchRole of roles)
        if (this.roles.includes(matchRole)) return true;
      return false;
    },

    hasRole(role: string): boolean {
      return this.roles.includes(role);
    },

    can<S extends Schema, Typename extends NodeTypename<S>>(
      action: AccessType,
      nodeOrTypename: DataNode<S, Typename> | Typename,
      fieldName?: keyof S['nodes'][Typename]['fields'],
    ): boolean {
      return can(this.user, action, nodeOrTypename, fieldName);
    },

    canEdit<S extends Schema, Typename extends NodeTypename<S>>(
      node: DataNode<S, Typename>,
    ) {
      return can(this.user, meta(node).isLocal ? 'insert' : 'update', node);
    },

    async confirm() {
      if (context.hasLoggedInRecently) return true;
      return await options.confirm();
    },

    requestRole(role: string) {
      return requestRole(this.roles, role);
    },

    async login(data: UserLogin, noRedirect?: boolean) {
      const result = await session.data.mutation.login(data, UserFields);
      if (result.data?.id && !noRedirect) {
        const next = session.router.query.next?.[0];
        if (next) {
          if (next.startsWith('/')) {
            session.router.navigate(next);
          } else {
            const path = session.router.getPath({ routeKey: next });
            if (path) session.router.navigate(path);
          }
        }
        const roles = result.data?.roles?.split(',') || [];
        if (roles.includes('admin') || roles.includes('sysadmin')) {
          window.location.reload();
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      }
      return result.errors;
    },

    async register(data: RegistrationForm) {
      const result = await session.data.mutation.createUser(
        { data },
        UserFields,
      );
      return result.errors;
    },

    async logout() {
      const result = await session.data.mutation.logout();
      if (result.data === true) {
        window.location.reload();
      }
      if (result.errors?.[0]) throw result.errors[0];
    },
  });

  setContext(key, context);

  return context;
};

export const getUserContext = () => getContext<UserContext>(key);

export const UserFields: FieldsFragment<DataNode<DataSchema, 'User'>> = {
  email: true,
  ui: true,
  uiCulture: true,
  theme: true,
  openedChangelogAt: true,
  roles: true,
  createdAt: true,
  lastLogin: true,
};

const can = <S extends Schema, Typename extends NodeTypename<S>>(
  user: DataNode<DataSchema, 'User'> | null | undefined,
  action: AccessType,
  nodeOrTypename: DataNode<S, Typename> | Typename,
  fieldName?: keyof S['nodes'][Typename]['fields'],
): boolean => {
  const typename =
    typeof nodeOrTypename === 'string'
      ? nodeOrTypename
      : nodeOrTypename.__typename;

  if (!(typename in session.app.schema.nodes)) return true;

  const roles = user?.roles ? user.roles.split(',') : ['visitor'];
  if (user) {
    const can = session.app.permissions.can<any, any>(roles, action, typename);
    return fieldName ? can.field(fieldName) : can.node;
  }

  if (!(typename in session.app.schema.nodes)) return true;

  const can = session.app.permissions.can<any, any>(roles, action, typename);
  return fieldName ? can.field(fieldName) : can.node;
};

export const useReadonly = (value: () => boolean) => {
  const parentContext = getUserContext();
  setUserContext({
    get user() {
      return value() ? null : parentContext.userRequest;
    },
    get confirm() {
      return parentContext.confirm;
    },
  });
};

export function Readonly(props: { active: boolean; children?: JSX.Children }) {
  useReadonly(() => props.active);
  return () => props.children;
}
