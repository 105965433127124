import { createView, getTheme, text } from '@donkeyjs/client';
import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { AddToCartButton } from '@donkeyjs/module-shopping';
import { meta } from '@donkeyjs/proxy';
import { I18nSimon } from '../../i18n';
import { Detail } from '../components/Detail';
import { ListTile } from '../components/ListTile';
import { Tile } from '../components/Tile';
import { related } from '../components/related';
import styles from './ViewProduct.module.css';
import { tilesOrListClass, tilesOrListModes } from './helpers';

const workingOnCart = false;

export const ViewProduct = createView<DataSchema, 'Product'>(
  {
    name: () => 'Default View',
    dataContainerClass: tilesOrListClass,
    modes: tilesOrListModes,
    enableAsBlock: true,
  },

  function ViewProduct(props) {
    const theme = getTheme();

    return () =>
      props.selected ? (
        <Detail
          class={bind(() => props.class)}
          onmount={bind(() => props.onmount)}
          image={(ratio) => <props.node.$.images ratio={ratio} />}
          title={<props.node.$.name />}
          related={related({ node: props.node })}
          buttons={
            <>
              {() =>
                !!(
                  props.node.externalLinkUrl ||
                  (props.node.options.length && workingOnCart)
                ) && (
                  <div class="add-to-cart">
                    {() =>
                      props.node.externalLinkUrl ? (
                        <a
                          class={[theme.class.button, 'default']}
                          href={props.node.externalLinkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {() =>
                            props.node.externalLinkLabel ||
                            text(I18nSimon, 'Products.Buy')
                          }
                        </a>
                      ) : (
                        <AddToCartButton
                          kind="productOption"
                          item={props.node.options[0]}
                        />
                      )
                    }
                  </div>
                )
              }
              {() =>
                props.node.projects.$((rel) => (
                  <span
                    class={theme.class.button}
                    // href={session.router.getPath(
                    //   { route: 'current' },
                    //   { query: { project: [rel.project.id] } },
                    // )}
                  >
                    {() => rel.project.name}
                  </span>
                ))
              }
              {
                () => null
                /*                (!!props.node.externalPrice ||
                  (!props.node.options.$.isLoading &&
                    !!props.node.options.length)) &&
                html`<span class={styles.price}>
                  ${() =>
                    formatMoney(
                      i18n,
                      props.node.externalPrice || props.node.options[0].price,
                    )}
                </span>`*/
              }
            </>
          }
        >
          <props.node.$.info />
          <props.node.$.repertoire class={styles.repertoire} />
          <props.node.$.moreInfo class={styles.moreInfo} />
        </Detail>
      ) : (
        <a href={props.href} onmount={props.onmount}>
          {() =>
            jsxx(props.mode?.key === 'list' ? ListTile : Tile, {
              get readonly() {
                return props.readonly;
              },
              image: (ratio) => <props.node.$.images ratio={ratio} readonly />,
              get class() {
                return props.class;
              },
              square: true,
              get title() {
                return props.mode?.key === 'list' ? (
                  <props.node.$.name readonly />
                ) : null;
              },
              get button() {
                return (
                  (meta(props.node.options).isLoading ||
                    (props.node.options.length === 1 && workingOnCart)) && (
                    <div class="add-to-cart">
                      <AddToCartButton
                        kind="productOption"
                        item={props.node.options[0]}
                        style="icon-only"
                      />
                    </div>
                  )
                );
              },
              children: () =>
                props.mode?.key === 'list' ? (
                  <div class={styles.info}>
                    {() => props.node.info[0]?.text}
                  </div>
                ) : null,
            })
          }
        </a>
      );
  },
);
