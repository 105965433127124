import { batch } from '@preact/signals-core';
import type { Culture } from '../Culture';
import { extendStatusFragment } from '../helpers/extendStatusFragment';
import { generateNodeId } from '../helpers/generateNodeId';
import { isMatch } from '../helpers/isMatch';
import { meta } from '../helpers/meta';
import { type ValidationError, validateValue } from '../helpers/validate';
import { type Markup, createMarkupString } from '../markup/Markup';
import type {
  AnonymousFragment,
  AppSchema,
  FieldSchema,
  FieldsFragment,
  NodeFieldsFromSchema,
  NodeSchema,
  NodeTypename,
  Schema,
  StatusFragment,
} from '../schema';
import { type Store, store } from '../store';
import type { DataError } from './DataError';
import {
  type DataList,
  type List,
  createDataList,
  isDataList,
} from './DataList';
import { type NodeFieldInfo, getNodeField } from './getNodeField';
import type { NodeFactory } from './nodeFactory';

export const DataNodeApply = {
  implementation: undefined as
    | ((node: Node) => DataNodeApply<any, any>)
    | undefined,
};

export interface IntrinsicNodeFields<Typename extends string = string> {
  readonly __typename: Typename;
  readonly id: string;
  readonly createdAt?: Date;
  updatedAt?: Date;
  draft?: boolean;
}

const intrinsicFields = ['__typename', 'id', 'createdAt', 'updatedAt', 'draft'];

export type Node<
  Fields extends { readonly __typename: string } = {
    readonly __typename: string;
  },
> = IntrinsicNodeFields & Fields & { readonly $: NodeApply<Fields> };

export type DataNode<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> = IntrinsicNodeFields<Typename> &
  DataNodeFields<S, Typename> & {
    readonly $: NodeApply<DataNode<S, Typename>>;
  };

export type DataNodeFields<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> = {
  [Key in keyof NodeFieldsFromSchema<S, Typename>]: NonNullable<
    NodeFieldsFromSchema<S, Typename>[Key]
  > extends { readonly __typename: infer T extends NodeTypename<S> }
    ? DataNode<S, T>
    : NodeFieldsFromSchema<S, Typename>[Key] extends {
          readonly __typename: infer T extends NodeTypename<S>;
        }[]
      ? DataList<S, T>
      : NodeFieldsFromSchema<S, Typename>[Key];
};

// export type PartialNode<Fields extends { readonly __typename: string }> =
//   IntrinsicNodeFields & PartialNodeFields<Fields>;

// type PartialNodeFields<Fields extends { readonly __typename: string }> = {
//   [Key in keyof Fields]?: NonNullable<Fields[Key]> extends Node<
//     infer N extends { readonly __typename: string }
//   >
//     ? PartialNode<N>
//     : Fields[Key] extends Node<infer N extends { readonly __typename: string }>
//       ? PartialNode<N>[]
//       : Fields[Key];
// };

export interface NodeMeta<T extends { readonly __typename: string }> {
  // Status
  readonly isLoading: boolean;
  readonly isTest: boolean;
  isCreating: 'new' | 'stored' | undefined;
  isLocal: boolean;
  fieldStatus: StatusFragment | undefined;
  request(fragment: FieldsFragment<T>): void;

  // Data
  readonly store: Store<Omit<T, '__typename' | '$'>>;
  mutated: Partial<Omit<T, '__typename' | '$'>>;
  errors: (DataError | ValidationError)[];
  fix(): void;
  validate(fields?: (keyof T)[]): ValidationError[];
  sources: string[];
  peek<Key extends keyof T>(
    key: Key,
    options?: {
      allowTracked?: boolean;
    },
  ): T[Key];
  getField<Key extends keyof Omit<T, '__typename' | '$'>>(
    key: Key,
  ): NodeFieldInfo<T[Key]>;
  get references(): {
    [Key in {
      [Key in keyof T]: T[Key] extends DataNode<any, any>
        ? Key
        : T[Key] extends DataList<any, any>
          ? Key
          : never;
    }[keyof T]]: NodeFieldInfo<T[Key]>;
  };
  getValue(nestedKey: string, preventLazyLoad?: boolean): any;
  setValue(nestedKey: string, value: any): void;
  isMatch(args: any): boolean | Culture;
  testValues(values: Partial<Omit<T, '__typename' | '$'>>): () => void;

  // I18n
  readonly culture: Culture;
  getCulture(culture: Culture): T;

  // Schema
  readonly appSchema: AppSchema | undefined;
  readonly schema: NodeSchema | undefined;

  // Mutations
  delete(): void;
}

type NodeApply<Fields extends { readonly __typename: string }> = {
  [Key in Exclude<keyof Fields, '$' | '__typename' | 'id'>]-?: DataNodeApply<
    Fields,
    Key
  > extends (field: Key, props: infer Props) => infer R
    ? (props: Props) => R
    : never;
};

export interface CreateDataNodeOptions<
  Fields extends { readonly __typename: string },
> {
  values: Omit<Partial<IntrinsicNodeFields>, '__typename'> & Fields;
  loading?: boolean;
  test?: boolean;
  creating?: boolean;
  fieldStatus?: StatusFragment;
  schema?: AppSchema;
  nodeSchema?: NodeSchema;
  source?: string;
  errors?: (DataError | ValidationError)[];
  depth?: number;

  factory?: NodeFactory;

  dispose?(): void;
  scheduleFieldRequest?(): void;
  scheduleMutation?(): void;
}

export function createDataNode<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  options: CreateDataNodeOptions<
    { readonly __typename: Typename } & DataNodeFields<S, Typename>
  >,
): DataNode<S, Typename>;
export function createDataNode<Fields extends { readonly __typename: string }>(
  options: CreateDataNodeOptions<Fields>,
): Node<Fields>;
export function createDataNode<Fields extends { readonly __typename: string }>(
  options: CreateDataNodeOptions<Fields>,
): Node<Fields> {
  const {
    factory,
    loading = false,
    test: isTest,
    source,
    errors,
    schema: appSchema,
    dispose,
  } = options;
  const typename = options.values.__typename as string;

  const state = store({
    fieldStatus: options.fieldStatus,
    isCreating: (options.creating ? 'new' : undefined) as
      | 'new'
      | 'stored'
      | undefined,
    fieldErrors: {} as Record<string | symbol | number, ValidationError[]>,
    sources: source ? [source] : [],
    externalErrors: errors,
    mutated: {} as Record<string | symbol | number, any>,
    touched: {} as Record<string | symbol | number, boolean>,
    get errors(): (DataError | ValidationError)[] {
      return [
        ...(state.externalErrors || []),
        ...Object.values(state.fieldErrors).flat(),
      ];
    },
    get isTest() {
      return isTest || testing.items.length > 0;
    },
  });

  function requestFields(fields: AnonymousFragment) {
    const fieldStatus = state.$.peek('fieldStatus');
    if (!appSchema || !fieldStatus) return;

    const hasChanged = extendStatusFragment(
      'requested',
      state.$.peek('fieldStatus')!,
      fields,
      appSchema!,
      typename,
    );
    if (!loading && hasChanged) {
      options.scheduleFieldRequest?.();
    }
  }

  function scheduleMutation() {
    if (!state.fieldStatus && !state.isCreating) return;
    if (loading || state.isTest) return;
    options.scheduleMutation?.();
  }

  const nodeSchema = options.nodeSchema || appSchema?.nodes[typename];
  function fieldSchema(field: string) {
    return nodeSchema?.fields[field] || nodeSchema?.reverseFields[field];
  }

  const testing = store({
    items: [] as Record<string | symbol | number, any>[],
    get merged() {
      return testing.items.reduce(
        (merged, item) => Object.assign(merged, item),
        {} as Record<string | symbol | number, any>,
      );
    },
  });

  const values = store<Node<Fields>>(
    {
      ...(options.values as Node<Fields>),
      id: options.values?.id || generateNodeId(),
      ...(options.creating
        ? {
            createdAt: new Date(),
            updatedAt: new Date(),
          }
        : {}),
    },
    {
      beforeSet(source, key) {
        const current = source[key];
        if (current && isDataNode(current)) {
          if (meta(current).isLoading) {
            meta(current).delete();
            nestedLoaders.delete(current);
          }
        }
      },
    },
  );

  const timeZoneFields: Record<string, Set<string>> = {};
  const nestedLoaders = new Set<Node | List>();
  const instances = new Map<string, Node<Fields>>();
  let references: undefined | Record<string, NodeFieldInfo<any>>;

  function getInstance(culture: Culture): Node<Fields> {
    if (instances.has(culture)) return instances.get(culture)!;

    const isDefaultCulture = culture === appSchema?.cultures[0];

    function getFieldName(prop: string, prefix?: string) {
      const field = nodeSchema?.fields[prop];
      return !field?.i18n || culture === (appSchema?.cultures[0] || 'global')
        ? prefix
          ? `${prop}${prefix}`
          : prop
        : `${prop}${prefix ?? ''}__${culture.replace('-', '_')}`;
    }

    function getNestedValue(key: string, preventLazyLoad?: boolean) {
      const [field, rest] = key.split('.');
      const value = get(values, field, values, preventLazyLoad);
      if (!rest) return value;
      return meta(value)?.getValue(rest, preventLazyLoad);
    }

    function setNestedValue(key: string, value: any) {
      const [field, rest] = key.split('.');
      if (!rest) {
        set(values, field, value, values);
      } else {
        const nested = values.$.peek(field as keyof Fields);
        if (isDataNode(nested)) {
          meta(nested).setValue(rest, value);
        }
      }
    }

    function createNestedList(
      fieldName: string,
      field: FieldSchema,
      skipLoading?: boolean,
    ) {
      const fieldStatus =
        state.fieldStatus &&
        ((state.fieldStatus[fieldName] ??= {
          id: skipLoading ? 'ready' : 'requested',
        }) as StatusFragment);
      const listLoading = fieldStatus?.id === 'requested';
      const result = createDataList({
        typename: field.type,
        schema: appSchema,
        match: { where: { [field.reverse!]: { eq: values.$.peek('id') } } },
        sort: field.sort?.[0]?.order,
        factory: factory!,
        loading: listLoading,
        test: isTest,
        creating: !!state.isCreating,
        placeholderCount: 3,
        fieldStatus,
      });
      if (listLoading) {
        nestedLoaders.add(result);
        if (!loading) {
          options.scheduleFieldRequest?.();
        }
      }
      return result;
    }

    const fieldsCache = new Map<string, NodeFieldInfo<any>>();

    const nodeMeta: Omit<NodeMeta<Fields>, keyof Function> = {
      get isLocal() {
        return !state.fieldStatus && !state.isCreating;
      },

      set isLocal(value) {
        if (value) {
          batch(() => {
            state.fieldStatus = undefined;
            state.isCreating = undefined;
          });
        } else {
          if (loading || state.isTest) return;
          if (!state.fieldStatus) {
            state.isCreating = 'new';
          }
          options.scheduleMutation?.();
        }
      },

      isLoading: loading,

      get isCreating() {
        return state.isCreating;
      },

      set isCreating(value) {
        state.isCreating = value;
      },

      get isTest() {
        return state.isTest;
      },

      get fieldStatus() {
        return state.fieldStatus;
      },

      set fieldStatus(value) {
        state.fieldStatus = value;
      },

      getValue: getNestedValue,
      setValue: setNestedValue,

      request: requestFields,

      peek(key, options) {
        return options?.allowTracked
          ? get(values, key, values, true)
          : (values.$.peek(
              typeof key === 'symbol'
                ? key
                : (getFieldName(key.toString()) as keyof Fields),
            ) as any);
      },

      getField(key) {
        return getNodeField({
          node: instance,
          key,
          cache: fieldsCache,
          state,
          testing,
        });
      },

      get references() {
        if (!references) {
          references = {};
          for (const key in nodeSchema
            ? { ...nodeSchema.fields, ...nodeSchema.reverseFields }
            : {}) {
            const field = fieldSchema(key)!;
            if (!field.enum && !field.scalar) {
              references[key] = getNodeField({
                node: instance,
                key,
                cache: fieldsCache,
                state,
                testing,
              });
            }
          }
        }
        return references as any;
      },

      testValues(values) {
        // Todo: i18n
        const add: any = { ...values };
        for (const key in add) {
          const field = fieldSchema(key);
          if (field) {
            const [corrected, error] = validateValue(
              key,
              add[key],
              appSchema!,
              field,
              factory,
            );
            if (error) {
              delete add[key];
            } else if (corrected !== null && corrected !== add[key]) {
              add[key] = corrected;
            }
          }
        }
        testing.items = [...testing.items, add];
        return () => {
          testing.items = testing.items.filter((item) => item !== add);
        };
      },

      store: values,
      get mutated() {
        return state.mutated as NodeMeta<Fields>['mutated'];
      },

      set mutated(value) {
        state.mutated = value;
      },

      get errors() {
        return state.errors;
      },

      set errors(value) {
        state.externalErrors = value;
      },

      fix() {
        if (!nodeSchema) return;
        for (const [key, field] of Object.entries(nodeSchema.fields)) {
          const fieldName = getFieldName(key);
          const current = values.$.peek(fieldName);
          const [value] = validateValue(
            key,
            current,
            appSchema!,
            field,
            factory,
          );
          if (value !== current) {
            set(values, key, value, values);
          }
        }
      },

      validate(fields) {
        batch(() => {
          for (const key of fields || Reflect.ownKeys(values)) {
            const field = fieldSchema(key as string);
            if (!field) continue;
            const fieldName = getFieldName(key as string);
            const current = values.$.peek(fieldName);
            const [_, error] = validateValue(
              key as string,
              current,
              appSchema!,
              field,
              factory,
            );
            if (error) {
              state.fieldErrors = {
                ...state.fieldErrors,
                [key]: [error],
              };
            } else {
              state.fieldErrors[key] = [];
            }
            state.touched = {
              ...state.touched,
              [fieldName]: true,
            };
          }
        });
        return fields
          ? fields.flatMap((key) => state.fieldErrors[key] || [])
          : Object.values(state.fieldErrors).flat();
      },

      culture: culture as Culture,
      getCulture(culture) {
        return getInstance(culture);
      },

      appSchema,
      schema: nodeSchema,

      get sources() {
        return state.sources;
      },

      set sources(value) {
        state.sources = value;
      },

      isMatch(args) {
        if (state.fieldStatus?.id === 'deleted') return false;
        const instance = getInstance(culture);
        return (
          !!(state.isCreating && instance.draft) ||
          isMatch(args, instance, state.sources, !loading)
        );
      },

      delete() {
        batch(() => {
          if (state.fieldStatus || state.isCreating) {
            state.fieldStatus = { id: 'deleted' };
            scheduleMutation();
          }
          for (const nested of nestedLoaders) {
            if (isDataList(nested)) {
              meta(nested).dispose();
            } else {
              meta(nested as Node).delete();
            }
          }
          nestedLoaders.clear();
          if (!loading) {
            for (const [key, schema] of Object.entries(
              nodeSchema?.reverseFields || {},
            )) {
              if (schema.reverse && schema.cascadeDelete) {
                const value = createNestedList(key, schema, true);
                // Nodes will be deleted on the server, only need to purge from cache
                for (const item of value) {
                  factory?.purgeNodeFromCache(item as Node);
                }
              }
            }
          }
          dispose?.();
        });
      },
    };

    let apply: any = null;
    const applyCache: Record<string | symbol, any> = {};
    const applyFunction = function (this: any, ...args: any[]) {
      if (!DataNodeApply.implementation) return;
      apply ??= DataNodeApply.implementation(instance);
      return apply?.call(this, ...args);
    };

    const $ = new Proxy(
      {},
      {
        get(target, prop) {
          return (applyCache[prop] ??= (props: any) =>
            applyFunction.call(target, prop, props));
        },
      },
    );

    function get(
      target: any,
      prop: any,
      receiver: any,
      preventLazyLoad?: boolean,
    ) {
      if (prop === '__store') return 'node';
      if (prop === '__meta') {
        return nodeMeta;
      }
      if (prop === '$') {
        return $;
      }
      if (prop === 'toString') {
        return () => {
          return (
            factory?.nodeToString(instance) ||
            (nodeSchema?.fields && 'name' in nodeSchema.fields
              ? get(target, 'name', receiver)
              : nodeSchema?.fields && 'title' in nodeSchema.fields
                ? get(target, 'title', receiver)
                : `{${typename}}`) ||
            ''
          );
        };
      }

      let result: any;
      if (typeof prop === 'symbol') {
        result = Reflect.get(target, prop, receiver);
      } else {
        const fieldName = getFieldName(prop);
        const field = fieldSchema(prop as string);
        const markupFieldName = (field as FieldSchema<'string'>)?.markup
          ? getFieldName(prop, 'Markup')
          : undefined;
        const tzFieldName =
          factory?.tz && (field as FieldSchema<'date'>)?.timeZoneField;
        function getFieldValue(key: string) {
          if (!preventLazyLoad && key !== '__typename' && key !== 'id') {
            requestFields({ [key]: true });
          }
          return key in testing.merged
            ? testing.merged[key]
            : Reflect.get(target, key, receiver);
        }
        result =
          nodeSchema && !field && !intrinsicFields.includes(prop)
            ? undefined
            : getFieldValue(fieldName);
        if (result !== undefined && markupFieldName) {
          result = createMarkupString(
            result ?? '', // can be null
            (getFieldValue(markupFieldName) as Markup) ?? [],
          );
        }
        if (result !== undefined && tzFieldName) {
          (timeZoneFields[tzFieldName] ??= new Set()).add(fieldName);
          const timeZone =
            getFieldValue(tzFieldName) || factory.tz!.defaultTimeZone;
          result = factory.tz!.utcToZonedTime(result, timeZone);
        }

        const fieldErrors = state.$.peek('fieldErrors');
        if (field && !fieldErrors[fieldName]) {
          let [corrected, error] = validateValue(
            prop,
            result,
            appSchema!,
            field,
            factory,
          );
          batch(() => {
            if (field.array && field.reverse) {
              if (corrected !== result || corrected == null) {
                result =
                  corrected ??
                  (factory ? createNestedList(fieldName, field) : undefined);
                Reflect.set(target, fieldName, result, receiver);
              }
            } else if (
              (loading ||
                (!preventLazyLoad &&
                  state.fieldStatus &&
                  state.fieldStatus.id !== 'deleted' &&
                  (!(state.fieldStatus[fieldName] as StatusFragment)?.id ||
                    (state.fieldStatus[fieldName] as StatusFragment).id ===
                      'requested'))) &&
              !field.scalar &&
              !field.enum
            ) {
              if (meta(corrected)?.fieldStatus?.id === 'deleted')
                corrected = null;
              if (corrected !== result || corrected == null) {
                result =
                  corrected ??
                  factory?.ensureNode(
                    {
                      __typename: field.type,
                      id: `loading-${generateNodeId()}`,
                    },
                    {
                      fieldStatus:
                        state.fieldStatus &&
                        ((state.fieldStatus[fieldName] ??= {
                          id: 'requested',
                        }) as StatusFragment),
                    },
                  );
                if (result) nestedLoaders.add(result);
                if (!loading) {
                  options.scheduleFieldRequest?.();
                }
                Reflect.set(target, fieldName, result, receiver);
              }
            } else if (corrected !== result) {
              Reflect.set(target, fieldName, corrected, receiver);
              result = corrected;
            }
            if (error) {
              state.fieldErrors = {
                ...fieldErrors,
                [fieldName]: error ? [error] : [],
              };
            } else {
              state.fieldErrors[fieldName] = [];
            }
          });
        }
      }

      if (!isDefaultCulture && (isDataNode(result) || isDataList(result))) {
        return meta(result as any).getCulture(culture);
      }

      return result;
    }

    function set(target: any, prop: any, valueInput: any, receiver: any) {
      if (prop === 'id' || prop === '__typename') return false;
      if (typeof prop === 'symbol') {
        return Reflect.set(target, prop, valueInput, receiver);
      }

      const field = fieldSchema(prop as string);
      const fieldName = getFieldName(prop as string);

      const [value, error] = field
        ? validateValue(prop, valueInput, appSchema!, field, factory)
        : [valueInput];

      if (error && value === undefined) {
        state.fieldErrors = {
          ...state.fieldErrors,
          [fieldName]: [error],
        };
        return true;
      }

      const markupFieldName =
        value instanceof String && 'markup' in value
          ? getFieldName(prop, 'Markup')
          : undefined;
      const tzFieldName =
        factory?.tz && (field as FieldSchema<'date'>)?.timeZoneField;

      return batch(() => {
        const fieldStatus = state.$.peek('fieldStatus');
        if (fieldStatus && appSchema) {
          extendStatusFragment(
            'ready',
            fieldStatus,
            { [fieldName]: true },
            appSchema,
            typename,
          );
        }
        const fieldErrors = state.$.peek('fieldErrors');
        if (!fieldErrors?.[fieldName] || fieldErrors[fieldName][0] !== error) {
          state.fieldErrors = {
            ...state.fieldErrors,
            [fieldName]: error ? [error] : [],
          };
        }
        if (!state.$.peek('touched')[fieldName]) {
          state.touched = {
            ...state.touched,
            [fieldName]: true,
          };
        }

        const oldValue = values.$.peek(fieldName);
        let newValue = value instanceof String ? value.toString() : value;
        if (field?.array && field.reverse) {
          if (value != null && Array.isArray(value)) {
            newValue = oldValue || createNestedList(fieldName, field);
            newValue.push(...value);
          }
        } else {
          if (newValue !== oldValue) {
            state.mutated = { ...state.mutated, [fieldName]: oldValue };
            scheduleMutation();
          }
        }
        if (markupFieldName) {
          const oldMarkupValue = values.$.peek(markupFieldName);
          if (value.markup !== oldMarkupValue) {
            Reflect.set(target, markupFieldName, value.markup, receiver);
            state.mutated = {
              ...state.mutated,
              [markupFieldName]: oldMarkupValue,
            };
            scheduleMutation();
          }
        }
        if (tzFieldName) {
          const timeZone =
            (values.$.peek(tzFieldName) as string) ||
            factory!.tz!.defaultTimeZone;
          newValue = factory!.tz!.zonedTimeToUtc(value as Date, timeZone);
        } else if (timeZoneFields[fieldName]) {
          const oldTimeZone = values.$.peek(fieldName) as string;
          const newTimeZone = value || factory!.tz!.defaultTimeZone;
          if (oldTimeZone !== newTimeZone) {
            for (const dateField of timeZoneFields[fieldName]) {
              const oldDate = values.$.peek(dateField);
              if (oldDate) {
                const zoned = factory!.tz!.utcToZonedTime(
                  oldDate as unknown as Date,
                  oldTimeZone,
                );
                const newUtc = factory!.tz!.zonedTimeToUtc(zoned, newTimeZone);
                state.mutated = { ...state.mutated, [dateField]: newUtc };
                scheduleMutation();
              }
            }
          }
        }
        return Reflect.set(target, fieldName, newValue, receiver);
      });
    }

    const instance = new Proxy(values, {
      get(target, prop, receiver) {
        return get(target, prop, receiver);
      },

      set(target, prop, value, receiver) {
        return set(target, prop, value, receiver);
      },

      has(target, prop) {
        return Reflect.has(target, prop) || prop in testing.merged;
      },

      getOwnPropertyDescriptor(target, p) {
        const writable = p !== '$' && p !== '__typename' && p !== 'id';
        return writable
          ? {
              configurable: true,
              enumerable: true,
              get: () => get(target, p, target),
              set: (value) => set(target, p, value, target),
            }
          : {
              configurable: true,
              enumerable: true,
              value: get(target, p, target),
              writable,
            };
      },

      ownKeys(target) {
        return Reflect.ownKeys(target);
      },
    });

    instances.set(culture, instance);
    return instance;
  }

  return getInstance((appSchema?.cultures[0] || 'global') as Culture);
}

export function isDataNode<
  Fields extends { readonly __typename: string } = IntrinsicNodeFields,
>(node: any, typename?: string): node is Node<Fields>;
export function isDataNode<S extends Schema, Typename extends NodeTypename<S>>(
  node: any,
  typename?: Typename,
): node is DataNode<S, Typename>;
export function isDataNode<
  Fields extends { readonly __typename: string } = IntrinsicNodeFields,
>(node: any, typename?: string): node is Node<Fields> {
  return (
    typeof node === 'object' &&
    node != null &&
    node.__store === 'node' &&
    (!typename || node.__typename === typename)
  );
}
