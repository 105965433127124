import { live, setState } from '@donkeyjs/jsx-runtime';
import type { Culture, NodeTypename } from '@donkeyjs/proxy';
import { setDataContext } from '../data/DataContext';
import { enableMailMode } from '../helpers/enableMailMode';
import { setI18n } from '../i18n/getI18n';
import { session } from '../session';
import { setViewContext } from '../views';
import { MailWrapper } from './MailWrapper';
import { setMailContext } from './mailContext';

export function MessageRoute() {
  enableMailMode();
  setMailContext({ target: 'mail', theme: session.app.theme });
  setViewContext({
    preferredView: 'mail-merge',
    preferredListView: 'mail-merge',
  });

  const query = session.router.getSchemaQuery({
    t: 'string?',
    c: 'string?',
    culture: 'string?',
  });

  const state = setState({
    get template() {
      const id = query.t;
      return id ? session.data.getNode('MessageTemplate', id) : undefined;
    },

    get context() {
      const context = query.c;
      const [typename, id] = context?.split(':') ?? [];
      return typename && id
        ? session.data.getNode(typename as NodeTypename<DataSchema>, id)
        : undefined;
    },
  });

  live(() => {
    if (state.context) {
      setDataContext(state.context);
    }
  });

  if (query.culture) {
    setI18n({ culture: query.culture as Culture });
  }

  return () => {
    if (!state.template) return null;
    return (
      <>
        {'||!**||'}
        <state.template.$.from readonly />
        {'||!**||'}
        <state.template.$.to readonly />
        {'||!**||'}
        <state.template.$.subject readonly />
        {'||!**||'}
        <MailWrapper width={900} padding="15">
          <state.template.$.body readonly />
        </MailWrapper>
      </>
    );
  };
}
