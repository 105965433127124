import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { store, type DataNode, type MarkupString } from '@donkeyjs/proxy';
import type { FieldProps } from '../..';
import type { BlockProps } from '../../../blocks';
import { ErrorBoundary } from '../../../helpers';
import { lazyComponent } from '../../../loaders';
import { getMailContext } from '../../../mail';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Markup, type MarkupProps } from '../../markup';
import { getTextBlockElement } from './helpers/getTextBlockElement';

export interface TextBlockSettings {
  align?: 'left' | 'center' | 'right' | 'justify';
}

const Editor = lazyComponent(
  'donkey/block-editor',
  () => import('./BlockEditor').then((m) => m.BlockEditor),
  'none',
);

export function getBlockProps<Settings extends {}>(
  props: Pick<FieldProps<'Block'>, 'readonly' | 'blocks'> & {
    value: DataNode<DataSchema, 'Block'>;
  },
  classNames?: () => JSX.ClassNames,
  onmount?: JSX.OnMount<HTMLElement>,
) {
  return store<BlockProps<Settings>>(
    {
      onmount,
      get class() {
        return classNames?.();
      },
      get readonly() {
        return props.readonly;
      },
      get block() {
        return props.value;
      },
      get context() {
        return props.blocks;
      },
    } as BlockProps<{}> as BlockProps<Settings>,

    {
      beforeGet(_, key) {
        if (
          ![
            'class',
            'onmount',
            'readonly',
            'block',
            'context',
            'onmount',
            'onClick',
          ].includes(key as string)
        ) {
          return (props.value.settings as any)?.[key] ?? undefined;
        }
      },

      beforeSet(_, key, value) {
        if (
          ![
            'class',
            'onmount',
            'readonly',
            'block',
            'context',
            'onmount',
            'onClick',
          ].includes(key as string)
        ) {
          if ((props.value.settings as any)?.[key] !== value) {
            props.value.settings = {
              ...(props.value.settings as any),
              [key]: value,
            };
          }
          return null;
        }
      },
    },
  );
}

type BlockComponentProps = FieldProps<'Block'> & {
  value: DataNode<DataSchema, 'Block'>;
};

export function Block(props: BlockComponentProps) {
  const theme = getTheme();
  const mail = getMailContext();

  return () => {
    if (!props.readonly) return jsxx(Editor, props);

    const render = props.value?.type
      ? props.value.type.startsWith('field:')
        ? session.app.blocks['node-field']?.component
        : session.app.blocks[props.value.type]?.component
      : undefined;

    if (render) {
      return (
        <ErrorBoundary>
          {jsxx(
            render,
            getBlockProps(props, () => 'block'),
          )}
        </ErrorBoundary>
      );
    }

    const markupProps = store<MarkupProps<MarkupString | string>>({
      get value() {
        return props.value.text || '\n';
      },
      get links() {
        return props.value.links;
      },
      readonly: true,
    });

    const el = () =>
      getTextBlockElement(theme, props.value.type, mail?.target === 'mail');

    return (
      <p
        $element={bind(() => el().element)}
        class={bind(() => [el().class, 'block'])}
        style={bind(() =>
          (props.value.settings as TextBlockSettings)?.align
            ? `text-align: ${(props.value.settings as TextBlockSettings).align}`
            : undefined,
        )}
      >
        {jsxx(Markup, markupProps)}
      </p>
    );
  };
}
