import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Slider.module.css';

interface SliderProps {
  value: number;
  float?: boolean;
  min: number;
  max: number;
  step?: number;
}

export function Slider(props: SliderProps) {
  return (
    <div class={styles.sliderContainer}>
      <input
        type="range"
        class={styles.slider}
        min={bind(() => props.min)}
        max={bind(() => props.max)}
        step={bind(() => props.step ?? (props.float ? 0.01 : 1))}
        value={bind(props, 'value')}
      />
    </div>
  );
}
