import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import { session } from '../session';

const key = Symbol('trackingContext');

export interface TrackingContext {
  sid: string;
}

export const getTrackingContext = () =>
  getContext<TrackingContext | undefined>(key) ?? {
    get sid() {
      return session.router?.route?.node?.id ?? '';
    },
  };

export function setTrackingContext(context: TrackingContext) {
  setContext(key, context);
  return context;
}
