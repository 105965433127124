import { Loader, createView, session } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import { formatComposition } from '@donkeyjs/module-music';
import { meta } from '@donkeyjs/proxy';
import styles from './ViewComposition.module.css';

export const ViewComposition = createView<DataSchema, 'Composition'>(
  {
    name: () => 'Default View',
    dataContainerClass: () => 'box',
    propertiesRequest: {
      category: {
        id: true,
      },
    },
  },

  function ViewComposition(props) {
    return (
      <div
        class={bind(() => [styles.composition, { draft: false }])}
        onmount={props.onmount}
      >
        <div class="side">
          <div>
            {() =>
              props.node.composer && (
                <span class={styles.composer}>
                  <Loader
                    loading={bind(() => meta(props.node).isLoading)}
                    type="inline-small"
                  >
                    {() => formatName(props.node.composer)}
                  </Loader>
                </span>
              )
            }

            <Loader
              loading={bind(() => meta(props.node).isLoading)}
              type="inline-large"
            >
              {() => formatComposition(props.node, session.app.schema)}
            </Loader>
          </div>
        </div>
      </div>
    );
  },
);
