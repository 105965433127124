import { bind, setState } from '@donkeyjs/jsx-runtime';
import type { DataList, DataNode, MarkupLinkType, Node } from '@donkeyjs/proxy';
import { admin } from '../../admin';
import { getAssetUrl } from '../../helpers';
import { getMailContext } from '../../mail';
import { session } from '../../session';
import { getTheme } from '../../styles';
import { sendBeacon } from '../../trace/sendBeacon';
import { getTrackingContext } from '../../trace/trackingContext';

interface MarkupLinkProps {
  entity: MarkupLinkType;
  links?: DataList<DataSchema, 'BlockLink'>;
  readonly?: boolean;
  children?: JSX.Children;
  selected?: boolean;
}

export function resolveMarkupLink(to: string) {
  if (to.startsWith('internal://')) {
    const [key, id] = to.slice(11).split('/');
    if (!key || !id) {
      return null;
    }
    const typename = (
      session.app.schema.nodes.BlockLink.fields as Record<
        string,
        { type: string }
      >
    )[key]?.type;
    return typename
      ? ({
          type: 'internal',
          key: key as keyof DataNode<DataSchema, 'BlockLink'>,
          typename: (
            session.app.schema.nodes.BlockLink.fields as Record<
              string,
              { type: string }
            >
          )[key].type,
          id,
        } as const)
      : null;
  }
  return {
    type: 'external',
    to,
  } as const;
}

export function MarkupLink(props: MarkupLinkProps) {
  const theme = getTheme();
  const mail = getMailContext();
  const tracking = getTrackingContext();

  const withHostname = (url: string) => {
    return mail?.target === 'mail'
      ? session.router.prependPathWithHostname(url)
      : url;
  };

  const state = setState({
    get resolved() {
      return resolveMarkupLink(props.entity.to);
    },

    get href() {
      if (!this.resolved) return undefined;
      if (this.resolved?.type === 'external') return this.resolved.to;
      const link = props.links?.find((link) => {
        const node =
          this.resolved && ((link as any)[this.resolved.key as any] as Node);
        return (
          !!node &&
          node.__typename === this.resolved?.typename &&
          node.id === this.resolved?.id
        );
      });
      if (!link) return undefined;
      const node = link[this.resolved.key!] as Node;
      if (node.__typename === 'File') {
        return withHostname(getAssetUrl(node as any));
      }
      return session.router.getPath(
        node.__typename === 'Route'
          ? { route: node as any }
          : { node: node as any },
      );
    },
  });

  const handleClick = () => {
    if (props.entity.lid) {
      sendBeacon({ lid: props.entity.lid, context: tracking });
    }
  };

  return () =>
    !state.href ? (
      <span>{props.children}</span>
    ) : mail?.target === 'mail' && props.entity.lid ? (
      <a
        class={theme.class.link}
        href={bind(() => {
          const baseUrl = `/.api/out?lid=${encodeURIComponent(
            props.entity.lid!,
          )}&s=m&url=${encodeURIComponent(state.href!)}`;
          const sidParam = tracking.sid
            ? `&sid=${encodeURIComponent(tracking.sid)}`
            : '';
          return withHostname(baseUrl + sidParam);
        })}
      >
        {props.children}
      </a>
    ) : (
      <a
        class={theme.class.link}
        href={bind(() => state.href)}
        target={props.entity.tg}
        onclick={handleClick}
        onmount={admin.onLinkMount?.({
          lid: props.entity.lid,
        })}
      >
        {props.children}
      </a>
    );
}
