import { bind } from '@donkeyjs/proxy';
import styles from './SpinnerBackground.module.css';

interface SpinnerBackgroundProps {
  readonly enabled: boolean;
  readonly delayed?: boolean;
  readonly children?: JSX.Element[];
}

export function SpinnerBackground(props: SpinnerBackgroundProps) {
  return () =>
    props.enabled ? (
      <div
        class={bind(() => [
          styles.spinnerBackground,
          { [styles.delayed]: props.delayed },
        ])}
      >
        {props.children}
      </div>
    ) : (
      props.children
    );
}
