import {
  type BlockProps,
  List,
  View,
  WithHeading,
  getI18n,
  getNow,
  getTheme,
  masonry,
  session,
} from '@donkeyjs/client';
import { bind, getContext, setContext } from '@donkeyjs/jsx-runtime';
import { compositionsSort } from '@donkeyjs/module-music';
import {
  type DataList,
  type DataNode,
  map,
  meta,
  store,
} from '@donkeyjs/proxy';
import { views } from '../views';
import styles from './SearchResults.module.css';

const contextKey = Symbol();

const getState = () =>
  getContext<{ text: string; keywords: string[] }>(contextKey);

export function SearchResultsBlock(props: BlockProps) {
  const state = store({
    get text() {
      return session.router.query.query?.[0]?.replace(/^\s+|\s+$/g, '');
    },
    get keywords() {
      return (
        session.router.query.query?.[0]
          ?.replace(/^\s+|\s+$/g, '')
          .split(/\s+/) || []
      );
    },
  });

  setContext(contextKey, state);

  return () =>
    !state.text ? null : (
      <Results class={bind(() => props.class)} text={state.text} />
    );
}

interface ResultsProps {
  class?: JSX.ClassNames;
  text: string;
}

function Results(props: ResultsProps) {
  const i18n = getI18n();

  const search = () => ({
    text: props.text,
    mode: { __literal: 'WEBSEARCH' as const },
    culture: i18n.culture,
  });

  const routes = session.data.useNodes({
    typename: 'Route',
    get search() {
      return search();
    },
  });

  const events = session.data.useNodes({
    typename: 'Event',
    get search() {
      return search();
    },
  });

  const articles = session.data.useNodes({
    typename: 'Article',
    get search() {
      return search();
    },
  });

  const products = session.data.useNodes({
    typename: 'Product',
    get search() {
      return search();
    },
  });

  const compositions = session.data.useNodes({
    typename: 'Composition',
    get search() {
      return search();
    },
    sort: compositionsSort[0].order,
  });

  return () => {
    const foundRoutes = routes.filter((r) => !r.children.length && !r.hidden);

    const results: {
      heading?: string;
      element: JSX.Children;
    }[] = [];

    if (foundRoutes.length)
      results.push({
        heading: 'Search results',
        element: (
          <div class="box">
            <List
              data={foundRoutes as DataList<DataSchema, 'Route'>}
              render={(item) => <Route route={item} />}
            />
          </div>
        ),
      });

    if (events.length)
      results.push({
        heading: 'Schedule',
        element: (
          <List
            adding={false}
            data={events}
            group={(node) =>
              node.ends < getNow()
                ? { name: 'Past', sortIndex: 1, sort: ['starts:desc'] }
                : { name: 'Upcoming', sortIndex: 0, sort: ['starts:asc'] }
            }
            groupHeadingStyle="heading1"
            render={(item) => (
              <View
                node={item}
                view={views.Event!.default!}
                mode={views.Event!.default!.modes![2]}
              />
            )}
          />
        ),
      });

    if (compositions.length)
      results.push({
        heading: 'Repertoire',
        element: (
          <div class="box">
            <List
              adding={false}
              data={compositions}
              render={(item) => (
                <View node={item} view={views.Composition!.detailed!} />
              )}
            />
          </div>
        ),
      });

    if (articles.length)
      results.push({
        heading: 'News',
        element: (
          <List
            adding={false}
            data={articles}
            render={(item) => (
              <View
                node={item}
                view={views.Article!.default!}
                mode={views.Article!.default!.modes![1]}
              />
            )}
          />
        ),
      });

    if (products.length)
      results.push({
        heading: 'Products',
        element: (
          <List
            adding={false}
            data={products}
            render={(item) => (
              <View
                node={item}
                view={views.Product!.default!}
                mode={views.Product!.default!.modes![1]}
              />
            )}
          />
        ),
      });

    return () =>
      results.length ? (
        <div
          class={bind(() => [props.class, styles.searchResults])}
          onmount={(el: HTMLElement) => masonry(el, 'div')}
        >
          {map(
            () => results,
            (r) =>
              r.heading ? (
                <div>
                  <WithHeading heading={() => r.heading} styleAs="subtitle">
                    {r.element}
                  </WithHeading>
                </div>
              ) : (
                <div>{r.element}</div>
              ),
          )}
        </div>
      ) : (
        <div class={bind(() => [props.class, 'box with-padding'])}>
          <p>No results found.</p>
        </div>
      );
  };
}

interface RouteProps {
  route: DataNode<DataSchema, 'Route'>;
}

function Route(props: RouteProps) {
  const theme = getTheme();
  const state = getState();

  return (
    <div class={styles.route}>
      <Crumbs route={props.route} />
      <WithHeading
        heading={
          <a
            class={theme.class.link}
            href={bind(() => session.router.getPath({ route: props.route }))}
          >
            {() => props.route.name}
          </a>
        }
        styleAs={'heading1'}
      >
        <p>
          {summarizeKeywordsFromBlocks(props.route.blocks, state.keywords) ??
            props.route.blocks.filter((b) => b.segment === 'main')[0]?.text ??
            null}
        </p>
      </WithHeading>
    </div>
  );
}

function Crumbs(props: RouteProps) {
  const state = store({
    get crumbs() {
      const result: DataNode<DataSchema, 'Route'>[] = [];
      let current = props.route?.parentRoute;
      while (current && !meta(current).isLoading) {
        result.push(current);
        current = current.parentRoute;
      }
      result.reverse();
      return result;
    },
  });

  return () =>
    !state.crumbs.length ? null : (
      <div class={styles.crumbs}>
        {map(
          () => state.crumbs,
          (crumb) => (
            <a href={session.router.getPath({ route: crumb })}>
              {() => crumb.name}
            </a>
          ),
        )}
      </div>
    );
}

function summarizeKeywordsFromBlocks(
  blocks: { text?: string | null }[] | null | undefined,
  keywords: string[],
) {
  return summarizeKeywordsFromText(
    blocks?.map((block) => block.text ?? '').join('\n'),
    keywords,
  );
}

function summarizeKeywordsFromText(
  text: string | undefined,
  keywords: string[],
) {
  if (!text || !keywords.length) return undefined;
  const extractions = (
    text.match(
      new RegExp(
        `\\b[^\\s].{0,80}(${keywords
          .map((k) => escapeRegExp(k))
          .join('|')}).{0,80}[^\\s]\\b`,
        'gmi',
      ),
    ) || []
  ).map((t) => t.trim());
  if (!extractions.length) return undefined;
  const result = `...${extractions.join('... ...')}...`;
  return result.endsWith('.') ? result : `${result}...`;
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
