import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { Images } from '../../../components/Images';
import { FileRef } from './FileRef';

export function InlineFileRef(props: FieldRenderProps<'FileRef'>) {
  return (
    <FileRef
      value={bind(
        () => props.field.value,
        (value) => {
          props.field.value = value;
        },
      )}
      default={bind(() => props.default)}
      ratio={bind(() => props.ratio)}
      readonly={bind(() => props.readonly)}
      optional={props.field.schema?.optional}
      onmount={bind(() => props.onmount)}
    />
  );
}

export function InlineFileRefs(props: FieldRenderProps<'FileRefList'>) {
  return jsxx(
    Images,
    store.clone(props, {
      get files() {
        return props.field.value;
      },
    }),
  );
}
