import type { Views } from '.';
import {
  AddFileRefPropertiesList,
  ViewFileRefPropertiesList,
} from '../layout/views/ViewFileRefPropertiesList';
import { ViewUserMailMerge } from '../layout/views/ViewUserMailMerge';

export const defaultViews: Views<DataSchema> = {
  FileRef: {
    'properties-list': {
      component: ViewFileRefPropertiesList,
      renderAdd: AddFileRefPropertiesList,
      name: () => 'Properties list',
      hidden: true,
    },
  },
  User: {
    'mail-merge': {
      component: ViewUserMailMerge,
      name: () => 'Mail merge',
      hidden: true,
    },
  },
};
