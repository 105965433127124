import type { Culture } from '@donkeyjs/proxy';
import { Flag } from './Flag';
import styles from './MultiCultureInput.module.css';
import { setI18n } from './getI18n';

interface MultiCultureContainerProps {
  vertical?: boolean;
  children?: JSX.Children;
}

interface MultiCultureCultureProps {
  culture?: Culture;
  children?: JSX.Children;
}

interface MultiCultureFlagProps {
  culture: Culture;
}

export const MultiCultureInput = {
  Container: (props: MultiCultureContainerProps) => (
    <div
      class={[
        styles.cultures,
        'cultures',
        { [styles.vertical]: props.vertical },
      ]}
    >
      {props.children}
    </div>
  ),

  Culture: (props: MultiCultureCultureProps) => {
    setI18n({ culture: props.culture });
    return <div class={[styles.culture, 'culture']}>{props.children}</div>;
  },

  Flag: (props: MultiCultureFlagProps) => (
    <span class={[styles.flag, 'flag']}>
      <Flag culture={props.culture} />
    </span>
  ),
};
