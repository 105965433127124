import { bindContext, getGlobal } from '@donkeyjs/jsx-runtime';
import {
  batch,
  isDataNode,
  store,
  type DataNode,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { getAssetUrl } from '../helpers';
import { isElementVisibleWithinAncestor } from '../helpers/isElementVisibleWithinAncestor';
import type { DraggableOptions } from './useDraggable';
import type {
  AcceptedDrag,
  DraggedFiles,
  DraggedItem,
  DropHandler,
  DropPosition,
  UseDropZoneOptions,
} from './useDropZone';

interface Dragging {
  item: DraggedItem;
  accept?: DraggableOptions<any>['accept'];
  element: HTMLElement | undefined;
}

interface ZoneInfo {
  zone: UseDropZoneOptions;
  disabled: () => boolean;
  element: HTMLElement;
}

interface ActiveZone extends ZoneInfo {
  accept: DropHandler;
}

export interface DragPosition {
  zone: ActiveZone;
  position: DropPosition;
  distance: number;
}

const key = Symbol('dragHandler');
const PRECISION = 30;
const OFFSET_AFTER_MOVE = 20;

export const useDragHandler = () =>
  getGlobal(key, () => {
    const startDragBound: typeof startDrag = bindContext(startDrag);

    const handler = store({
      dragging: undefined as Dragging | undefined,
      lastDragEvent: undefined as DragEvent | undefined,
      lastZonesUpdate: undefined as { x: number; y: number } | undefined,
      lastPosition: undefined as DragPosition | undefined,

      zones: [] as ZoneInfo[],
      get activeZones(): ActiveZone[] {
        if (!this.dragging || !this.dragCoordinates) return [];
        return this.zones
          .filter(
            (zone) =>
              !zone.disabled() &&
              isInZone(this.dragCoordinates!, zone) &&
              isElementVisibleWithinAncestor(zone.element),
          )
          .map((zone) => ({
            ...zone,
            accept: zone.zone.onDrag(this.dragging!.item) as DropHandler,
          }))
          .filter((zone) => zone.accept);
      },

      get dragCoordinates() {
        if (!this.dragging || !this.lastDragEvent) return undefined;
        return { x: this.lastDragEvent.clientX, y: this.lastDragEvent.clientY };
      },

      get position(): DragPosition | undefined {
        if (!this.dragCoordinates) {
          this.lastPosition = undefined;
          return undefined;
        }

        if (this.lastZonesUpdate) {
          const offset = calculateDistance(
            this.dragCoordinates,
            this.lastZonesUpdate,
          );
          if (offset > OFFSET_AFTER_MOVE) this.lastZonesUpdate = undefined;
          return this.lastPosition;
        }

        const coords = this.dragCoordinates;
        if (
          document
            .elementFromPoint(coords.x, coords.y)
            ?.closest('.node-testing')
        ) {
          return this.lastPosition;
        }

        const zones = this.activeZones;
        const candidates = zones
          .map(
            (zone) =>
              relativeToZone(this.dragging!, coords, zone, this.lastPosition)!,
          )
          .filter(Boolean);
        candidates.sort((a, b) => {
          if (a.zone.element.contains(b.zone.element)) return 1;
          if (b.zone.element.contains(a.zone.element)) return -1;
          return a.distance - b.distance;
        });
        const zone = candidates[0];

        if (!zone && zones.length) {
          return this.lastPosition;
        }

        this.lastPosition = zone;
        return this.lastPosition;
      },

      registerZone(zone: ZoneInfo) {
        batch(() => {
          this.zones = [...this.zones, zone as any];
          this.lastZonesUpdate = this.dragCoordinates;
        });

        return () => {
          batch(() => {
            this.zones = this.zones.filter((z) => z !== zone);
            this.lastZonesUpdate = this.dragCoordinates;
          });
        };
      },

      startDrag<T extends AcceptedDrag>(
        ev: DragEvent,
        options: DraggableOptions<T>,
        element: HTMLElement | undefined,
      ) {
        startDragBound(handler, ev, options, element);
      },
    });

    if (typeof window !== 'undefined') {
      const handleExternalDragEnter = (ev: DragEvent) => {
        if (handler.dragging) return;

        const items = [...(ev.dataTransfer!.items as any)];
        const isFiles = items.some((item) => item.kind === 'file');

        if (isFiles) {
          handler.startDrag<'native-files'>(
            ev,
            { item: { type: 'files', files: undefined } },
            undefined,
          );
        }
      };

      document.addEventListener('dragenter', handleExternalDragEnter);
    }

    return handler;
  });

const calculateDistance = (
  from: { x: number; y: number },
  to: { x: number; y: number },
) => Math.sqrt((from.x - to.x) ** 2 + (from.y - to.y) ** 2);

const relativeToZone = (
  dragging: Dragging,
  coords: { x: number; y: number },
  zone: ActiveZone,
  lastPosition?: DragPosition,
): DragPosition | undefined => {
  if (dragging.element && isInSelf(dragging.element, zone.element))
    return undefined;
  if (zone.element.closest('.node-testing')) return lastPosition;

  const accept = (position: DropPosition) => {
    return !dragging.accept ? true : dragging.accept(zone.zone.key, position);
  };

  const direction = getElementDirection(zone.element);
  const rect = zone.element.getBoundingClientRect();
  const values = {
    before:
      zone.accept.positions.includes('before') && accept('before')
        ? direction === 'horizontal'
          ? Math.abs(coords.x - rect.left) > PRECISION * 2
            ? Number.POSITIVE_INFINITY
            : calculateDistance(coords, {
                x: rect.left,
                y: rect.top + rect.height / 2,
              })
          : Math.abs(coords.y - rect.top) > PRECISION * 2
            ? Number.POSITIVE_INFINITY
            : calculateDistance(coords, {
                x: rect.left + rect.width / 2,
                y: rect.top,
              })
        : Number.POSITIVE_INFINITY,
    after:
      zone.accept.positions.includes('after') && accept('after')
        ? direction === 'horizontal'
          ? Math.abs(coords.x - rect.right) > PRECISION * 2
            ? Number.POSITIVE_INFINITY
            : calculateDistance(coords, {
                x: rect.right,
                y: rect.top + rect.height / 2,
              })
          : Math.abs(coords.y - rect.bottom) > PRECISION * 2
            ? Number.POSITIVE_INFINITY
            : calculateDistance(coords, {
                x: rect.left + rect.width / 2,
                y: rect.bottom,
              })
        : Number.POSITIVE_INFINITY,
    inside:
      zone.accept.positions.includes('inside') &&
      isInZone(coords, zone, 0) &&
      accept('inside')
        ? calculateDistance(coords, {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
          })
        : Number.POSITIVE_INFINITY,
  };

  const position: DropPosition =
    values.inside < values.before && values.inside < values.after
      ? 'inside'
      : values.before < values.after
        ? 'before'
        : 'after';

  const distance = values[position];
  if (
    distance === Number.POSITIVE_INFINITY //||
    // (position !== 'inside' && distance > PRECISION * 2)
  )
    return undefined;

  return {
    zone,
    position,
    distance,
  };
};

const isInZone = (
  coords: { x: number; y: number },
  zone: ZoneInfo,
  precision = PRECISION,
) => {
  const rect = zone.element.getBoundingClientRect();
  return (
    coords.x >= rect.left - precision &&
    coords.x <= rect.right + precision &&
    coords.y >= rect.top - precision &&
    coords.y <= rect.bottom + precision
  );
};

const isInSelf = (element: HTMLElement, parent: HTMLElement) => {
  let current: HTMLElement | null = element;
  while (current) {
    if (current === parent) return true;
    current = current.parentElement;
  }
  return false;
};

function getElementDirection(element: HTMLElement): 'horizontal' | 'vertical' {
  const style = getComputedStyle(element);
  if (style.getPropertyValue('float') !== 'none') return 'horizontal';
  if (style.getPropertyValue('display') === 'inline') return 'horizontal';

  const parent = element.parentElement;
  if (!parent) return 'vertical';

  const parentStyle = getComputedStyle(parent!);
  const display = parentStyle.getPropertyValue('display');

  if (display === 'flex' || display === 'inline-flex') {
    const flexFlow = parentStyle.getPropertyValue('flex-flow');
    if (flexFlow.includes('row')) return 'horizontal';
    return 'vertical';
  }

  if (display === 'grid' || display === 'inline-grid') {
    const gridAutoFlow = parentStyle.getPropertyValue('grid-auto-flow');
    if (gridAutoFlow?.includes('row')) return 'horizontal';
    return 'vertical';
  }

  return 'vertical';
}

const startDrag = <T extends AcceptedDrag>(
  handler: ReturnType<typeof useDragHandler>,
  originalEvent: DragEvent,
  options: DraggableOptions<T>,
  element: HTMLElement | undefined,
) => {
  if (handler.dragging) return;

  const { item, accept, listKey, onCancelDrag, onDrop } = options;
  if (!item) return;

  const eventTarget = originalEvent.currentTarget;
  let lastPosition: DragPosition | undefined;

  let disposeTransient = undefined as (() => void) | void | undefined;

  const onDragEnter = (ev: DragEvent) => {
    ev.preventDefault();
    ev.dataTransfer!.dropEffect = lastPosition ? 'move' : 'none';
  };

  const onDragOver = (ev: DragEvent) => {
    ev.preventDefault();
    if (!element) dragMove(ev);
    ev.dataTransfer!.dropEffect = lastPosition ? 'move' : 'none';
  };

  let timer: any = undefined;
  const dragMove = (ev: DragEvent) => {
    ev.stopPropagation();

    if (timer) clearTimeout(timer);
    if (handler.dragging?.item.type === 'files') {
      timer = setTimeout(() => {
        timer = undefined;
        dragEnd();
      }, 100);
    }

    if (ev.clientX === 0 && ev.clientY === 0) return;

    handler.lastDragEvent = ev;
    const position = handler.position;

    if (
      position?.zone.zone.key !== lastPosition?.zone.zone.key ||
      position?.position !== lastPosition?.position
    ) {
      lastPosition = position;
      disposeTransient?.();
      disposeTransient = position
        ? position.zone.accept.hover(position.position)
        : undefined;
    }
  };

  const drop = (ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    const dropHandler = lastPosition?.zone.accept.drop;

    if (dropHandler && handler.dragging) {
      const item = handler.dragging.item;
      if (item?.type === 'files') {
        item.files = ev.dataTransfer!.files;
      }
      const position = lastPosition!.position;

      dragEnd();

      dropHandler(position);
      onDrop?.();
    } else {
      dragEnd();
      onCancelDrag?.();
    }

    batch(() => {
      handler.dragging = undefined;
      handler.lastPosition = undefined;
      handler.lastDragEvent = undefined;
      handler.lastZonesUpdate = undefined;
    });
  };

  const dragEnd = () => {
    if (element) element.style.opacity = '';

    document.removeEventListener('dragenter', onDragEnter);
    document.removeEventListener('dragover', onDragOver);
    document.removeEventListener('drop', drop);
    eventTarget?.removeEventListener('drag', dragMove as any);
    eventTarget?.removeEventListener('dragend', dragEnd);

    batch(() => {
      handler.dragging = undefined;
      handler.lastPosition = undefined;
      handler.lastDragEvent = undefined;
      handler.lastZonesUpdate = undefined;
    });

    disposeTransient?.();
    disposeTransient = undefined;
  };

  originalEvent.stopPropagation();

  document.addEventListener('dragenter', onDragEnter);
  document.addEventListener('dragover', onDragOver);
  document.addEventListener('drop', drop);
  eventTarget?.addEventListener('drag', dragMove as any);
  eventTarget?.addEventListener('dragend', dragEnd);

  if (element) {
    const rect = element.getBoundingClientRect();
    originalEvent.dataTransfer!.setDragImage(
      element!,
      originalEvent.clientX - rect.left,
      originalEvent.clientY - rect.top,
    );
  }

  if (
    isDataNode<DataSchema, T extends NodeTypename<DataSchema> ? T : never>(item)
  ) {
    originalEvent.dataTransfer!.setData('donkey/typename', item.__typename);
    originalEvent.dataTransfer!.setData('donkey/id', item.id);
    handler.dragging = {
      item: {
        type: 'node',
        node: item as any,
        listKey,
      },
      accept,
      element,
    };
    if (item.__typename === 'File') {
      const dragIcon = document.createElement('img');
      dragIcon.width = 30;
      dragIcon.height = 30;
      dragIcon.src = getAssetUrl(
        item as unknown as DataNode<DataSchema, 'File'>,
        {
          width: 30,
          height: 30,
        },
      );
      originalEvent.dataTransfer!.setDragImage(dragIcon, 15, 15);
    }
  } else if (typeof item === 'object' && 'typename' in item) {
    originalEvent.dataTransfer!.setData(
      'donkey/typename',
      `Create${item.typename}`,
    );
    originalEvent.dataTransfer!.setData('donkey/data', JSON.stringify(item));
    handler.dragging = {
      item: {
        type: 'node-create',
        ...(item as any),
      },
      accept,
      element,
    };
  } else if (
    typeof item === 'object' &&
    'type' in item &&
    item.type === 'files'
  ) {
    handler.dragging = {
      item: item as DraggedFiles,
      accept,
      element,
    };
  } else if (typeof item === 'object' && 'type' in item && 'value' in item) {
    originalEvent.dataTransfer!.setData('donkey/typename', 'custom');
    originalEvent.dataTransfer!.setData('donkey/type', item.type);
    handler.dragging = {
      item: {
        type: 'custom',
        custom: item.type,
        value: item.value,
        listKey,
      },
      accept,
      element,
    };
  }
  originalEvent.dataTransfer!.effectAllowed = 'move';
  if (element) element.style.opacity = '0.3';

  lastPosition = handler.position;
  if (lastPosition) {
    disposeTransient = lastPosition.zone.accept.hover(lastPosition.position);
  }
};
