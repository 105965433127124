import type {
  AnonymousFragment,
  FieldSchema,
  NodeTypename,
  Schema,
  StatusFragment,
} from '../schema';

export function extendStatusFragment(
  mode: 'requested' | 'ready',
  fragment: StatusFragment,
  extension: AnonymousFragment,
  schema: Schema,
  typename: NodeTypename<Schema>,
  reverse?: string,
): boolean {
  let changed = false;

  if (reverse && !fragment[reverse]) {
    fragment[reverse] = { id: mode };
    changed = true;
  }

  if (fragment.id === 'deleted') return false;

  const nodeSchema = schema.nodes[typename];
  if (!nodeSchema) return false;

  for (const key in extension) {
    if (key === 'id') continue;

    if (key === 'draft' || key === 'deleted') {
      if (!fragment[key]) fragment[key] = mode;
      continue;
    }

    const fieldSchema =
      nodeSchema.fields[key] || nodeSchema.reverseFields[key]!;

    const value = extension[key];
    if (value) {
      if (!fieldSchema || fieldSchema.scalar || fieldSchema.enum) {
        if (!fragment[key]) {
          fragment[key] = mode;
          changed = true;
        }
        if ((fieldSchema as FieldSchema<'string'>)?.markup) {
          const markupKey = `${key}Markup`;
          if (!fragment[markupKey]) {
            fragment[markupKey] = mode;
            changed = true;
          }
        }
        continue;
      }
      if (fieldSchema.array && fieldSchema.reverseLookup === 'cache') {
        if (!fragment[key]) {
          fragment[key] = { id: 'ready' };
          changed = true;
        }
        continue;
      }
      if (!fragment[key]) {
        fragment[key] = { id: mode } as StatusFragment;
        changed = true;
      }
      if (typeof value === 'object') {
        const nestChanged = extendStatusFragment(
          mode,
          fragment[key] as StatusFragment,
          { id: true, ...(typeof value === 'object' ? value : {}) },
          schema,
          fieldSchema.type,
          fieldSchema.array ? fieldSchema.reverse : undefined,
        );
        changed = changed || nestChanged;
      }
    }
  }

  return changed;
}
