import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { PhCamera } from '@donkeyjs/phosphor-icons';
import { meta, store } from '@donkeyjs/proxy';
import { getMailContext } from '../mail';
import styles from './Image.module.css';
import { type UseImageProps, useImage } from './useImage';

export interface ImageProps extends UseImageProps, ImageDisplayProps {
  readonly onmount?:
    | ((element: HTMLElement) => () => void)
    | (((element: HTMLElement) => () => void) | undefined)[];
}

export interface ImageDisplayProps {
  readonly alt?: string | undefined;
  readonly element?: keyof HTMLElementTagNameMap | undefined;
  readonly class?: JSX.ClassNames;
  readonly testing?: boolean;
  readonly style?: Record<string, any>;
  readonly onClick?: () => void;
}

export function Image(props: ImageProps) {
  // Fix until useOutline is reactive
  return () => {
    const file = props.file;
    const onChange = props.onChange;
    const onClear = props.onClear;

    return jsxx(
      ImageComponent,
      store.clone(props, {
        file,
        onChange,
        onClear,
      }),
    );
  };
}

function ImageComponent(props: ImageProps) {
  const state = useImage(props);
  const mail = getMailContext();

  return () => {
    if (!state.file) {
      return props.onChange ? (
        <span
          class={bind(() => [
            'image',
            styles.image,
            styles.placeholder,
            props.class,
          ])}
          style={bind(() => state.style(props.style))}
          onclick={props.onClick || state.handleButtonClick}
          onmount={bind(() => state.attach)}
        >
          <PhCamera weight="duotone" />
        </span>
      ) : undefined;
    }

    if (meta(state.file)?.isLoading) {
      return (
        <span
          class={bind(() => [
            'image',
            styles.image,
            styles.loading,
            props.class,
          ])}
          style={bind(() => state.style(props.style))}
          onsize={state.handleSize}
        />
      );
    }

    if (!state.url) {
      return (
        <span
          class={bind(() => ['image', styles.image, props.class])}
          style={bind(() => ({
            ...state.style(props.style),
            opacity: '0.5',
          }))}
          onclick={props.onClick}
          onsize={state.handleSize}
        />
      );
    }

    return (
      <img
        class={bind(() => [
          'image',
          styles.image,
          props.class,
          {
            [styles.testing]: props.testing,
          },
        ])}
        src={bind(() => state.url)}
        alt={bind(() => props.alt || state.file?.name)}
        style={bind(() => state.style(props.style))}
        width={mail?.target === 'mail' ? props.style?.width : undefined}
        align={
          mail?.target === 'mail'
            ? props.style?.align || props.style?.float
            : undefined
        }
        onmount={bind(() => [props.onmount, state.attach])}
        onclick={props.onClick}
        onload={state.handleLoad}
        onsize={state.handleSize}
      />
    );
  };
}
