import type { Culture } from '../Culture';
import type { DataNode, Node } from '../cache/DataNode';
import type {
  FieldSchema,
  MutationDelete,
  MutationInsert,
  MutationUpdate,
  NodeTypename,
  Schema,
} from '../schema';
import { dontWatch } from '../store';
import { meta } from './meta';

export const getMutations = <
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
>(
  node: Node<{ __typename: Typename }> | DataNode<S, Typename>,
): MutationInsert<S> | MutationUpdate<S> | MutationDelete<S> | null => {
  return dontWatch(() => {
    const { store, fieldStatus, schema, mutated, appSchema, isCreating } =
      meta(node);
    if (!schema || !appSchema) return null;
    if (!fieldStatus && !isCreating) return null;
    if (fieldStatus?.id === 'deleted') {
      return { kind: 'delete', id: node.id, typename: node.__typename };
    }
    if (!isCreating && !Object.keys(mutated).length) {
      return null;
    }

    meta(node).mutated = {};
    const values: any = {};

    for (const mutatedKey in mutated) {
      let key = mutatedKey;
      let culture: Culture | undefined;

      let fieldSchema = (schema.fields[key] || schema.reverseFields[key]) as
        | FieldSchema
        | undefined;
      let prefix: string | undefined;

      if (!fieldSchema) {
        const split = key.split('__');
        fieldSchema = schema.fields[split[0]];
        if (!fieldSchema && split[0].endsWith('Markup')) {
          split[0] = split[0].slice(0, -6);
          prefix = 'Markup';
          fieldSchema = schema.fields[split[0]];
        }
        if (fieldSchema) {
          key = split[0];
          if (split[1]) {
            culture = split[1] as Culture;
          }
        }
      }

      if (!fieldSchema) continue;

      const value = (store as any).$.peek(mutatedKey);

      const fieldName = fieldSchema.i18n
        ? `${key}${prefix ?? ''}_${culture || appSchema.defaultCulture.replace('-', '_')}`
        : `${key}${prefix ?? ''}`;

      if (prefix === 'Markup' || fieldSchema.type === 'json') {
        values[fieldName] = JSON.stringify(value);
      } else if (fieldSchema && !fieldSchema.scalar && !fieldSchema.enum) {
        if (!fieldSchema.array)
          values[fieldName] = (value as DataNode<S>)?.id ?? null;
      } else {
        values[fieldName] = value ?? null;
      }
    }

    if (isCreating !== 'new') {
      return {
        kind: 'update',
        id: node.id,
        typename: node.__typename,
        values,
      };
    }

    meta(node).isCreating = 'stored';

    return {
      kind: 'insert',
      values: {
        ...values,
        __typename: node.__typename,
        id: node.id,
      },
    };
  });
};
