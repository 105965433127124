import {
  type DomElement,
  bind,
  componentContext,
  getRenderPlugin,
  mount,
  onHybridMount,
} from '@donkeyjs/jsx-runtime';
import { session } from '../session';
import { getTheme } from '../styles/applyTheme';

interface PortalProps {
  parent: DomElement | HTMLElement;
  bare?: boolean;
  children?: JSX.Children;
  class?: JSX.ClassNames;
}

export function Portal(props: PortalProps) {
  if (getRenderPlugin()) return null;

  onHybridMount(() => {
    const [dispose] = mount(
      session.dom,
      props?.bare
        ? function Portal({
            children,
          }: { children?: JSX.Children; class?: JSX.ClassNames }) {
            return children;
          }
        : PortalComponent,
      { class: props.class },
      Array.isArray(props.children) ? props.children : [props.children],
      props.parent,
      componentContext.current,
    );
    return dispose;
  });

  return null;
}

function PortalComponent(props: {
  children?: JSX.Children;
  class?: JSX.ClassNames;
}) {
  return (
    <div class={bind(() => [getTheme().themeClass, 'portal', props.class])}>
      {props.children}
    </div>
  );
}
