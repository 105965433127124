export interface PasswordStrength {
  score: number;
  isStrongEnough: boolean;

  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSymbol: boolean;
  hasNumber: boolean;
  isMediumLong: boolean;
  isLong: boolean;
  isUnique: boolean;
}

export const passwordStrength = (password: string): PasswordStrength => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
  const hasNumber = /\d/.test(password);
  const isMediumLong = password.length >= 6;
  const isLong = password.length >= 12;

  const characters = new Set(password.split(''));
  const uniqueCharacters = [...characters].join('');
  const isUnique = uniqueCharacters.length >= 6;

  let score = 0;

  if (hasUpperCase) score += 1;
  if (hasLowerCase) score += 1;
  if (hasSymbol) score += 1;
  if (hasNumber) score += 1;
  if (isMediumLong) score += 1;
  if (isLong) score += 1;
  if (isUnique) score += 1;

  return {
    score: score / 7,
    isStrongEnough: score >= 6,

    hasUpperCase,
    hasLowerCase,
    hasSymbol,
    hasNumber,
    isMediumLong,
    isLong,
    isUnique,
  };
};
