import { meta, type DataNode } from '@donkeyjs/proxy';
import type { OrderableItem, OrderableKey } from '../useCart';

export function removeFromOrder<Kind extends OrderableKey>(
  order: DataNode<DataSchema, 'Order'>,
  kind: Kind,
  item: OrderableItem<Kind>,
  quantity = 1,
) {
  const orderItem = order.items.find(
    (orderItem) => orderItem[kind]?.id === item.id,
  );

  if (orderItem) {
    const newQuantity = orderItem.quantity - quantity;
    if (newQuantity <= 0) {
      meta(orderItem).delete();
    } else {
      orderItem.quantity -= quantity;
    }
  }
}
