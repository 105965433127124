import { addMinutes, roundToNearestMinutes } from 'date-fns';
import type { Culture, SupportedCulture } from './Culture';
import type { List } from './cache/DataList';
import type { DataNode } from './cache/DataNode';
import type { MarkupString } from './markup/Markup';

export const scalars = {
  boolean: { emptyValue: () => false },
  date: {
    emptyValue: () =>
      roundToNearestMinutes(addMinutes(new Date(), 1), {
        nearestTo: 15,
        roundingMethod: 'ceil',
      }),
  },
  float: { emptyValue: () => 0 },
  int: { emptyValue: () => 0 },
  json: { emptyValue: () => ({}) },
  string: { emptyValue: () => '' },
};

export type Scalar = keyof typeof scalars;
export type ScalarType<T extends Scalar | 'markup'> = T extends 'string'
  ? string
  : T extends 'date'
    ? Date
    : T extends 'boolean'
      ? boolean
      : T extends 'number'
        ? number
        : T extends 'float'
          ? number
          : T extends 'int'
            ? number
            : T extends 'json'
              ? unknown
              : T extends 'markup'
                ? MarkupString | string
                : never;

// Schema

export interface Schema {
  readonly nodes: NodeSetSchema;
  readonly enums: EnumSetSchema;
}

export interface AppSchema extends Schema {
  readonly cultures: SupportedCulture[];
  readonly defaultCulture: SupportedCulture;
  readonly resolvers: { [name: string]: string };
  readonly customResolvers: CustomResolverRootSchema;
  readonly i18n: I18nSchemaSet<SchemaInput<any, any, any, any>>;
}

export interface DataFromSchema<S extends Schema> {
  nodes: S['nodes'];
  enums: S['enums'];
}

export type SchemaMeta<S extends Schema> = {
  readonly [Typename in NodeTypename<S>]?: {
    // fields?: {
    //   [FieldName in Extract<
    //     keyof FieldsFromNodeSchema<S, Typename>,
    //     string
    //   >]?: {
    //     timeZoneField?: Extract<
    //       keyof FieldsFromNodeSchema<S, Typename>,
    //       string
    //     >;
    //   };
    // };
    readonly searchVectors?: SearchVectors<
      Extract<
        keyof NestedFieldsWithArrays<S, Typename, S['nodes'][Typename]>,
        string
      >
    >;
    readonly join?: {
      [Key in keyof FieldsFromNodeSchema<
        S,
        Typename
      >]?: keyof FieldsFromNodeSchema<S, Typename>;
    };
  };
};

export type SchemaInput<
  Typenames extends string,
  EnumNames extends string,
  Enums extends EnumSetSchemaInput<EnumNames>,
  Nodes extends NodeSetSchemaInput<Typenames, EnumNames>,
> = {
  readonly enums?: Enums;
  readonly nodes: Nodes;
  readonly customResolvers?: CustomResolverRootSchemaInput<
    Typenames,
    EnumNames
  >;
  readonly resolvers?: ResolverTypenameSetSchemaInput<{
    [Typename in Typenames]: {
      // Todo: fix this
      readonly UniqueFields: string /*Extract<
        UniqueNestedFieldKeysFromInput<Simplified<Nodes>, Typename>,
        string
      >*/;
    };
  }>;
  readonly i18n?: {
    [C in SupportedCulture]?: () => Promise<I18nSchema<Nodes, Enums>>;
  }[];
};

export type SchemaFromInput<
  T extends SchemaInput<any, any, any, any>,
  Cultures extends SupportedCulture = SupportedCulture,
> = Simplified<{
  readonly defaultCulture: SupportedCulture;
  readonly cultures: Cultures[];
  readonly nodes: NodeSetSchemaFromInput<T>;
  readonly enums: EnumSetSchemaFromInput<T>;
  readonly resolvers: undefined extends T['resolvers']
    ? {}
    : ResolverReferencesFromSchemaInput<NonNullable<T['resolvers']>>;
  readonly customResolvers: CustomResolverRootSchemaFromInput<
    NonNullable<T['customResolvers']>
  >;
  readonly i18n: I18nSchemaSet<T>;
}>;

// NodeSetSchema

interface NodeSetSchema {
  readonly [typename: string]: NodeSchema;
}

type NodeSetSchemaInput<Typenames extends string, EnumNames extends string> = {
  readonly [key in Typenames]: NodeSchemaInput<Typenames, EnumNames>;
};

type NodeSetSchemaFromInput<T extends SchemaInput<any, any, any, any>> =
  Simplified<{
    readonly [Typename in Extract<keyof T['nodes'], string>]: Simplified<
      NodeSchemaFromInput<
        T['nodes'][Typename],
        Typename extends keyof NonNullable<T['resolvers']>
          ? NonNullable<NonNullable<T['resolvers']>[Typename]>
          : never,
        Extract<keyof T['enums'], string>,
        Typename
      >
    >;
  }>;

// NodeSchema

export interface NodeSchema {
  readonly kind: 'type';
  readonly fields: FieldSetSchema;
  readonly reverseFields: FieldSetSchema;
  readonly resolvers?: ResolverSetSchema;
  readonly databaseSchema?: string;
}

export interface NodeSchemaInput<
  Typenames extends string,
  EnumNames extends string,
> {
  readonly [field: string]: FieldSchemaInput<Typenames, EnumNames>;
}

export interface NodeSchemaFromInput<
  T extends NodeSchemaInput<any, EnumNames>,
  Resolvers extends ResolverSetSchemaInput<any>,
  EnumNames extends string,
  Typename extends string,
> {
  readonly kind: 'type';
  readonly fields: Simplified<FieldSetSchemaFromInput<T, EnumNames>>;
  readonly reverseFields: {
    [fieldName: string]: FieldSchema;
  };
  readonly resolvers: ResolverSetSchemaFromInput<Resolvers, Typename>;
  databaseSchema?: string;
}

// FieldSetSchema

export interface FieldSetSchema {
  readonly [fieldName: string]: FieldSchema;
}

type FieldSetSchemaFromInput<
  T extends NodeSchemaInput<any, any>,
  EnumNames extends string,
> = Simplified<{
  readonly [F in keyof T]: FieldSchemaFromInput<T[F], EnumNames>;
}>;

// FieldSchema

export type FieldSchema<S extends Scalar = Scalar> = Simplified<
  RelationFieldOptions<S> & {
    readonly type: string;
    readonly scalar: boolean;
    readonly enum: boolean;
    readonly optional: boolean;
    readonly array: boolean;
    readonly index?: boolean;
  }
>;

export type FieldSchemaInput<
  Typenames extends string,
  EnumNames extends string,
> =
  | SchemaType<Typenames, EnumNames>
  | readonly ['boolean' | `${'boolean'}?`, FieldOptions<'boolean'>]
  | readonly ['date' | `${'date'}?`, FieldOptions<'date'>]
  | readonly ['float' | `${'float'}?`, FieldOptions<'float'>]
  | readonly ['int' | `${'int'}?`, FieldOptions<'int'>]
  | readonly ['json' | `${'json'}?`, FieldOptions<'json'>]
  | readonly ['string' | `${'string'}?`, FieldOptions<'string'>]
  | readonly [EnumNames | `${EnumNames}?`, FieldOptions<Scalar>]
  | readonly [Typenames, NonNullableRelationFieldOptions]
  | readonly [`${Typenames}?`, RelationFieldOptions];

type FieldSchemaFromInput<
  T extends FieldSchemaInput<string, string>,
  EnumNames extends string,
> = Simplified<
  T extends `${infer U}?`
    ? U extends string
      ? {
          readonly type: U;
          readonly scalar: Capitalize<U> extends U ? false : true;
          readonly enum: EnumNames extends U ? true : false;
          readonly optional: true;
          readonly array: false;
          readonly index?: boolean;
        }
      : never
    : T extends `${infer U}[]`
      ? U extends string
        ? {
            readonly type: U;
            readonly scalar: Capitalize<U> extends U ? false : true;
            readonly enum: EnumNames extends U ? true : false;
            readonly optional: false;
            readonly array: true;
            readonly index?: boolean;
          }
        : never
      : T extends string
        ? {
            readonly type: T;
            readonly scalar: Capitalize<T> extends T ? false : true;
            readonly enum: EnumNames extends T ? true : false;
            readonly optional: false;
            readonly array: false;
            readonly index?: boolean;
          }
        : T extends readonly [infer U, infer V]
          ? U extends FieldSchemaInput<string, string>
            ? FieldSchemaFromInputWithOptions<U, V, EnumNames>
            : never
          : never
>;

type FieldSchemaFromInputWithOptions<
  T extends FieldSchemaInput<string, string>,
  V,
  EnumNames extends string,
> = Omit<FieldSchemaFromInput<T, EnumNames>, keyof V> & V;

// EnumSetSchema

interface EnumSetSchema {
  readonly [name: string]: EnumSchema;
}

type EnumSetSchemaInput<EnumNames extends string> = {
  readonly [key in EnumNames]: EnumSchemaInput;
};

type EnumSetSchemaFromInput<T extends SchemaInput<any, any, any, any>> =
  Simplified<{
    [Name in keyof T['enums']]: T['enums'][Name] extends EnumSchemaInput
      ? EnumSchemaFromInput<T['enums'][Name]>
      : never;
  }>;

// EnumSchema

export interface EnumSchema {
  readonly kind: 'enum';
  readonly values: readonly string[];
}

export type EnumSchemaInput = readonly string[];

type EnumSchemaFromInput<T extends EnumSchemaInput> = Simplified<{
  readonly kind: 'enum';
  readonly values: T;
}>;

// ResolverTypenameSetSchema

type ResolverTypenameSetSchemaInput<
  Input extends {
    readonly [Typename: string]: { readonly UniqueFields: string };
  },
> = {
  readonly [Typename in Extract<keyof Input, string>]?: ResolverSetSchemaInput<
    Input[Typename]['UniqueFields']
  >;
};

// ResolverReferences

type ResolverReferencesFromSchemaInput<
  T extends ResolverTypenameSetSchemaInput<any>,
> = Simplified<
  UnionToIntersection<
    {
      [Typename in keyof T]: {
        [Name in keyof T[Typename]]: Typename;
      };
    }[keyof T]
  >
>;

// ResolverSetSchema

export interface ResolverSetSchema {
  readonly [resolverName: string]: ResolverSchema;
}

type ResolverSetSchemaInput<UniqueFields extends string> = {
  readonly [name: string]: ResolverSchemaInput<UniqueFields>;
};

type ResolverSetSchemaFromInput<
  Input extends ResolverSetSchemaInput<any>,
  Typename extends string,
> = {
  readonly [Name in keyof Input]: ResolverSchemaFromInput<
    NonNullable<Input[Name]>,
    Typename
  >;
};

// ResolverSchema

export type ResolverSchema = ResolveOneSchema | ResolveManySchema;

type ResolverSchemaInput<UniqueFields extends string> =
  | ResolveOneSchemaInput<UniqueFields>
  | ResolveManySchemaInput<UniqueFields>;

type ResolverSchemaFromInput<
  Input extends ResolverSchemaInput<any>,
  Typename extends string,
> = Input & {
  readonly typename: Typename;
};

// ResolveOneSchema

export type ResolveOneSchema = {
  readonly return: 'one';
  readonly typename: string;
  readonly where?: { [arg: string]: string };
};

type ResolveOneSchemaInput<UniqueFields extends string> = {
  readonly return: 'one';
  readonly where?: {
    readonly [arg: string]: UniqueFields;
  };
};

// TODO: ResolveOneSchemaFromInput

// ResolveManySchema

export interface ResolveManySchema extends ResolveManySchemaInput<string> {
  readonly typename: string;
}

interface ResolveManySchemaInput<UniqueFields extends string> {
  readonly return: 'many';
  readonly where?: ResolveManySchemaWhere<UniqueFields>;
  readonly sort?: readonly {
    readonly name: string;
    readonly order: QuerySortOrderInput<UniqueFields, UniqueFields>;
  }[];
}

export type ResolveManySchemaWhere<Keys extends string> = {
  readonly [arg: string]: ResolveManySchemaWhereField<Keys>;
};

export type ResolveManySchemaWhereField<Keys extends string> = readonly [
  Keys,
  readonly ('eq' | 'notEq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in' | 'notIn')[],
];

// CustomResolverRootSchema

interface CustomResolverRootSchema {
  readonly queries: CustomResolverSetSchema;
  readonly mutations: CustomResolverSetSchema;
}

interface CustomResolverRootSchemaInput<
  Typenames extends string,
  EnumNames extends string,
> {
  readonly queries?: CustomResolverSetSchemaInput<Typenames, EnumNames>;
  readonly mutations?: CustomResolverSetSchemaInput<Typenames, EnumNames>;
}

type CustomResolverRootSchemaFromInput<
  Input extends CustomResolverRootSchemaInput<any, any>,
> = Simplified<{
  readonly queries: NonNullable<Input['queries']>;
  readonly mutations: NonNullable<Input['mutations']>;
}>;

// CustomResolverSetSchema

export interface CustomResolverSetSchema {
  readonly [name: string]: CustomResolverSchema;
}

export interface CustomResolverSetSchemaInput<
  Typenames extends string,
  EnumNames extends string,
> {
  readonly [name: string]: CustomResolverSchemaInput<Typenames, EnumNames>;
}

// type CustomResolverSetSchemaFromInput<
//   T extends CustomResolverSetSchemaInput<any, any>,
// > = {
//   [key in keyof T]: CustomResolverSchemaFromInput<T[key]>;
// };

// CustomResolverSchema

export interface CustomResolverSchema {
  readonly args?: CustomResolverArgs;
  readonly returns: SchemaType<any, any>;
}

interface CustomResolverSchemaInput<
  Typenames extends string,
  EnumNames extends string,
> {
  readonly args?: CustomResolverArgs;
  readonly returns: SchemaType<Typenames, EnumNames>;
}

// type CustomResolverSchemaFromInput<
//   T extends CustomResolverSchemaInput<any, any>,
// > = T;

// CustomResolverArgs

export interface CustomResolverArgs {
  readonly [name: string]: CustomResolverArg;
}

export type CustomResolverArg =
  | Scalar
  | `${Scalar}?`
  | CustomResolverArgs
  | [CustomResolverArgs, { readonly optional?: true }]
  | null;

export type CustomResolverArgsFromSchema<T extends CustomResolverArgs> = {
  readonly [key in keyof T]: T[key] extends `${infer U}?`
    ? U extends Scalar
      ? ScalarType<U> | undefined
      : never
    : T[key] extends Scalar
      ? ScalarType<T[key]>
      : T[key] extends CustomResolverArgs
        ? CustomResolverArgsFromSchema<T[key]>
        : never;
};

// I18n

export interface I18nSchema<
  Nodes extends NodeSetSchemaInput<any, any>,
  Enums extends EnumSetSchemaInput<any>,
> {
  nodes?: {
    [Type in keyof Nodes]?: {
      [Field in
        | '__typename'
        | keyof Nodes[Type]
        | keyof ReverseInputTypes<Nodes, Type>]?:
        | string
        | readonly [singular: string, plural: string];
    };
  };
  enums?: {
    [Enum in keyof Enums]?: {
      [Value in Extract<
        Enums[Enum]['values'] extends readonly (infer U)[] ? U : never,
        string
      >]?: string;
    };
  };
  groups?: {
    [Type in keyof Nodes]?: Record<string, string>;
  };
}

export interface I18nSchemaSet<T extends SchemaInput<any, any, any, any>> {
  loaders: {
    [C in SupportedCulture]?:
      | I18nSchema<T['nodes'], T['enums']>
      | (() => Promise<I18nSchema<T['nodes'], T['enums']>>);
  }[];
  values: {
    [C in Culture]?: any;
  };
}

export type SchemaExtensionI18n<
  Extension extends SchemaExtensionInput<
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any
  >,
> = I18nSchema<
  NonNullable<Extension['nodes']>,
  NonNullable<Extension['enums']>
>;

// Extending

export interface SchemaExtensionInput<
  S extends SchemaInput<string, string, any, any>,
  Typenames extends string,
  EnumNames extends string,
  Enums extends {
    readonly [key in EnumNames]: EnumSchemaInput;
  },
  Nodes extends {
    readonly [key in Typenames]: NodeSchemaInput<
      Extract<keyof S['nodes'], string> | Typenames,
      Extract<keyof S['enums'], string> | EnumNames
    >;
  },
  Extensions extends {
    readonly [key in keyof S['nodes']]?: NodeSchemaExtensionInput<
      S['nodes'][key],
      Extract<keyof S['nodes'], string> | Typenames,
      Extract<keyof S['enums'], string> | EnumNames
    >;
  },
  Resolvers,
  CustomResolvers,
> {
  readonly databaseSchema?: string;
  readonly enums?: Enums;
  readonly nodes?: Nodes;
  readonly extensions?: Extensions;
  readonly resolvers?: Resolvers;
  readonly customResolvers?: CustomResolvers;
  readonly i18n?: {
    [C in SupportedCulture]?:
      | I18nSchema<Nodes, Enums>
      | (() => Promise<I18nSchema<Nodes, Enums>>);
  };
}

interface NodeSchemaExtensionInput<
  N extends NodeSchemaInput<string, string>,
  Typenames extends string,
  EnumNames extends string,
> {
  readonly extend?: {
    readonly [field in keyof N]?: { readonly optional?: false };
  };
  readonly add?: {
    readonly [field: string]: FieldSchemaInput<Typenames, EnumNames>;
  };
}

export type SchemaInputWithExtensions<
  Source extends SchemaInput<any, any, any, any>,
  Input extends SchemaExtensionInput<any, any, any, any, any, any, any, any>,
> = Simplified<
  Omit<Source, 'enums' | 'nodes' | 'resolvers' | 'customResolvers'> & {
    readonly enums: Simplified<Source['enums'] & Input['enums']>;
    readonly nodes: NodeSetSchemaInputWithExtensions<Source['nodes'], Input>;
    readonly resolvers: ResolverTypenameSetSchemaInputWithExtensions<
      NonNullable<Source['resolvers']>,
      Input['resolvers']
    >;
    readonly customResolvers: CustomResolverRootSchemaInputWithExtensions<
      Source,
      Input['customResolvers']
    >;
  }
>;

type NodeSetSchemaInputWithExtensions<
  Source extends NodeSetSchemaInput<any, any>,
  Input extends SchemaExtensionInput<any, any, any, any, any, any, any, any>,
> = Simplified<
  Omit<Source, keyof Input['nodes']> &
    Input['nodes'] & {
      readonly [key in keyof Input['extensions']]: Simplified<
        NodeSchemaInputWithExtensions<
          key extends keyof Source ? Source[key] : never,
          Input['extensions'][key]
        >
      >;
    }
>;

type NodeSchemaInputWithExtensions<Source, Input> = Simplified<
  Input extends {
    readonly extend?: infer Extend;
    readonly add?: infer Add;
  }
    ? Omit<Source, keyof Extend> & {
        [field in keyof Extend]: field extends keyof Source
          ? Source[field] extends string
            ? [Source[field], Extend[field]]
            : Source[field] extends [infer Type, infer Options]
              ? [Type, Omit<Options, keyof Extend[field]> & Extend[field]]
              : never
          : never;
      } & Add
    : never
>;

type ResolverTypenameSetSchemaInputWithExtensions<
  Source extends ResolverTypenameSetSchemaInput<any>,
  Input extends ResolverTypenameSetSchemaInput<any>,
> = Simplified<Source & Input>;

type CustomResolverRootSchemaInputWithExtensions<
  Source extends SchemaInput<any, any, any, any>,
  CustomResolvers extends CustomResolverRootSchemaInput<any, any>,
> = Simplified<{
  readonly queries: Simplified<
    NonNullable<Source['customResolvers']>['queries'] &
      NonNullable<CustomResolvers>['queries']
  >;
  readonly mutations: Simplified<
    NonNullable<Source['customResolvers']>['mutations'] &
      NonNullable<CustomResolvers>['mutations']
  >;
}>;

// Resolved types

export interface NodeBase {
  readonly __typename: string;
  readonly id: string;
}

export type QueryWhereValue<T> = {
  readonly eq?: T;
  readonly notEq?: T;
  readonly lt?: T;
  readonly lte?: T;
  readonly gt?: T;
  readonly gte?: T;
  readonly in?: T[];
  readonly notIn?: T[];
};

type QueryWhereOperator = keyof QueryWhereValue<any>;

export interface ResolverManyArgsFromSchema<
  S extends Schema,
  T extends NodeTypename<S>,
> {
  readonly offset?: number;
  readonly limit?: number;
  readonly drafts?: boolean;
  readonly where?: ResolverArgsWhereFromSchema<S, T>;
  readonly search?: ResolverManySearch<S, S['nodes'][T]>;
  readonly sort?: QuerySortOrder<S, S['nodes'][T]>;
  readonly skipExecution?: boolean;
  readonly source?: string;
}

export type ResolverArgsWhereFromSchema<
  S extends Schema,
  T extends NodeTypename<S>,
> = {
  readonly id?: QueryWhereValue<string>;
} & {
  [Key in keyof NestedFieldsWithArrays<S, T, S['nodes'][T]>]?: QueryWhereValue<
    NonNullable<NestedFieldsWithArrays<S, T, S['nodes'][T]>[Key]> extends {
      readonly __typename: string;
      readonly id: string;
    }
      ? string
      : NestedFieldsWithArrays<S, T, S['nodes'][T]>[Key]
  >;
};

//#endregion

//#region Schema from input conversion

export interface ResolverArgsSearch {
  readonly text: string;
  readonly culture?: SupportedCulture;
  readonly mode?: { __literal: 'WEBSEARCH' | 'TOKENS' | 'PLAIN' | 'PHRASE' };
}

export interface ResolverManySearch<S extends Schema, N extends NodeSchema>
  extends ResolverArgsSearch {
  readonly in: SearchVectors<Extract<keyof NestedFields<S, N>, string>>;
}

// TODO

type FieldOptions<S extends Scalar = Scalar> = Simplified<
  ScalarFieldOptions[S] & {
    readonly i18n?: boolean;
    readonly unique?: boolean;
    readonly index?: boolean;
    readonly skipValidation?: boolean;
    readonly validate?: (value: unknown) => string | null | undefined;
    readonly defaultValue?: () => unknown;
    readonly hideFromForms?: boolean;
  }
>;

interface ScalarFieldOptions {
  readonly boolean: {};
  readonly date: {
    readonly rangeFromField?: string;
    readonly rangeToField?: string;
    readonly fullDayField?: string;
    readonly timeZoneField?: string;
  };
  readonly float: {};
  readonly int: {};
  readonly json: {
    jsonType?: string;
  };
  readonly string: {
    readonly markup?: boolean;
    readonly size?: number;
    readonly options?: readonly string[];
  };
}

type RelationFieldOptions<S extends Scalar = Scalar> = Simplified<
  FieldOptions<S> & {
    readonly embeddedNode?: boolean;
    readonly embeddedList?: boolean;
    readonly reverse?: string;
    readonly reverseLookup?: 'default' | 'cache';
    readonly cascadeDelete?: boolean;
    readonly sort?: readonly {
      readonly name: string;
      readonly order: readonly (
        | `${string}:asc`
        | `${string}:desc`
        | `${string}:auto`
      )[];
    }[];
  }
>;

type NonNullableRelationFieldOptions<S extends Scalar = Scalar> = Simplified<
  FieldOptions<S> & {
    readonly embeddedNode?: boolean;
    readonly embeddedList?: boolean;
    readonly reverse?: string;
    readonly reverseLookup?: 'default' | 'cache';
    readonly cascadeDelete?: boolean;
    readonly sort?: readonly {
      readonly name: string;
      readonly order: readonly (
        | `${string}:asc`
        | `${string}:desc`
        | `${string}:auto`
      )[];
    }[];
  }
>;

//#endregion

//#region Schema input types

export type SchemaType<Typenames extends string, EnumNames extends string> =
  | Scalar
  | Typenames
  | EnumNames
  | `${Scalar | Typenames | EnumNames}?`;

// type QuerySortOrderFromInput<
//   Nodes extends {
//     [key: string]: NodeSchemaInput<any, any>;
//   },
//   Typename extends string,
// > = (
//   | `${Extract<NestedFieldKeysFromInput<Nodes, Typename>, string>}:asc`
//   | `${Extract<NestedFieldKeysFromInput<Nodes, Typename>, string>}:desc`
//   | `${never extends Nodes[Typename]
//       ? string
//       : AutoSortableKeysFromInput<Nodes, Nodes[Typename]>}:auto`
// )[];

// type AutoSortableKeysFromInput<
//   Nodes extends {
//     [key: string]: NodeSchemaInput<any, any>;
//   },
//   N extends NodeSchemaInput<any, any>,
// > = Extract<NestedNumberFieldKeysFromInput<Nodes, N>, string>;

export type QuerySortOrder<S extends Schema, N extends NodeSchema> = readonly (
  | `${Extract<keyof NestedFields<S, N>, string>}:asc`
  | `${Extract<keyof NestedFields<S, N>, string>}:desc`
  | `${never extends N ? string : AutoSortableKeys<S, N>}:auto`
)[];

export type QuerySortOrderInput<
  FieldKeys extends string,
  SortableKeys extends string,
> = readonly (
  | `${FieldKeys}:asc`
  | `${FieldKeys}:desc`
  | `${SortableKeys}:auto`
)[];

type AutoSortableKeys<S extends Schema, N extends NodeSchema> = {
  readonly [key in Extract<keyof NestedFields<S, N>, string>]: Extract<
    NestedFields<S, N>[key],
    number
  >;
}[Extract<keyof NestedFields<S, N>, string>];

export type SearchVectors<Keys extends string> = {
  readonly [key in Keys]?: 'A' | 'B' | 'C' | 'D';
};

//#endregion

//#region Data from schema conversion

export type NodesFromSchema<S extends Schema> = {
  readonly [Typename in NodeTypename<S>]: NodeFromSchema<S, Typename>;
};

export type NodeFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = Simplified<
  {
    readonly __typename: Typename;
    readonly id: string;
    draft?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
  } & NodeFieldsFromSchema<S, Typename>
>;

export type NodeFieldsFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = RequiredFields<S, Typename> &
  OptionalFields<S, Typename> &
  ReverseRelations<S, Typename>;

type OptionalFields<S extends Schema, Typename extends NodeTypename<S>> = {
  [F in OptionalFieldKeys<S['nodes'][Typename]['fields']>]?: FieldFromSchema<
    S,
    S['nodes'][Typename]['fields'][F]
  >;
};

type RequiredFields<S extends Schema, Typename extends NodeTypename<S>> = {
  [F in RequiredFieldKeys<S['nodes'][Typename]['fields']>]: FieldFromSchema<
    S,
    S['nodes'][Typename]['fields'][F]
  >;
};

export type AggregationFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  [F in keyof S['nodes'][Typename]['fields']]?: S['nodes'][Typename]['fields'][F] extends FieldSchema
    ? AggregationFieldFromSchema<S, S['nodes'][Typename]['fields'][F]>
    : never;
} & {
  [F in keyof ReverseTypes<S, Typename>]?: ReverseTypes<
    S,
    Typename
  >[F] extends FieldSchema
    ? AggregationFieldFromSchema<S, ReverseTypes<S, Typename>[F]>
    : never;
};

type AggregationFieldFromSchema<
  S extends Schema,
  T extends FieldSchema,
> = T extends { readonly type: infer Type }
  ? Type extends Scalar
    ? {
        readonly average?: ScalarType<Type>;
        readonly max?: ScalarType<Type>;
        readonly min?: ScalarType<Type>;
        readonly sum?: ScalarType<Type>;
        readonly values: {
          readonly count?: number;
          readonly value?: ScalarType<Type>;
        }[];
      }
    : Type extends NodeTypename<S>
      ? {
          readonly values: {
            readonly count?: number;
            readonly value?: DataNode<S, Type>;
          }[];
        }
      : Type extends EnumName<S>
        ? {
            readonly max?: EnumFromSchema<S, Type>;
            readonly min?: EnumFromSchema<S, Type>;
            readonly values: {
              readonly count?: number;
              readonly value?: EnumFromSchema<S, Type>;
            }[];
          }
        : never
  : never;

export type ExternalNodeFromSchema<S extends Schema, N extends NodeSchema> = {
  [F in OptionalFieldKeys<N['fields']>]?: N['fields'][F] extends FieldSchema
    ? FieldFromSchema<S, N['fields'][F]>
    : never;
} & {
  [F in RequiredFieldKeys<N['fields']>]: N['fields'][F] extends FieldSchema
    ? FieldFromSchema<S, N['fields'][F]>
    : never;
};

export type PartialNodesFromSchema<S extends Schema> = {
  readonly [Typename in NodeTypename<S>]: PartialNodeFromSchema<S, Typename>;
};

export type InputNodeFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly __typename: Typename;
  readonly id?: string;
  draft?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
} & {
  [Key in keyof NodeFieldsFromSchema<S, Typename>]?: NonNullable<
    NodeFieldsFromSchema<S, Typename>[Key]
  > extends NodeFromSchema<S, infer T>
    ? InsertableNodeFromSchema<S, T>
    : NodeFieldsFromSchema<S, Typename>[Key] extends NodeFromSchema<
          S,
          infer T
        >[]
      ? InsertableNodeFromSchema<S, T>[]
      : NodeFromSchema<S, Typename>[Key];
};

export type PartialNodeFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly __typename: Typename;
  readonly id?: string;
  draft?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
} & {
  [Key in keyof NodeFieldsFromSchema<S, Typename>]?: NonNullable<
    NodeFieldsFromSchema<S, Typename>[Key]
  > extends NodeFromSchema<S, infer T>
    ? PartialNodeFromSchema<S, T>
    : NodeFieldsFromSchema<S, Typename>[Key] extends NodeFromSchema<
          S,
          infer T
        >[]
      ? PartialNodeFromSchema<S, T>[]
      : NodeFieldsFromSchema<S, Typename>[Key];
};

interface InsertableList<T extends { __typename: string }>
  extends Array<InsertableNode<T>> {}

export type Insertable<T> = NonNullable<T> extends { __typename: string }
  ? InsertableNode<NonNullable<T>>
  : NonNullable<T> extends List<infer U>
    ? InsertableList<U>
    : NonNullable<T> extends (infer U extends { __typename: string })[]
      ? InsertableList<U>
      : T;

export type InsertableNode<
  T extends { __typename: string } = { __typename: string },
> =
  | T
  | {
      [Key in Exclude<keyof T, '$'>]?: Insertable<T[Key]>;
    };

export type InsertableNodeFieldsFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly __typename?: Typename;
  readonly id?: string;
  draft?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
} & {
  [Key in keyof NodeFieldsFromSchema<S, Typename>]?:
    | (NonNullable<
        NodeFieldsFromSchema<S, Typename>[Key]
      > extends NodeFromSchema<S, infer T>
        ? InsertableNodeFromSchema<S, T>
        : NodeFieldsFromSchema<S, Typename>[Key] extends NodeFromSchema<
              S,
              infer T
            >[]
          ? InsertableNodeFromSchema<S, T>[]
          : NodeFieldsFromSchema<S, Typename>[Key])
    | null;
};

export type InsertableNodeFromSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> =
  | InsertableNodeFieldsFromSchema<S, Typename>
  | DataNode<S, Typename>
  | NodeFromSchema<S, Typename>
  | FlatNodeRef<S, Typename>;

export type FieldsFromNodeSchema<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = Simplified<S['nodes'][Typename]['fields'] & ReverseTypes<S, Typename>>;

export type EnumFromSchema<
  S extends Schema,
  Name extends EnumName<S>,
> = S['enums'][Name] extends { values: readonly (infer U)[] } ? U : never;

type FieldFromSchema<S extends Schema, T extends FieldSchema> = T extends {
  readonly type: infer Type;
  readonly optional?: infer Optional;
  readonly array?: infer Array;
  readonly markup?: infer Markup;
}
  ? Type extends Scalar
    ? Optional extends true
      ? Markup extends true
        ? ScalarType<'markup'> | null | undefined
        : ScalarType<Type> | null | undefined
      : Markup extends true
        ? ScalarType<'markup'>
        : ScalarType<Type>
    : Type extends NodeTypename<S>
      ? Array extends true
        ? NodeFromSchema<S, Type>[]
        : Optional extends true
          ? NodeFromSchema<S, Type> | null | undefined
          : NodeFromSchema<S, Type>
      : Type extends EnumName<S>
        ? Array extends true
          ? EnumFromSchema<S, Type>[]
          : Optional extends true
            ? EnumFromSchema<S, Type> | null | undefined
            : EnumFromSchema<S, Type>
        : never
  : never;

type PartialFieldFromSchema<
  S extends Schema,
  T extends FieldSchema,
> = T extends {
  readonly type: infer Type;
  readonly optional?: infer Optional;
  readonly array?: infer Array;
}
  ? Type extends Scalar
    ? Optional extends true
      ? ScalarType<Type> | null | undefined
      : ScalarType<Type>
    : Type extends NodeTypename<S>
      ? Array extends true
        ? PartialNodeFromSchema<S, Type>[]
        : Optional extends true
          ? PartialNodeFromSchema<S, Type> | null | undefined
          : PartialNodeFromSchema<S, Type>
      : Type extends EnumName<S>
        ? Array extends true
          ? EnumFromSchema<S, Type>[]
          : Optional extends true
            ? EnumFromSchema<S, Type> | null | undefined
            : EnumFromSchema<S, Type>
        : never
  : never;

export type NodeRef<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> = `${Typename}:${string}`;

export interface FlatNodeRef<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly __ref: NodeRef<S, Typename>;
}

export type FieldsFragment<Fields extends {}> = Omit<
  {
    [F in Exclude<keyof Fields, '__typename' | '$'>]?: NonNullable<
      Fields[F]
    > extends {
      readonly __typename: string;
    }
      ? FieldsFragment<NonNullable<Fields[F]>>
      : NonNullable<Fields[F]> extends List<infer U>
        ? FieldsFragment<U>
        : NonNullable<Fields[F]> extends (infer S extends {
              readonly __typename: string;
            })[]
          ? NonNullable<FieldsFragment<S>>
          : true;
  },
  '__typename'
>;

export type Fragment<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = Simplified<
  {
    id?: true;
    createdAt?: true;
    updatedAt?: true;
    draft?: true;
  } & FragmentFields<S, S['nodes'][Typename]['fields']> &
    FragmentFields<S, ReverseTypes<S, Typename>>
>;

export type NodeFieldsFromFragment<
  S extends Schema,
  T extends NodeTypename<S>,
  F extends Fragment<S, T>,
> = Simplified<
  {
    [K in RequiredFragmentKeys<S, T, F> &
      keyof NodeFromSchema<S, T>]: F[K] extends true
      ? NodeFromSchema<S, T>[K]
      : NodeFromSchema<S, T>[K] extends NodeFromSchema<
            S,
            infer Nested extends NodeTypename<S>
          >
        ? F[K] extends Fragment<S, Nested>
          ? NodeFieldsFromFragment<S, Nested, F[K]>
          : never
        : NodeFromSchema<S, T>[K] extends NodeFromSchema<
              S,
              infer Nested extends NodeTypename<S>
            >[]
          ? F[K] extends Fragment<S, Nested>
            ? NodeFieldsFromFragment<S, Nested, F[K]>[]
            : never
          : never;
  } & {
    [K in OptionalFragmentKeys<S, T, F> &
      keyof NodeFromSchema<S, T>]?: F[K] extends true
      ? NodeFromSchema<S, T>[K]
      : NonNullable<NodeFromSchema<S, T>[K]> extends NodeFromSchema<
            S,
            infer Nested extends NodeTypename<S>
          >
        ? F[K] extends Fragment<S, Nested>
          ? NodeFieldsFromFragment<S, Nested, F[K]>
          : never
        : NonNullable<NodeFromSchema<S, T>[K]> extends NodeFromSchema<
              S,
              infer Nested extends NodeTypename<S>
            >[]
          ? F[K] extends Fragment<S, Nested>
            ? NodeFieldsFromFragment<S, Nested, F[K]>[]
            : never
          : never;
  }
>;

type OptionalFragmentKeys<S extends Schema, T extends NodeTypename<S>, F> = {
  [K in keyof F]: K extends
    | 'draft'
    | 'createdAt'
    | 'updatedAt'
    | OptionalFieldKeys<S['nodes'][T]['fields']>
    ? K
    : never;
}[keyof F];

type RequiredFragmentKeys<S extends Schema, T extends NodeTypename<S>, F> = {
  [K in keyof F]: K extends
    | '__typename'
    | 'id'
    | RequiredFieldKeys<S['nodes'][T]['fields']>
    | keyof ReverseTypes<S, T>
    ? K
    : never;
}[keyof F];

export interface ManyFragment<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  aggregate?: AnonymousFragment;
  count?: boolean;
  nodes?: Fragment<S, Typename>;
}

export interface AnonymousFragment {
  [field: string]: boolean | AnonymousFragment | undefined;
}

type FragmentFields<S extends Schema, Fields> = Fields extends Record<
  string,
  FieldSchema
>
  ? {
      [F in keyof Fields]?: FragmentField<S, Fields[F]>;
    }
  : {};

type FragmentField<S extends Schema, R extends FieldSchema> = R extends {
  readonly type: infer Type;
}
  ? Type extends Scalar
    ? true
    : Type extends NodeTypename<S>
      ? Fragment<S, Type>
      : Type extends EnumName<S>
        ? true
        : never
  : never;

export type FieldStatus =
  | 'requested'
  | 'loading'
  | 'ready'
  | 'error'
  | 'deleted';

export interface StatusFragment {
  id?: FieldStatus;
  [key: string]: FieldStatus | StatusFragment | undefined;
}

// type ResolverFromSchema<
//   S extends Schema,
//   N extends NodeSchema,
//   R extends ResolverSchema<S, N>,
// > = {};

export type ResolverFromSchema<
  S extends Schema,
  A extends AppSchema,
  Resolver extends keyof A['resolvers'],
> = Resolver extends keyof S['nodes'][A['resolvers'][Resolver]]['resolvers']
  ? S['nodes'][A['resolvers'][Resolver]]['resolvers'][Resolver]
  : never;

export type ResolverArgsFromSchema<
  S extends Schema,
  A extends AppSchema,
  Resolver extends keyof A['resolvers'],
  NeedsCulture extends boolean = false,
> = ResolverFromSchema<S, A, Resolver> extends ResolveOneSchema
  ? ResolverArgsWhereOneFromSchema<
      S,
      S['nodes'][A['resolvers'][Resolver]],
      ResolverFromSchema<S, A, Resolver>
    >
  : ResolverFromSchema<S, A, Resolver> extends ResolveManySchema
    ? ResolverArgsManyFromSchema<
        S,
        A['resolvers'][Resolver] extends NodeTypename<S>
          ? A['resolvers'][Resolver]
          : never,
        ResolverFromSchema<S, A, Resolver>,
        NeedsCulture
      >
    : never;

type ResolverArgsWhereOneFromSchema<
  S extends Schema,
  N extends NodeSchema,
  R extends ResolveOneSchema,
> = Simplified<
  {
    readonly [Key in keyof R['where']]?: R['where'][Key] extends keyof UniqueNestedFields<
      S,
      N
    >
      ? UniqueNestedFields<S, N>[R['where'][Key]]
      : never;
  } & {
    readonly source?: string;
  }
>;

type ResolverArgsManyFromSchema<
  S extends Schema,
  T extends NodeTypename<S>,
  R extends ResolveManySchema,
  NeedsCulture extends boolean = false,
> = Simplified<
  ('where' extends keyof R
    ? {
        readonly where?: ResolverArgsWhereManyFromSchema<
          S,
          T,
          NonNullable<R['where']>
        >;
      }
    : {}) &
    (NeedsCulture extends true
      ? { readonly search?: ResolverArgsSearch }
      : { readonly search?: string | Omit<ResolverArgsSearch, 'culture'> }) &
    ('sort' extends keyof R
      ? {
          readonly sort?: R['sort'] extends readonly (infer U)[]
            ? Simplified<U> extends { readonly name: infer Name }
              ? Name
              : never
            : never;
        }
      : {}) & {
      readonly limit?: number;
      readonly offset?: number;
      readonly source?: string;
      readonly skipExecution?: boolean;
      readonly drafts?: boolean;
    }
>;

type ResolverArgsWhereManyFromSchema<
  S extends Schema,
  T extends NodeTypename<S>,
  R extends ResolveManySchemaWhere<string>,
> = Simplified<{
  readonly [Key in keyof R]?: ResolverArgWhereManyFromSchema<S, T, R[Key]>;
}>;

type ResolverArgWhereManyFromSchema<
  S extends Schema,
  T extends NodeTypename<S>,
  R extends ResolveManySchemaWhereField<string>,
> = R extends readonly [infer Field, readonly (infer Ops)[]]
  ? Ops extends QueryWhereOperator
    ? Field extends keyof NestedFieldsWithArrays<S, T, S['nodes'][T]>
      ? {
          readonly [Op in Ops]: NonNullable<
            NestedFieldsWithArrays<S, T, S['nodes'][T]>[Field]
          > extends {
            readonly __typename: NodeTypename<S>;
          }
            ? string | null
            : NestedFieldsWithArrays<S, T, S['nodes'][T]>[Field] | null;
        }
      : never
    : never
  : never;

type CustomResolverReturnType<
  S extends Schema,
  T extends FieldSchemaInput<NodeTypename<S>, Extract<EnumName<S>, string>>,
> = T extends `${infer U}?`
  ? U extends Scalar
    ? ScalarType<Scalar> | undefined
    : U extends NodeTypename<S>
      ? PartialNodeFromSchema<S, U> | undefined
      : never
  : T extends Scalar
    ? ScalarType<T>
    : T extends NodeTypename<S>
      ? PartialNodeFromSchema<S, T>
      : never;

export interface CustomResolverSelection {
  readonly [key: string]: CustomResolverSelectionField;
}

export interface ResolverAggregateSelection {
  readonly count?: boolean;
  readonly aggregate?: {
    readonly [key: string]: {
      readonly avg?: boolean;
      readonly max?: boolean;
      readonly min?: boolean;
      readonly sum?: boolean;
      readonly count?: boolean;
      readonly unique?: any;
    };
  };
}

interface CustomResolverSelectionField {
  readonly alias?: string;
  readonly aliasFor?: string;
  readonly args?: { [key: string]: any };
  readonly selection?: CustomResolverSelection;
}

export type CustomResolver<
  S extends Schema,
  R extends CustomResolverSchema,
> = (input: {
  args: CustomResolverArgsFromSchema<NonNullable<R['args']>>;
  selection: CustomResolverSelection | undefined;
}) =>
  | CustomResolverReturnType<S, R['returns']>
  | Promise<CustomResolverReturnType<S, R['returns']>>;

export type CustomResolvers<
  S extends Schema,
  T extends CustomResolverSetSchema,
> = {
  readonly [name in keyof T]: CustomResolver<S, T[name]>;
};

//#endregion

//#region Field helpers

export type RequiredFieldKeys<T> = {
  readonly [Key in keyof T]: T[Key] extends { readonly optional: false }
    ? Key
    : never;
}[keyof T];

export type OptionalFieldKeys<T> = {
  readonly [Key in keyof T]: T[Key] extends { readonly optional: true }
    ? Key
    : never;
}[keyof T];

type UniqueFieldKeys<T> = {
  readonly [Key in keyof T]: NonNullable<T[Key]> extends {
    unique: true;
  }
    ? Extract<Key, string>
    : never;
}[keyof T];

// type UniqueFieldKeysFromInput<T extends NodeSchemaInput<any, any>> = {
//   readonly [Key in keyof T]: NonNullable<T[Key]> extends [any, { unique: true }]
//     ? Extract<Key, string>
//     : never;
// }[keyof T];

export type OwnFieldKeys<T> = {
  readonly [Key in keyof T]: T[Key] extends { readonly scalar: true }
    ? Key
    : T[Key] extends { readonly enum: true }
      ? Key
      : never;
}[keyof T];

export type RelationFieldKeys<T> = {
  readonly [Key in keyof T]: T[Key] extends { readonly scalar: false }
    ? Key
    : never;
}[keyof T];

export type NodeTypename<S extends Schema> = Extract<keyof S['nodes'], string>;
export type EnumName<S extends Schema> = Extract<keyof S['enums'], string>;

export type NodeFieldname<
  S extends Schema,
  T extends NodeTypename<S>,
> = Extract<
  keyof (S['nodes'][T]['fields'] & S['nodes'][T]['reverseFields']),
  string
>;

export type NestedFields<
  S extends Schema,
  N extends NodeSchema,
  Levels extends keyof NextLevel = 3,
  FieldPrefix extends string = '',
> = {
  readonly [Key in keyof N['fields'] as `${FieldPrefix}${Extract<
    Key,
    string
  >}`]?: FieldFromSchema<S, N['fields'][Key]>;
} & (0 extends Levels
  ? {}
  : UnionToIntersection<
      {
        readonly [Key in RelationFieldKeys<
          N['fields']
        >]: S['nodes'][N['fields'][Key]['type']] extends NodeSchema
          ? NestedFields<
              S,
              S['nodes'][N['fields'][Key]['type']],
              NextLevel[Levels],
              `${FieldPrefix}${Extract<Key, string>}.`
            >
          : {};
      }[RelationFieldKeys<N['fields']>]
    >);

export type NestedFieldsWithArrays<
  S extends Schema,
  T extends NodeTypename<S>,
  N extends NodeSchema,
  Levels extends keyof NextLevel = 2,
  FieldPrefix extends string = '',
> = Simplified<
  {
    readonly [Key in keyof N['fields'] as `${FieldPrefix}${Extract<
      Key,
      string
    >}`]?: FieldFromSchema<S, N['fields'][Key]>;
  } & (0 extends Levels
    ? {}
    : UnionToIntersection<
        Values<{
          readonly [Key in RelationFieldKeys<
            N['fields']
          >]: N['fields'][Key]['type'] extends NodeTypename<S>
            ? NestedFieldsWithArrays<
                S,
                N['fields'][Key]['type'],
                S['nodes'][N['fields'][Key]['type']],
                NextLevel[Levels],
                `${FieldPrefix}${Extract<Key, string>}.`
              >
            : {};
        }>
      > &
        UnionToIntersection<
          Values<{
            readonly [Key in keyof ReverseTypes<S, T>]: ReverseTypes<
              S,
              T
            >[Key] extends {
              readonly type: NodeTypename<S>;
            }
              ? NestedFieldsWithArrays<
                  S,
                  ReverseTypes<S, T>[Key]['type'],
                  S['nodes'][ReverseTypes<S, T>[Key]['type']],
                  NextLevel[Levels],
                  `${FieldPrefix}${Extract<Key, string>}.`
                >
              : {};
          }>
        >)
>;

type UniqueNestedFields<
  S extends Schema,
  N extends NodeSchema,
  Levels extends keyof NextLevel = 3,
  FieldPrefix extends string = '',
> = { [Key in `${FieldPrefix}id`]?: string } & {
  readonly [Key in UniqueFieldKeys<N['fields']> as `${FieldPrefix}${Extract<
    Key,
    string
  >}`]?: FieldFromSchema<S, N['fields'][Key]>;
} & (0 extends Levels
    ? {}
    : UnionToIntersection<
        {
          readonly [Key in RelationFieldKeys<
            N['fields']
          >]: S['nodes'][N['fields'][Key]['type']] extends NodeSchema
            ? UniqueNestedFields<
                S,
                S['nodes'][N['fields'][Key]['type']],
                NextLevel[Levels],
                `${FieldPrefix}${Extract<Key, string>}.`
              >
            : {};
        }[RelationFieldKeys<N['fields']>]
      >);

export type ReverseInputTypes<
  Nodes extends NodeSetSchemaInput<any, any>,
  Typename extends keyof Nodes,
> = Simplified<
  UnionToIntersection<
    Values<{
      [ReverseType in Extract<keyof Nodes, string>]: Simplified<
        Values<{
          readonly [ReverseField in Extract<
            Exclude<keyof Nodes[ReverseType], keyof Nodes[Typename]>,
            string
          >]: Nodes[ReverseType][ReverseField] extends readonly [
            `${Extract<Typename, string>}?` | Typename,
            {
              readonly reverse?: infer Reverse;
              readonly unique?: infer Unique;
            },
          ]
            ? {
                readonly [key in Extract<Reverse, string>]: {
                  readonly type: ReverseType;
                  readonly array: Unique extends true ? false : true;
                  readonly scalar: false;
                  readonly enum: false;
                  readonly optional: true;
                };
              }
            : never;
        }>
      >;
    }>
  >
>;

export type ReverseTypes<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = UnionToIntersection<
  Values<{
    [ReverseType in NodeTypename<S>]: Simplified<
      Values<{
        readonly [ReverseField in Extract<
          keyof S['nodes'][ReverseType]['fields'],
          string
        >]: S['nodes'][ReverseType]['fields'][ReverseField] extends {
          readonly type: Typename;
          readonly reverse?: infer Reverse;
          readonly unique?: infer Unique;
        }
          ? Reverse extends keyof S['nodes'][Typename]['fields']
            ? never
            : {
                readonly [key in Extract<Reverse, string>]: {
                  readonly type: ReverseType;
                  readonly array: Unique extends true ? false : true;
                  readonly scalar: false;
                  readonly enum: false;
                  readonly optional: true;
                };
              }
          : never;
      }>
    >;
  }>
>;

export type ReverseRelations<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly [Field in keyof ReverseTypes<S, Typename>]: ReverseTypes<
    S,
    Typename
  >[Field] extends FieldSchema
    ? FieldFromSchema<S, ReverseTypes<S, Typename>[Field]>
    : never;
};

export type PartialReverseRelations<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly [Field in keyof ReverseTypes<S, Typename>]?: ReverseTypes<
    S,
    Typename
  >[Field] extends FieldSchema
    ? PartialFieldFromSchema<S, ReverseTypes<S, Typename>[Field]>
    : never;
};

//#endregion

//#region Other helpers

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartial<U>[]
    : T[P] extends object
      ? DeepPartial<T[P]>
      : T[P];
};

export type Simplified<T> = T extends object
  ? {} & {
      [P in keyof T]: T[P];
    }
  : T;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

type Values<T> = T[keyof T];

type NextLevel = {
  9: 8;
  8: 7;
  7: 6;
  6: 5;
  5: 4;
  4: 3;
  3: 2;
  2: 1;
  1: 0;
  0: never;
};

//#endregion

//#region Mutations

export interface MutationTransaction<S extends Schema> {
  readonly kind: 'transaction';
  readonly mutations: (
    | Omit<MutationInsert<S>, 'source'>
    | MutationUpdate<S>
    | MutationDelete<S>
  )[];
}

export interface MutationInsert<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly kind: 'insert';
  readonly values: {
    readonly __typename: Typename;
    readonly id: string;
  } & {
    readonly [F in OptionalFieldKeys<
      S['nodes'][Typename]['fields']
    >]?: MutationField<S, S['nodes'][Typename]['fields'][F]>;
  } & {
    readonly [F in RequiredFieldKeys<
      S['nodes'][Typename]['fields']
    >]?: MutationField<S, S['nodes'][Typename]['fields'][F]>;
  };
}

export interface MutationUpdate<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly kind: 'update';
  readonly typename: Typename;
  readonly id: string;
  readonly values: {
    readonly [F in keyof S['nodes'][Typename]['fields']]?: MutationField<
      S,
      S['nodes'][Typename]['fields'][F]
    >;
  };
}

export interface MutationDelete<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly kind: 'delete';
  readonly typename: Typename;
  readonly id: string;
}

export type Mutation<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> =
  | MutationTransaction<S>
  | MutationInsert<S, Typename>
  | MutationUpdate<S, Typename>
  | MutationDelete<S, Typename>;

export type Mutations<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> = Mutation<S, Typename>[];

export type MutationField<S extends Schema, T extends FieldSchema> = T extends {
  readonly type: infer Type;
  readonly optional?: infer Optional;
  readonly array?: infer Array;
}
  ? Type extends Scalar
    ? Optional extends true
      ? ScalarType<Type> | null | undefined
      : ScalarType<Type>
    : Type extends NodeTypename<S>
      ? Array extends true
        ? never
        : Optional extends true
          ? string | null | undefined
          : string
      : Type extends EnumName<S>
        ? Array extends true
          ? never
          : Optional extends true
            ? EnumFromSchema<S, Type> | null | undefined
            : EnumFromSchema<S, Type>
        : never
  : never;

//#endregion

//#region Implementations

export const createSchemaInput = <
  T extends SchemaInput<Typenames, EnumNames, Enums, Nodes>,
  Typenames extends Extract<keyof T['nodes'], string>,
  EnumNames extends Extract<keyof T['enums'], string>,
  Enums extends {
    readonly [key in EnumNames]: EnumSchemaInput;
  },
  Nodes extends {
    readonly [key in Typenames]: NodeSchemaInput<Typenames, EnumNames>;
  },
>(
  input: T,
): T => input;

interface SchemaExtender<Source extends SchemaInput<any, any, any, any>> {
  result: Source;
  with<
    Input extends SchemaExtensionInput<
      Source,
      Typenames,
      EnumNames,
      Enums,
      Nodes,
      Extensions,
      Resolvers,
      CustomResolvers
    >,
    Typenames extends Extract<keyof Input['nodes'], string>,
    EnumNames extends Extract<keyof Input['enums'], string>,
    Enums extends {
      readonly [key in EnumNames]: EnumSchemaInput;
    },
    Nodes extends {
      readonly [key in Typenames]: NodeSchemaInput<
        Extract<keyof Source['nodes'], string> | Typenames,
        Extract<keyof Source['enums'], string> | EnumNames
      >;
    },
    Extensions extends {
      readonly [key in keyof Source['nodes']]?: NodeSchemaExtensionInput<
        Source['nodes'][key],
        Extract<keyof Source['nodes'], string> | Typenames,
        Extract<keyof Source['enums'], string> | EnumNames
      >;
    },
    Resolvers extends ResolverTypenameSetSchemaInput<{
      readonly [key in Extract<keyof Source['enums'], string> & Typenames]: {
        UniqueFields: string;
      };
    }>,
    CustomResolvers extends CustomResolverRootSchemaInput<Typenames, EnumNames>,
  >(extension: Input): SchemaExtender<SchemaInputWithExtensions<Source, Input>>;
}

export const extendSchema = <Source extends SchemaInput<any, any, any, any>>(
  input: Source,
): SchemaExtender<Source> => {
  const result: any = {
    result: input,
    with(extension: any) {
      this.result = extendSchemaInput(this.result, extension);
      return this;
    },
  };
  return result;
};

export const extendSchemaInput = <
  Source extends SchemaInput<any, any, any, any>,
  Input extends SchemaExtensionInput<
    Source,
    Typenames,
    EnumNames,
    Enums,
    Nodes,
    Extensions,
    Resolvers,
    CustomResolvers
  >,
  Typenames extends Extract<keyof Input['nodes'], string>,
  EnumNames extends Extract<keyof Input['enums'], string>,
  Enums extends {
    readonly [key in EnumNames]: EnumSchemaInput;
  },
  Nodes extends {
    readonly [key in Typenames]: NodeSchemaInput<
      Extract<keyof Source['nodes'], string> | Typenames,
      Extract<keyof Source['enums'], string> | EnumNames
    >;
  },
  Extensions extends {
    readonly [key in keyof Source['nodes']]?: NodeSchemaExtensionInput<
      Source['nodes'][key],
      Extract<keyof Source['nodes'], string> | Typenames,
      Extract<keyof Source['enums'], string> | EnumNames
    >;
  },
  Resolvers extends ResolverTypenameSetSchemaInput<{
    readonly [key in Typenames]: { UniqueFields: string };
  }>,
  CustomResolvers extends CustomResolverRootSchemaInput<Typenames, EnumNames>,
>(
  schema: Source,
  extension: Input,
): SchemaInputWithExtensions<Source, Input> => {
  const result: any = {
    enums: extension.enums
      ? { ...schema.enums, ...extension.enums }
      : schema.enums,
    nodes: {
      ...schema.nodes,
      ...(extension.databaseSchema && extension.nodes
        ? Object.fromEntries(
            Object.entries(extension.nodes).map(([key, value]) => [
              key,
              {
                ...(value as any),
                __databaseSchema: extension.databaseSchema,
              },
            ]),
          )
        : extension.nodes),
    },
    resolvers: { ...schema.resolvers },
    customResolvers: {
      queries: {
        ...schema.customResolvers?.queries,
        ...extension.customResolvers?.queries,
      },
      mutations: {
        ...schema.customResolvers?.mutations,
        ...extension.customResolvers?.mutations,
      },
    },
    i18n: schema.i18n,
  };

  for (const typename in extension.extensions) {
    const ext = extension.extensions[typename]!;

    const extended = {
      ...result.nodes[typename],
      ...ext.add,
    };

    for (const field in ext?.extend)
      extended.fields[field] = {
        ...extended.fields[field],
        ...ext.extend[field],
      };

    result.nodes[typename] = extended;
  }

  if (extension.resolvers)
    for (const typename in extension.resolvers)
      result.resolvers[typename] = {
        ...result.resolvers[typename],
        ...extension.resolvers[typename as keyof typeof extension.resolvers],
      };

  if (extension.i18n) {
    result.i18n ??= [];
    result.i18n.push(extension.i18n);
  }

  return result;
};

export const createSchemaExtension =
  <Source extends SchemaInput<any, any, any, any>>() =>
  <
    Input extends SchemaExtensionInput<
      Source,
      Typenames,
      EnumNames,
      Enums,
      Nodes,
      Extensions,
      Resolvers,
      CustomResolvers
    >,
    Typenames extends string,
    EnumNames extends string,
    Enums extends {
      readonly [key in EnumNames]: EnumSchemaInput;
    },
    Nodes extends {
      readonly [key in Typenames]: NodeSchemaInput<
        Extract<keyof Source['nodes'], string> | Typenames,
        Extract<keyof Source['enums'], string> | EnumNames
      >;
    },
    Extensions extends {
      readonly [key in keyof Source['nodes']]?: NodeSchemaExtensionInput<
        Source['nodes'][key],
        Extract<keyof Source['nodes'], string> | Typenames,
        Extract<keyof Source['enums'], string> | EnumNames
      >;
    },
    Resolvers extends ResolverTypenameSetSchemaInput<{
      readonly [key in Typenames]: { UniqueFields: string };
    }>,
    CustomResolvers extends CustomResolverRootSchemaInput<Typenames, EnumNames>,
  >(
    extension: Input,
  ): Input =>
    extension;

export const createSchema = <
  T extends SchemaInput<Typenames, EnumNames, Enums, Nodes>,
  Typenames extends Extract<keyof T['nodes'], string>,
  EnumNames extends Extract<keyof T['enums'], string>,
  Enums extends {
    readonly [key in EnumNames]: EnumSchemaInput;
  },
  Nodes extends {
    readonly [key in Typenames]: NodeSchemaInput<Typenames, EnumNames>;
  },
  Cultures extends SupportedCulture = SupportedCulture,
>(
  input: T,
  {
    cultures = [],
    defaultCulture = cultures[0] || 'en-US',
  }: {
    cultures?: Cultures[];
    defaultCulture?: SupportedCulture;
  } = {},
): SchemaFromInput<T, Cultures> => {
  // Process enums
  const enums = input.enums ? enumsFromInput(input.enums) : {};

  // Process nodes
  const nodes = nodeSchemasFromInput(
    input.nodes,
    Object.keys(input.enums || {}),
    {},
  );

  const result: SchemaFromInput<T, Cultures> = {
    defaultCulture: defaultCulture as Culture,
    cultures,
    resolvers: {} as any,
    customResolvers: {
      mutations: input.customResolvers?.mutations || {},
      queries: input.customResolvers?.queries || {},
    },
    enums,
    nodes,
    i18n: {
      loaders: input.i18n || [],
      values: {},
    },
    // i18n: Object.entries(input.i18n || {}).reduce(
    //   (acc, [key, value]) => ({
    //     ...acc,
    //     [key]: {
    //       loader: value,
    //       loading: false,
    //     },
    //   }),
    //   {},
    // ),
  };

  // Process resolvers
  for (const typename in input.resolvers) {
    (result.nodes as any)[typename].resolvers =
      input.resolvers[typename as keyof (typeof input)['resolvers']];

    for (const [name, resolver] of Object.entries(
      input.resolvers[typename as keyof (typeof input)['resolvers']]!,
    )) {
      // Make references
      (result.resolvers as any)[name] = typename as unknown as keyof T['nodes'];

      // Index fields
      for (const where of Object.values((resolver as any).where || {})) {
        const fieldName = typeof where === 'string' ? where : (where as any)[0];
        let node =
          result.nodes[typename as unknown as keyof (typeof result)['nodes']];
        let key: string;
        let field: FieldSchema;
        const segments = fieldName.split('.');
        while (segments.length) {
          key = segments.shift();
          field = node.fields[key] || node.reverseFields[key];
          if (segments.length)
            node =
              result.nodes[field.type as Extract<keyof T['nodes'], string>];
        }
        if (field!) (field as any).index = true;
      }
    }
  }

  return result;
};

let registeredSchema: Schema | undefined;
const cachedFields: Record<string, FieldSetSchema> = {};

export const registerSchema = (schema: Schema) => {
  if (registeredSchema)
    throw new Error('Schema should be registered only once.');
  registeredSchema = schema;
};

export const getSchema = () => {
  if (!registeredSchema) throw new Error('No schema registered.');
  return registeredSchema;
};

export const forceRegisterSchema = (schema: Schema | undefined) =>
  (registeredSchema = schema);

const empty: FieldSetSchema = {};

export const getNodeFields = (typename: string): FieldSetSchema | undefined => {
  if (!registeredSchema) return undefined;
  if (!cachedFields[typename])
    cachedFields[typename] = registeredSchema.nodes[typename]?.fields || empty;
  return cachedFields[typename] === empty ? undefined : cachedFields[typename];
};

export const isNode = (value: unknown): value is NodeBase =>
  !!value &&
  typeof value === 'object' &&
  typeof (value as any).__typename === 'string' &&
  typeof (value as any).id === 'string';

const enumsFromInput = <
  T extends SchemaInput<Typenames, EnumNames, any, any>['enums'],
  Typenames extends string,
  EnumNames extends string,
>(
  input: T,
) => {
  const result: { [name: string]: EnumSchema } = {};
  for (const name in input) {
    result[name] = {
      kind: 'enum',
      values: (input as any)[name],
    };
  }
  return result as any;
};

interface ReverseFields {
  readonly [typename: string]: {
    readonly fieldName: string;
    readonly schema: FieldSchema;
    readonly typename: string;
    readonly lookup: 'default' | 'cache';
    readonly sort?: readonly {
      readonly name: string;
      readonly order: (`${string}:asc` | `${string}:desc` | `${string}:auto`)[];
    }[];
  }[];
}

const nodeSchemasFromInput = <
  T extends SchemaInput<Typenames, EnumNames, any, any>['nodes'],
  Typenames extends string,
  EnumNames extends string,
>(
  input: T,
  enumNames: string[],
  existing: any,
) => {
  const result: any = { ...existing };
  const reverseFields: ReverseFields = {};

  for (const typename in input) {
    result[typename] = nodeSchemaFromInput<any, Typenames, EnumNames>(
      typename,
      input[typename],
      reverseFields,
      enumNames,
    );
  }

  applyReverseFields(reverseFields, result);

  return result;
};

export const nodeSchemaFromInput = <
  T extends NodeSchemaInput<Typename, EnumNames>,
  EnumNames extends string,
  Typename extends string,
>(
  typename: string,
  input: T,
  reverseFields: ReverseFields = {},
  enumNames: string[] = [],
): NodeSchemaFromInput<T, any, EnumNames, Typename> => {
  const result: NodeSchemaFromInput<any, any, EnumNames, Typename> = {
    fields: {},
    kind: 'type',
    reverseFields: {},
    resolvers: {},
  };

  const fields = {
    ...input,
    draft: 'boolean?',
    createdAt: 'date?',
    updatedAt: 'date?',
  };

  for (const fieldName in fields) {
    if (fieldName === '__databaseSchema') {
      result.databaseSchema = fields.__databaseSchema as string;
      continue;
    }
    const field = fields[fieldName] as FieldSchemaInput<any, any>;
    const [typeInput, options] =
      typeof field === 'string' ? [field, {}] : field;

    const type = typeInput.replace('?', '').replace('[]', '');
    const convertedField = {
      array: typeInput.endsWith('[]'),
      optional: typeInput.endsWith('?'),
      scalar: type.charAt(0).toLowerCase() === type.charAt(0),
      enum: enumNames.includes(type),
      type,
      ...options,
    };
    if (!convertedField.scalar && !convertedField.enum)
      convertedField.index = true;
    (result.fields as any)[fieldName] = convertedField as any;

    if (options.reverse)
      (
        reverseFields[convertedField.type] ??
        ((reverseFields as any)[convertedField.type] = [])
      ).push({
        fieldName,
        schema: convertedField,
        typename,
        lookup: convertedField.reverseLookup,
        sort: convertedField.sort,
      });
  }

  return result;
};

const applyReverseFields = (reverseFields: ReverseFields, result: any) => {
  for (const typename in reverseFields) {
    for (const field of reverseFields[typename]) {
      if (!result[typename])
        throw new Error(`Node type ${typename} not found.`);
      result[typename].reverseFields[field.schema.reverse!] = {
        array: !field.schema.unique,
        embeddedList: field.schema.embeddedList,
        embeddedNode: field.schema.embeddedNode,
        optional: !field.schema.unique,
        reverse: field.fieldName,
        reverseLookup: field.lookup,
        sort: field.sort,
        scalar: false,
        enum: false,
        type: field.typename,
        cascadeDelete: field.schema.cascadeDelete,
      };
    }
  }
};

//#endregion
