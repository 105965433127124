import { PhSpinnerGap } from '@donkeyjs/phosphor-icons';
import { bind } from '@donkeyjs/proxy';
import styles from './Spinner.module.css';
import { SpinnerBackground } from './SpinnerBackground';

interface SpinnerProps {
  delayed?: boolean;
  floating?: boolean;
  small?: boolean;
}

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerBackground
      enabled={bind(() => !!props.floating)}
      delayed={bind(() => props.delayed)}
    >
      <div
        $element={bind(() => (props.small ? 'span' : 'div'))}
        class={bind(() => [styles.spinner, { [styles.small]: props.small }])}
      >
        <PhSpinnerGap weight={bind(() => (props.small ? 'regular' : 'bold'))} />
      </div>
    </SpinnerBackground>
  );
}
