import { bind } from '@donkeyjs/jsx-runtime';
import { tooltip } from '../../donkey';
import styles from './Label.module.css';

interface LabelProps {
  readonly class?: JSX.ClassNames;
  readonly label?: JSX.Children;
  readonly element?: keyof JSX.IntrinsicElements;
  readonly required?: boolean;
  readonly helper?: JSX.Children;
  readonly helperClass?: JSX.ClassNames;
  readonly children?: JSX.Children;
  readonly onmount?: JSX.OnMount<HTMLElement>;
}

export function Label(props: LabelProps) {
  return (
    // biome-ignore lint/a11y/noLabelWithoutControl: This is a reusable component
    <label
      $element={bind(() => props.element)}
      class={bind(() => [
        'label',
        props.class,
        {
          [styles.required]: props.required,
        },
      ])}
      onmount={props.onmount}
    >
      {() =>
        !!props.label && (
          <span
            class={['label', styles.label]}
            onmount={tooltip({ text: props.label, overflowOnly: true })}
          >
            {props.label}
          </span>
        )
      }
      {props.children}
      {() =>
        props.helper && (
          <LabelHelper class={props.helperClass}>{props.helper}</LabelHelper>
        )
      }
    </label>
  );
}

interface LabelHelperProps {
  readonly class?: JSX.ClassNames;
  readonly children?: JSX.Children;
}

export function LabelHelper(props: LabelHelperProps) {
  return (
    <div class={bind(() => ['helper', styles.helper, props.class])}>
      {props.children}
    </div>
  );
}
