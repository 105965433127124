import type { DataNode } from '@donkeyjs/proxy';
import { session } from '../session';
import { selectOSFiles } from './selectOSFiles';

export async function uploadFilesFromUrl(
  urls: string[],
  tag?: DataNode<DataSchema, 'Tag'>,
  hideFromFilesPanel?: boolean,
  onProgress?: (
    url: string,
    result: { data: DataNode<DataSchema, 'File'>; errors?: Error[] },
  ) => void,
) {
  const result: {
    [url: string]: DataNode<DataSchema, 'File'> | Error;
  } = {};

  const uniqueUrls = Array.from(new Set(urls));
  for (const url of uniqueUrls) {
    try {
      const info = await storeFileInformation(
        '',
        '',
        '',
        tag,
        hideFromFilesPanel,
        url,
      );
      result[url] = info.errors ? info.errors[0] : info.data;
      onProgress?.(url, info);
    } catch (err) {
      result[url] = err as Error;
    }
  }

  return result;
}

export async function uploadFiles(options?: {
  files?: FileList | File[];
  tag?: DataNode<DataSchema, 'Tag'>;
  hideFromFilesPanel?: boolean;
  onCreated?: (node: DataNode<DataSchema, 'File'>) => void;
}): Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]> {
  return new Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]>(
    (resolve, reject) => {
      const upload = (files: FileList | File[]) => {
        startUpload(
          files,
          options?.tag,
          options?.hideFromFilesPanel,
          options?.onCreated,
        )
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      };

      if (options?.files) upload(options?.files);
      else {
        selectOSFiles({ multiple: true }).then((files) => upload(files));
      }
    },
  );
}

async function startUpload(
  files: FileList | File[],
  tag?: DataNode<DataSchema, 'Tag'>,
  hideFromFilesPanel?: boolean,
  onCreated?: (node: DataNode<DataSchema, 'File'>) => void,
  onProgress?: (file: File, progress: number) => void,
): Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]> {
  if (!files.length) return [];

  const filesArray = Array.from(files);
  return Promise.allSettled(
    filesArray.map(async (file) => {
      const parts = file.name.split(/\./g);
      const fileExtension = parts.length > 1 ? parts.pop()! : '';
      const name = parts.join('.');

      const { data, errors } = await storeFileInformation(
        fileExtension,
        name,
        file.type,
        tag,
        hideFromFilesPanel,
      );
      if (errors) throw errors;
      onCreated?.(data);

      return new Promise<DataNode<DataSchema, 'File'>>((resolve) => {
        uploadToGC(
          data.uploadStatus!,
          file,
          (err) => {
            console.error(err);
            data.uploadStatus = 'error';
            data.uploadError = true;
            resolve(data);
          },
          async (progress) => {
            onProgress?.(file, progress);
            if (progress === 100) {
              const { data: processed } =
                await session.data.mutation.processUpload(
                  { filename: `${data.id}.${fileExtension}` },
                  {
                    uploadStatus: true,
                    width: true,
                    height: true,
                    pages: true,
                    credits: true,
                  },
                );
              resolve(processed || data);
            } else {
              session.data.processNodeData({
                __typename: 'File',
                id: data.id,
                uploadStatus: progress.toString(),
              });
            }
          },
        );
      });
    }),
  );
}

function uploadToGC(
  url: string,
  file: File,
  error: (message?: string) => void,
  progress: (value: number) => void,
) {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url, true);

  const encodedFileName = encodeURIComponent(file.name);
  xhr.setRequestHeader('X-File-Name', encodedFileName);
  xhr.setRequestHeader('X-File-Size', file.size.toString());
  xhr.setRequestHeader('Content-Type', file.type);

  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentage = Math.round((e.loaded * 100) / e.total);
      progress(percentage >= 100 ? 99 : percentage);
    }
  };

  xhr.onload = function () {
    if (this.status === 200) {
      progress(100);
    } else {
      error(this.responseText || 'Upload failed');
    }
  };

  xhr.onerror = () => {
    error('Network error occurred');
  };

  xhr.timeout = 30000; // 30 seconds timeout
  xhr.ontimeout = () => {
    error('Upload timed out');
  };

  xhr.send(file);
}

async function storeFileInformation(
  fileExtension: string,
  name: string,
  fileType: string,
  tag: DataNode<DataSchema, 'Tag'> | undefined,
  hideFromFilesPanel: boolean | undefined,
  importFromUrl?: string,
) {
  return await session.data.mutation.upload(
    {
      fileExtension,
      name,
      fileType,
      tagId: tag?.id,
      hideFromFilesPanel,
      importFromUrl,
    },
    {
      fileExtension: true,
      fileType: true,
      name: true,
      uploadStatus: true,
      createdAt: true,
      draft: true,
      tags: {
        id: true,
        createdAt: true,
        updatedAt: true,
        tag: {
          id: true,
        },
        file: {
          id: true,
        },
      },
    },
    { source: 'filesPanel' },
  );
}
