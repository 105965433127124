import type { Culture, DataError, ValidationError } from '..';
import { meta } from '../helpers/meta';
import type { FieldSchema, FieldStatus, Scalar } from '../schema';
import type { Node } from './DataNode';

export interface NodeFieldInfo<
  Type,
  Parent extends Node = Node,
  S extends Scalar = Scalar,
> {
  value: Type;
  cultures?: Record<Culture, NodeFieldInfo<Type, Parent, S>>;
  status: FieldStatus;
  touched: boolean;
  errors: ValidationError[];
  local: boolean;
  isTest: boolean;
  loading: boolean;
  stored: boolean;
  schema: FieldSchema<S> | undefined;
  parent: { node: Parent; typename: string; key: string };
}

export function getNodeField({
  node,
  key,
  cache,
  state,
  testing,
}: {
  node: Node;
  key: any;
  cache: Map<string, NodeFieldInfo<any>>;
  state: {
    touched: Record<string | symbol | number, boolean>;
    fieldErrors: Record<string, (DataError | ValidationError)[]>;
  };
  testing: { readonly merged: Record<string | number | symbol, any> };
}) {
  if (cache.has(key)) return cache.get(key)!;

  const fieldSchema =
    meta(node).schema?.fields[key] || meta(node).schema?.reverseFields[key];

  let _cultures: Record<Culture, NodeFieldInfo<any>> | null | undefined = null;

  const result: NodeFieldInfo<any> = {
    get touched() {
      return key in state.touched;
    },

    get loading() {
      const triggerLoading = this.value;
      return (
        (triggerLoading || !triggerLoading) &&
        (meta(node).isLoading || this.status === 'requested')
      );
    },

    get stored() {
      return (
        !meta(node).isLocal && !meta(node).isLoading && key in meta(node).store
      );
    },

    get local() {
      return meta(node).isLocal;
    },

    get isTest() {
      if (meta(node).isTest) return true;
      return key in testing.merged;
    },

    get parent() {
      return {
        node,
        typename: node.__typename,
        key,
      };
    },

    get errors() {
      return state.fieldErrors[key] || [];
    },

    schema: fieldSchema,

    get value() {
      return node[key as keyof Node];
    },

    set value(value) {
      (node as any)[key] = value;
    },

    get status() {
      if (!meta(node).fieldStatus) return 'ready';
      const status =
        meta(node).fieldStatus?.[
          !meta(node).schema?.fields[key]?.i18n ||
          meta(node).culture === meta(node).appSchema?.defaultCulture
            ? key
            : `${key}__${meta(node).culture.replace('-', '_')}`
        ];
      return typeof status === 'string' ? status : status?.id || 'requested';
    },

    get cultures() {
      if (_cultures !== null) return _cultures;

      if (
        (!fieldSchema?.i18n && fieldSchema?.type !== 'Block') ||
        !meta(node).appSchema ||
        !meta(node).appSchema!.cultures.length
      ) {
        _cultures = undefined;
        return undefined;
      }

      _cultures = {} as Record<Culture, NodeFieldInfo<any>>;
      for (const culture of meta(node).appSchema?.cultures || ['global']) {
        Object.defineProperty(_cultures, culture, {
          get: () =>
            meta(meta(node).getCulture(culture as Culture)).getField(key),
          enumerable: true,
          configurable: true,
        });
      }
      return _cultures;
    },
  };

  cache.set(key, result);
  return result;
}
