import { type NodeFieldsFromSchema, createDataNode } from '@donkeyjs/proxy';
import { session } from '../session';

export type RegistrationForm = {
  __typename: 'Registration';
} & NodeFieldsFromSchema<DataSchema, 'User'>;

export const getRegistrationForm = () => {
  const userSchema = session.app.schema.nodes.User;
  const fields = session.app.userCreation?.fields || ['email', 'password'];

  return createDataNode<RegistrationForm>({
    values: {
      __typename: 'Registration',
    } as any,
    nodeSchema: {
      fields: Object.fromEntries(
        fields.map((key) => [
          key,
          {
            ...userSchema.fields[key as keyof typeof userSchema.fields],
            optional: false,
          },
        ]),
      ),
      kind: 'type',
      reverseFields: {},
    },
  });
};
