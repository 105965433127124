import type { PermissionsInput } from '@donkeyjs/core';
import type { ProductsSchema, ShoppingSchema } from './schema';

export const permissions: PermissionsInput<ShoppingSchema> = {
  visitor: {
    Discount: {
      read: 'allow',
    },
    Order: {
      read: 'allow',
      update: 'allow',
      insert: 'allow',
      delete: 'deny',
    },
    OrderItem: {
      read: 'allow',
      update: 'allow',
      insert: 'allow',
      delete: 'allow',
    },
    PaymentPlan: {
      read: 'allow',
    },
    Transaction: {
      read: 'allow',
      delete: 'allow',
    },
  },
};

export const productPermissions: PermissionsInput<ProductsSchema> = {
  visitor: {
    Product: {
      read: 'allow',
    },
    ProductOption: {
      read: 'allow',
    },
  },
};
