import { setState } from '@donkeyjs/jsx-runtime';
import { type DataNode, bind, meta } from '@donkeyjs/proxy';
import { Image } from '../../../components/Image';
import { session } from '../../../session';

interface FileRefProps {
  value: DataNode<DataSchema, 'FileRef'> | null | undefined;
  default?: DataNode<DataSchema, 'File'> | null | undefined;
  ratio?: number | 'adaptive';
  readonly?: boolean;
  optional?: boolean;
  class?: JSX.ClassNames;
  style?: Record<string, any>;
  onmount?: JSX.OnMount<HTMLSpanElement>;
}

export function FileRef(props: FileRefProps) {
  const state = setState({
    get value() {
      const def = props.default;
      return meta(props.value?.file)?.isLoading && def && !meta(def)?.isLoading
        ? def
        : props.value || def;
    },
  });

  const handleSelect = async (file: DataNode<DataSchema, 'File'>) => {
    if (props.value) {
      props.value.file = file;
    } else {
      props.value = session.data.createNode<'FileRef'>({
        __typename: 'FileRef',
        file,
      });
    }
  };

  return () => {
    if (!state.value && props.readonly) return null;

    return (
      <Image
        file={bind(
          () => state.value,
          (value) => {
            handleSelect(value as DataNode<DataSchema, 'File'>);
          },
        )}
        ratio={bind(() => props.ratio)}
        class={bind(() => props.class)}
        style={bind(() => props.style)}
        testing={bind(() => meta(props.value)?.getField('file').isTest)}
        onChange={bind(() => (!props.readonly ? handleSelect : undefined))}
        onClear={bind(() =>
          !props.readonly && props.optional
            ? () => {
                props.value = null;
              }
            : undefined,
        )}
        onTest={(value: DataNode<DataSchema, 'File'> | null | undefined) => {
          if (props.value) {
            return meta(props.value).testValues({ file: value || undefined });
          }
          if (value) {
            props.value = session.data.createLocalNode<'FileRef'>({
              __typename: 'FileRef',
              file: value,
            });
            return () => {
              meta(props.value)?.delete();
              props.value = null;
            };
          }
          const prev = props.value;
          props.value = null;
          return () => {
            props.value = prev;
          };
        }}
      />
    );
  };
}
