import {
  Loader,
  createView,
  getI18n,
  getTheme,
  getUserContext,
  session,
  text,
  type I18nContext,
} from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import { bind, live } from '@donkeyjs/jsx-runtime';
import { formatComposition } from '@donkeyjs/module-music';
import { PhCalendar, PhDisc, PhInfo } from '@donkeyjs/phosphor-icons';
import { meta, store, type DataNode } from '@donkeyjs/proxy';
import { formatEventName } from '../../helpers/formatEventName';
import { I18nSimon } from '../../i18n';
import { NestedAudioTrack } from '../components/NestedAudioTrack';
import styles from './ViewCompositionDetailed.module.css';

export const ViewCompositionDetailed = createView<DataSchema, 'Composition'>(
  {
    name: () => 'Detailed View',
    containerClass: ({ grouping }) => (grouping ? undefined : 'box'),
    enableAsBlock: true,
    propertiesRequest: {
      category: {
        id: true,
      },
    },
    groupings: [
      {
        key: 'composer',
        containerClass: 'box with-margin',
        name: () => 'Composer',
        format: (node) => formatName(node.composer),
      },
    ],
  },

  function ViewCompositionDetailed(props) {
    const user = getUserContext();
    const i18n = getI18n();
    const theme = getTheme();

    meta(props.node).request({
      programmes: {
        event: {
          customRepertoireLabelFuture: true,
          customRepertoireLabelPast: true,
          starts: true,
          timeZone: true,
          venue: {
            city: true,
            country: true,
            name: true,
          },
          venueName: true,
        },
        note: true,
      },
    });

    const state = store<{
      products?: Set<DataNode<DataSchema, 'Product'>>;
      events?: GroupedEvents;
    }>({});

    live(() => {
      const { products, events } = groupProgrammes(props.node, i18n);
      state.events = events;
      state.products = products;
    });

    return (
      <div
        class={bind(() => [
          styles.composition,
          {
            box: props.node.draft,
            [styles.box]: props.node.draft,
          },
        ])}
        onmount={props.onmount}
      >
        <div class="side">
          <div>
            {() =>
              props.node.composer &&
              (!props.grouping || props.node.draft) && (
                <span class={styles.composer}>
                  <Loader
                    loading={bind(() => meta(props.node).isLoading)}
                    type="inline-small"
                  >
                    {() =>
                      formatName(props.node.composer, {
                        lastNameFirst: true,
                      })
                    }
                  </Loader>
                </span>
              )
            }
            <Loader
              loading={bind(() => meta(props.node).isLoading)}
              type="inline-large"
            >
              {() => formatComposition(props.node, session.app.schema)}
            </Loader>
            <div
              class={bind(() => ({
                [styles.loading]: meta(props.node).isLoading,
              }))}
            >
              {() =>
                !!props.node.premiere && (
                  <div class={styles.annotations}>
                    <PhCalendar weight="duotone" /> Premiered by
                    {props.node.premiere}
                  </div>
                )
              }
              {() =>
                !!props.node.songText && (
                  <div class={styles.annotations}>
                    <PhInfo weight="duotone" /> Text by
                    {props.node.songText}
                  </div>
                )
              }
              {() =>
                !!props.node.dedicatee && (
                  <div class={styles.annotations}>
                    <PhInfo weight="duotone" /> Dedicated to
                    {props.node.dedicatee}
                  </div>
                )
              }
              {() =>
                !!props.node.notes?.length && (
                  <div class={styles.annotations}>
                    <PhInfo weight="duotone" /> <props.node.$.notes />
                  </div>
                )
              }
              {() =>
                user.isAdmin &&
                !!props.node.internalNotes?.length && (
                  <div class={styles.annotations}>
                    <PhInfo weight="duotone" /> <props.node.$.internalNotes />
                  </div>
                )
              }
              {() =>
                !!props.node.audioTracks.length && (
                  <div>
                    {props.node.audioTracks.$((track) => (
                      <NestedAudioTrack
                        track={track}
                        playlist={props.node.audioTracks}
                      />
                    ))}
                  </div>
                )
              }
              {() =>
                !!state.products?.size && (
                  <div class={styles.annotations}>
                    <PhDisc weight="duotone" />
                    {text(I18nSimon, 'Repertoire.AppearsOn')}{' '}
                    <AndMoreList
                      items={bind(() =>
                        Array.from(state.products!).map((product) => (
                          <a
                            class={theme.class.link}
                            href={session.router.getPath({ node: product })}
                          >
                            {product.toString()}
                          </a>
                        )),
                      )}
                    />
                  </div>
                )
              }
              {() =>
                Object.entries(state.events || {}).map(
                  ([groupLabel, { events, isFuture }]) => {
                    if (isFuture || user.isAdmin)
                      return (
                        <div class={styles.annotations}>
                          <PhCalendar weight="duotone" />
                          {groupLabel}{' '}
                          <AndMoreList
                            items={events.map((event) => ShowEvent(event))}
                          />
                        </div>
                      );
                  },
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  },
);

interface AndMoreListProps {
  items: JSX.Children[];
}

function AndMoreList(props: AndMoreListProps) {
  const state = store({ expanded: false });
  const theme = getTheme();

  if (!props.items.length) return null;

  return () =>
    state.expanded ? (
      props.items.map((item) => <div class={styles.relation}>{item}</div>)
    ) : (
      <>
        {' '}
        {() => props.items[0]}
        {() =>
          props.items.length > 1 && (
            <>
              {' '}
              {text('Common.And')}{' '}
              <button
                type="button"
                class={theme.class.linkButton}
                onclick={() => {
                  state.expanded = true;
                }}
              >
                {text(
                  I18nSimon,
                  'Repertoire.CountMore',
                  {},
                  props.items.length - 1,
                )}
              </button>
            </>
          )
        }
      </>
    );
}

interface ShowEventProps {
  event: DataNode<DataSchema, 'Event'>;
  notes: string[];
}

function ShowEvent(props: ShowEventProps) {
  const i18n = getI18n();
  const theme = getTheme();

  const name = () => formatEventName(props.event, { ignorePromotor: true });

  return (
    <>
      {' '}
      <a
        class={theme.class.link}
        href={bind(() => session.router.getPath({ node: props.event }))}
      >
        {() => i18n.formatDate(props.event.starts, 'P')}
      </a>
      {() =>
        name() ? (
          <>
            {' '}
            {text(I18nSimon, 'Repertoire.At')}{' '}
            <a
              class={theme.class.link}
              href={bind(() => session.router.getPath({ node: props.event }))}
            >
              {name()}
            </a>
          </>
        ) : null
      }
      {() => {
        const notes = props.notes.filter(Boolean).join(', ');
        return !!notes && ` (${notes})`;
      }}
    </>
  );
}

interface GroupedEvents {
  [groupLabel: string]: {
    isFuture?: boolean;
    events: { event: DataNode<DataSchema, 'Event'>; notes: string[] }[];
  };
}

const groupProgrammes = (
  composition: DataNode<DataSchema, 'Composition'>,
  i18n: I18nContext,
) => {
  const seen: Record<
    string,
    { event: DataNode<DataSchema, 'Event'>; notes: string[] }
  > = {};
  const events: GroupedEvents = {};

  const products = new Set<DataNode<DataSchema, 'Product'>>();

  for (const relation of composition.programmes) {
    if (relation.product) {
      products.add(relation.product);
      continue;
    }

    if (!relation.event) continue;

    const { note, event } = relation;
    if (seen[event.id]) {
      if (note) seen[event.id].notes.push(note);
    } else {
      const isFuture = event.starts > new Date();
      const label = isFuture
        ? event.customRepertoireLabelFuture ||
          i18n.get(I18nSimon, 'Repertoire.ListenLive')
        : event.customRepertoireLabelPast ||
          i18n.get(I18nSimon, 'Repertoire.Played');

      events[label] ??= { events: [], isFuture };
      seen[event!.id] = { event: event!, notes: note ? [note] : [] };
      events[label].events.push(seen[event.id]);
    }
  }

  for (const item of Object.values(events)) {
    item.events.sort(
      (a, b) =>
        (a.event.starts.getTime() - b.event.starts.getTime()) *
        (item.isFuture! ? 1 : -1),
    );
  }

  return { events, products };
};
