import type { Culture, NodeFieldInfo } from '@donkeyjs/proxy';
import { MultiCultureInput } from '../../../../i18n/MultiCultureInput';

export function MultiCultureStringEditor(props: {
  readonly cultures: Record<Culture, NodeFieldInfo<any, any>>;
  readonly render: (props: { culture: Culture; value: string }) => JSX.Element;
}) {
  return (
    <MultiCultureInput.Container>
      {Object.entries(props.cultures!).map(([culture, field]) => (
        <MultiCultureInput.Culture>
          {props.render({
            culture: culture as Culture,

            get value() {
              return field.value || '';
            },

            set value(value) {
              field.value = value;
            },
          })}
        </MultiCultureInput.Culture>
      ))}
    </MultiCultureInput.Container>
  );
}
