import {
  Loader,
  createView,
  getI18n,
  getMailContext,
  getTheme,
  text,
  useReadonly,
} from '@donkeyjs/client';
import { getLocale } from '@donkeyjs/core';
import { bind, jsxx, setState } from '@donkeyjs/jsx-runtime';
import { formatEventStartsEnds } from '@donkeyjs/module-events';
import { formatWorksBy } from '@donkeyjs/module-music';
import { PhListBullets, PhRows } from '@donkeyjs/phosphor-icons';
import { meta } from '@donkeyjs/proxy';
import { formatEventName } from '../../helpers/formatEventName';
import { I18nSimon } from '../../i18n';
import { ListTile } from '../components/ListTile';
import { related } from '../components/related';
import styles from './ViewEvent.module.css';
import { ViewEventEmail } from './ViewEventEmail';

export const ViewEvent = createView<DataSchema, 'Event'>(
  {
    name: () => 'Default View',
    dataContainerClass: 'full-tiles-list',
    enableAsBlock: true,
    groupings: [
      {
        key: 'month',
        name: () => 'Month',
        format: (node) =>
          getLocale(meta(node).culture).formatDate(node.starts, 'MMMM yyyy'),
      },
    ],
    propertiesRequest: {
      customRepertoireLabelFuture: true,
      customRepertoireLabelPast: true,
    },
    modes: [
      {
        key: 'simple',
        name: () => 'Simple View',
        icon: () => <PhListBullets weight="bold" />,
      },
      {
        key: 'detailed',
        name: () => 'Detailed View',
        icon: () => <PhRows weight="regular" />,
      },
    ],
  },

  function ViewEvent(props) {
    const i18n = getI18n();
    const theme = getTheme();
    const mail = getMailContext();

    const state = setState({
      get mode() {
        return props.selected
          ? 'selected'
          : props.mode?.key === 'detailed'
            ? 'detailed'
            : 'simple';
      },
      get past() {
        return props.node.ends < new Date();
      },
    });

    useReadonly(() => !props.selected);

    const formattedWorks = () => {
      const result = formatWorksBy(
        props.node.program.map((r) => r.composition!).filter(Boolean),
        {
          i18n,
          includeFirstNames: true,
        },
      );
      return result && `${result}`;
    };

    const relatedNodes = related({ node: props.node });

    return () =>
      mail ? (
        jsxx(ViewEventEmail, props)
      ) : (
        <>
          <div
            class={bind(() => [
              styles.event,
              props.class,
              { selected: state.mode === 'selected' },
            ])}
            onmount={props.onmount}
          >
            {() =>
              state.mode !== 'selected' && (
                <a class={styles.fullLink} href={bind(() => props.href)}>
                  {text('Common.MoreInfoShort')}
                </a>
              )
            }
            <ListTile
              image={(ratio) => <props.node.$.images ratio={ratio} />}
              readonly={bind(() => props.readonly)}
              kicker={bind(() => <props.node.$.kind />)}
              title={
                <>
                  <span class={styles.date}>
                    <props.node.$.starts
                      formatter={bind(() => {
                        const year = props.grouping ? ' yyyy' : '';
                        return props.node.fullDay
                          ? `do MMM${year}`
                          : `do MMM${year} | p`;
                      })}
                      formatRange={() =>
                        formatEventStartsEnds(props.node, {
                          timeDelimiter: '|',
                          includeYear: !props.grouping,
                        })
                      }
                    />
                  </span>
                  <span class={styles.name}>
                    <Loader
                      loading={bind(() => meta(props.node).isLoading)}
                      type="inline-medium"
                    >
                      {() => formatEventName(props.node)}
                    </Loader>
                  </span>
                </>
              }
              subtitle={
                <>
                  <span class={styles.name}>
                    <props.node.$.name />
                  </span>
                  {() =>
                    state.mode === 'simple' && (
                      <span class={styles.worksFrom}>
                        <Loader
                          loading={bind(() => meta(props.node).isLoading)}
                          type="inline-medium"
                        >
                          {formattedWorks}
                        </Loader>
                      </span>
                    )
                  }
                </>
              }
              button={() => {
                const text = props.node.linkToUrlText;
                return (
                  !!props.node.linkToUrl && (
                    <a
                      class={[theme.class.button, 'default', 'buy-tickets']}
                      href={props.node.linkToUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {text || state.past
                        ? i18n.get('Common.MoreInfoShort')
                        : i18n.get(I18nSimon, 'BuyTickets')}
                    </a>
                  )
                );
              }}
              overflow
              expanded={bind(() => state.mode === 'selected')}
              linkTo={bind(() => props.href)}
            >
              {() =>
                state.mode !== 'simple' && (
                  <>
                    <props.node.$.info />
                    <props.node.$.program class={styles.program} />
                    {() => {
                      const venue = props.node.venue;

                      if (state.mode !== 'selected')
                        return props.node.promotor && venue ? (
                          <div class={styles.address}>
                            {() =>
                              [venue.name, venue.city, venue.country]
                                .filter(Boolean)
                                .join(', ')
                            }
                          </div>
                        ) : null;

                      return (
                        <>
                          <div class={styles.googleMap}>
                            <props.node.$.venue />
                          </div>
                          <props.node.$.moreInfo />
                          {() =>
                            props.selected &&
                            venue && (
                              <div class={styles.address}>
                                {() =>
                                  [venue.name, venue.address, venue.phoneNumber]
                                    .filter(Boolean)
                                    .join(' | ')
                                }
                                {() =>
                                  !!venue.website && (
                                    <>
                                      {' | '}
                                      <a
                                        href={venue.website}
                                        target="_blank"
                                        class={theme.class.link}
                                        rel="noreferrer"
                                      >
                                        {text(I18nSimon, 'Website')}
                                      </a>
                                    </>
                                  )
                                }
                              </div>
                            )
                          }
                        </>
                      );
                    }}
                  </>
                )
              }
            </ListTile>
          </div>

          {() =>
            state.mode === 'selected' &&
            (relatedNodes?.[0]() || 0) > 0 &&
            relatedNodes[1]
          }
        </>
      );
  },
);
