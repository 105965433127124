import {
  type Node,
  createDataNode,
  nodeSchemaFromInput,
} from '@donkeyjs/proxy';
import { getUserContext } from './getUserContext';

export type LoginFormType = Node<{
  __typename: 'Login';
  email: string;
  password: string;
}>;

export const getLoginForm = (): LoginFormType => {
  const user = getUserContext();
  return createDataNode({
    values: {
      __typename: 'Login',
      email: user?.user?.email || '',
      password: '',
    },
    nodeSchema: nodeSchemaFromInput('Login', {
      email: 'string',
      password: 'string',
    }),
  });
};
