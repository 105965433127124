import { formatName } from '@donkeyjs/core';
import type { EnumFromSchema, NodeFieldsFromFragment } from '@donkeyjs/proxy';

export const formatAddress = (
  address: NodeFieldsFromFragment<
    DataSchema,
    'Address',
    {
      firstName: true;
      lastName: true;
      middleName: true;
      title: true;
      companyName: true;
      addressLine1: true;
      addressLine2: true;
      postalCode: true;
      city: true;
      countryCode: true;
    }
  >,
  getCountryName: (code: EnumFromSchema<DataSchema, 'CountryCode'>) => string,
  separator = '\n',
) =>
  [
    address.companyName,
    formatName(address),
    address.addressLine1,
    address.addressLine2,
    [address.postalCode, address.city].filter(Boolean).join(' '),
    address.countryCode && getCountryName(address.countryCode),
  ]
    .filter(Boolean)
    .join(separator);
