import {
  MarkupStringProcessor,
  type MarkupString,
  type MarkupTreeEntity,
  type MarkupTreeType,
} from './Markup';

export function markupToHtml(markup: MarkupString) {
  const processor = new MarkupStringProcessor({ value: markup });
  return convertTree(processor, processor.tree);
}

function convertTree(
  processor: MarkupStringProcessor,
  tree: MarkupTreeType,
  depth = 0,
) {
  return tree.map((e) => convertEntity(processor, e, depth)).join('');
}

function convertEntity(
  processor: MarkupStringProcessor,
  e: MarkupTreeEntity,
  depth: number,
): string {
  const children = `${convertTree(processor, e.children, depth + 1)}`;
  switch (e.kind) {
    case 'formatting': {
      const tag = (
        {
          b: 'strong',
          i: 'em',
          s: 's',
        } as const
      )[e.entity.f];
      return `<${tag}>${children}</${tag}>`;
    }
    case 'link':
      return `<a href="${e.entity.to}"${
        e.entity.tg ? ` target="${e.entity.tg}"` : ''
      }>${children}</link>`;
    case 'field':
      return `<span data-field-id="${e.entity.fld}"> </span>`;
    case 'text':
      return htmlEncode(e.text).replace(/\n/g, '<br />');
    default:
      return '';
  }
}

function htmlEncode(text: string) {
  return text
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}
