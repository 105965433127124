import { bind } from '@donkeyjs/proxy';
import styles from './MarkupTemplate.module.css';

interface MarkupTemplateProps {
  contenteditable?: 'false';
  onmount?: JSX.OnMount<HTMLDivElement | HTMLSpanElement>;
  class?: JSX.ClassNames;
  inline?: boolean;
  children?: JSX.Children;
}

export function MarkupTemplate(props: MarkupTemplateProps) {
  return (
    <div
      $element={bind(() => (props.inline ? 'span' : undefined))}
      class={bind(() => [
        styles.template,
        props.class,
        { [styles.inline]: props.inline },
      ])}
      contenteditable={bind(() => props.contenteditable)}
    >
      {props.children}
    </div>
  );
}
