import { bind } from '@donkeyjs/jsx-runtime';
import { map, store, type DataNode } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { getUserContext } from '../authentication';
import { text } from '../i18n/i18n';
import { session } from '../session';
import { WithHeading, getTheme } from '../styles';
import styles from './DirectoryBlock.module.css';

export interface DirectorySettings {
  parent?: DataNode<DataSchema, 'Route'>;
}

export function DirectoryBlock(props: BlockProps<DirectorySettings>) {
  const user = getUserContext();

  const state = store({
    get parent() {
      return props.parent
        ? session.data.getNode('Route', props.parent as unknown as string)
        : session.router.route.node;
    },
    get routes() {
      return (
        (this.parent?.children || []).filter(
          (route) => route !== session.router.route.node && !route.hidden,
        ) || []
      );
    },
  });

  return () =>
    !state.routes.length && !user.isAdmin ? null : (
      <div onmount={props.onmount} class={bind(() => props.class)}>
        {() =>
          !state.routes.length ? (
            <div class="empty">
              {() =>
                state.parent === session.router.route.node
                  ? 'The current page has no children.'
                  : `The page '${state.parent?.name}' has no children.`
              }
            </div>
          ) : (
            map(
              () => state.routes,
              (route) => route && <DirectoryRoute route={route} />,
            )
          )
        }
      </div>
    );
}

export function DirectoryRoute(props: {
  route: DataNode<DataSchema, 'Route'>;
}) {
  const theme = getTheme();

  const state = store({
    get url() {
      return session.router.getPath({ route: props.route });
    },
  });

  return (
    <div class={styles.route}>
      <a href={bind(() => state.url)} class={styles.image}>
        <props.route.$.image ratio={1} />
      </a>
      <div class={styles.description}>
        <WithHeading
          styleAs="heading1"
          heading={<a href={bind(() => state.url)}>{() => props.route.name}</a>}
        >
          <div>
            <props.route.$.summary />
            <p class={[styles.readMore, theme.class.paragraph]}>
              <a class={theme.class.link} href={bind(() => state.url)}>
                {text('Common.ReadMore')}
              </a>
            </p>
          </div>
        </WithHeading>
      </div>
    </div>
  );
}
