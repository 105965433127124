import { jsxx, live, setState } from '@donkeyjs/jsx-runtime';
import {
  type DataNode,
  type NodeTypename,
  type Store,
  meta,
  store,
} from '@donkeyjs/proxy';
import { formatSearchText } from '../../helpers/formatSearchText';
import { getI18n } from '../../i18n/getI18n';
import { session } from '../../session';
import { Select } from './Select';
import type { UseSelectProps } from './useSelect';

export interface NodeSelectProps<
  T extends NodeTypename<DataSchema>,
  Mapped extends NodeTypename<DataSchema> = T,
> {
  value?: DataNode<DataSchema, T> | DataNode<DataSchema, T>[] | null;
  readonly typename: Mapped;
  readonly mapping?: {
    get(node: DataNode<DataSchema, T> | null): DataNode<DataSchema, Mapped>;
    set(
      node: DataNode<DataSchema, Mapped>,
      previous: DataNode<DataSchema, T> | null,
    ): DataNode<DataSchema, T> | null;
    remove?(node: DataNode<DataSchema, T>): boolean;
  };
  readonly filter?: (node: DataNode<DataSchema, Mapped>) => boolean;
  readonly allowEmpty?: boolean;
  readonly autoSelectFirst?: boolean;
  readonly readonly?: boolean;
  onRequestAdd?(): Promise<DataNode<DataSchema, Mapped> | null | undefined>;
  onchange?(
    value:
      | DataNode<DataSchema, T>
      | DataNode<DataSchema, T>[]
      | null
      | undefined,
  ): boolean | void;
}

export function NodeSelect<
  T extends NodeTypename<DataSchema>,
  Mapped extends NodeTypename<DataSchema> = T,
>(props: NodeSelectProps<T, Mapped>) {
  return jsxx(Select, useNodeSelectProps(props));
}

export function useNodeSelectProps<
  T extends NodeTypename<DataSchema>,
  Mapped extends NodeTypename<DataSchema> = T,
>(
  props: NodeSelectProps<T, Mapped>,
): UseSelectProps<DataNode<DataSchema, T>, DataNode<DataSchema, Mapped>> {
  const propsStore = store(props);

  const i18n = getI18n();

  const state = setState({
    opened: false,
    search: '',
  });

  const options = session.data.useNodes({
    typename: propsStore.typename,
    drafts: false,
    get skipExecution() {
      return props.readonly || (!state.opened && !propsStore.autoSelectFirst);
    },
    get search() {
      return state.search
        ? {
            text: formatSearchText(state.search, 'tokens'),
            culture:
              session.app.schema.cultures.length > 1 ? i18n.culture : undefined,
            mode: { __literal: 'TOKENS' } as const,
          }
        : undefined;
    },
  });

  live(() => {
    if (
      propsStore.autoSelectFirst &&
      !(propsStore as Store<typeof propsStore>).$.peek('value')
    ) {
      const filtered = propsStore.filter
        ? options.filter(propsStore.filter)
        : options;
      if (filtered.length && !meta(filtered[0]).isLoading) {
        propsStore.value = filtered[0] as any;
      }
    }
  });

  return {
    get search() {
      return state.search;
    },
    set search(value) {
      state.search = value;
    },
    options: () => {
      state.opened = true;
      if (propsStore.filter) return options.filter(propsStore.filter);
      return options;
    },
    get readonly() {
      return propsStore.readonly;
    },
    get allowEmpty() {
      return propsStore.allowEmpty;
    },
    get value() {
      return propsStore.value;
    },
    set value(value) {
      propsStore.value = value;
    },
    get mapping() {
      return propsStore.mapping;
    },
    onchange: () => propsStore.onchange?.(propsStore.value),
    get onRequestAddLabel() {
      return i18n.get(
        'Common.CreateNode',
        i18n.getNodeName(propsStore.typename),
      );
    },
    get onRequestAdd() {
      return propsStore.onRequestAdd;
    },
  };
}
