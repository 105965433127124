import type { AppModule } from '@donkeyjs/core';
import type {
  AppClientModule,
  ClientApp,
  ClientAppInput,
} from '../apps/bootClientApp';
import { defaultBlocks } from '../blocks/defaultBlocks';
import type { Changelog } from '../donkey';
import { baseClientSchemaMeta } from '../schema/baseClientSchemaMeta';
import { mergeClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { createTheme } from '../styles';
import { defaultViews } from '../views/defaultViews';
import { mergeViews } from '../views/viewUtils';

export type MergedModules = Omit<
  Required<ClientAppInput>,
  | keyof AppModule
  | 'backOffice'
  | 'changelog'
  | 'tz'
  | 'theme'
  | 'nodeRouting'
  | 'userCreation'
> &
  Pick<ClientAppInput, 'backOffice' | 'tz' | 'userCreation'> &
  Pick<ClientApp, 'changelog' | 'theme'>;

export function mergeModules(input: ClientAppInput): MergedModules {
  const mergedModules = (input.modules || []).reduce<
    Omit<AppClientModule, 'changelog'> & { changelog?: Changelog[] }
  >(
    (acc, module) => {
      return {
        plugins: [...(acc.plugins || []), ...(module.plugins || [])],
        clientSchemaMeta: mergeClientSchemaMeta(
          acc.clientSchemaMeta,
          module.clientSchemaMeta,
        ),
        blocks: {
          ...acc.blocks,
          ...module.blocks,
        },
        changelog: module.changelog
          ? [...(acc.changelog || []), module.changelog]
          : acc.changelog,
        localRoutes: [
          ...(acc.localRoutes || []),
          ...(module.localRoutes || []),
        ],
        views: mergeViews(acc.views, module.views),
      };
    },
    { clientSchemaMeta: baseClientSchemaMeta },
  );

  const merged: Omit<
    Required<ClientAppInput>,
    | keyof AppModule
    | 'backOffice'
    | 'blockPresets'
    | 'changelog'
    | 'tz'
    | 'theme'
    | 'nodeRouting'
    | 'userCreation'
  > &
    Pick<ClientAppInput, 'tz' | 'userCreation'> &
    Pick<ClientApp, 'backOffice' | 'changelog' | 'theme'> = {
    ...input,
    install: input.install || (() => {}),
    routerFollowsCultures: !!input.routerFollowsCultures,
    modules: [],
    routerPlugins: input.routerPlugins || [],
    plugins: [...(mergedModules.plugins || []), ...(input.plugins || [])],
    theme: input.theme?.() || createTheme(undefined),
    localRoutes: [
      ...(mergedModules.localRoutes || []),
      ...(input.localRoutes || []),
    ],
    clientSchemaMeta: mergeClientSchemaMeta(
      mergedModules.clientSchemaMeta,
      input.clientSchemaMeta,
    ),
    roles: input.roles || [],
    blocks: {
      ...defaultBlocks,
      ...mergedModules.blocks,
      ...input.blocks,
    },
    changelog: input.changelog
      ? [...(mergedModules.changelog || []), input.changelog]
      : mergedModules.changelog,
    views: mergeViews(
      defaultViews,
      mergeViews<DataSchema>(mergedModules.views, input.views),
    ),
  };

  return merged;
}
