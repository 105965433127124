import { formatName } from '@donkeyjs/core';
import { getMailContext } from '../../mail';
import type { ViewProps } from '../../views';

export function ViewUserMailMerge(props: ViewProps<DataSchema, 'User'>) {
  const context = getMailContext();

  return () => {
    const name = formatName(props.node);
    const email = props.node.email;

    if (context?.target === 'mail') {
      return JSON.stringify({ userId: props.node.id, name, email });
    }

    return <>{name ? `${name} <${email}>` : email}</>;
  };
}
