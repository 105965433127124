import type { DataNode, NodeFieldsFromFragment } from '@donkeyjs/proxy';
import type { CouponFields } from '../../shared/coupons/validateCoupon';
import type { OrderItemParser } from '../shoppingSettings';

type ProductCouponFields = CouponFields &
  NodeFieldsFromFragment<
    DataSchema,
    'Coupon',
    {
      productOption: {
        id: true;
      };
    }
  >;

export const ProductOrderItemParser: OrderItemParser<
  DataNode<DataSchema, 'OrderItem'>
> = {
  selection: {
    productOption: {
      selection: {
        id: true,
        name: true,
        earliestPaymentAt: true,
        discounts: {
          selection: {
            id: true,
            name: true,
            amount: true,
            validFrom: true,
            validUntil: true,
            isPercentage: true,
          },
        },
        product: {
          selection: {
            id: true,
            name: true,
            options: {
              selection: {
                name: true,
                paymentSchemes: {
                  selection: {
                    id: true,
                    name: true,
                    scheme: true,
                    numberOfInstallments: true,
                    frequency: true,
                    endDate: true,
                  },
                },
              },
            },
            images: {
              selection: {
                id: true,
                file: {
                  selection: {
                    id: true,
                    fileType: true,
                    fileExtension: true,
                    name: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  couponSelection: {
    productOption: {
      selection: {
        id: true,
      },
    },
  },

  couponNeedsValidation(coupon: ProductCouponFields) {
    return !!coupon.productOption;
  },

  parse(item) {
    return item.productOption
      ? {
          key: 'productOption',
          orderable: item.productOption,
          orderables: item.productOption.product.options.map((option) => ({
            item: option,
            name: option.name,
          })),
          discounts: item.productOption.discounts,
          validateCoupon(coupon: ProductCouponFields) {
            return coupon.productOption?.id === item.productOption?.id;
          },
          paymentSchemes: item.productOption.paymentSchemes,
          earliestPaymentAt: item.productOption.earliestPaymentAt,
          name: item.productOption.product.name,
          option: item.productOption.name,
          image: item.productOption.product.images[0],
        }
      : null;
  },
};
